import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ContextApi } from "../../../store/ContextApi";
import PageTitle from "../../layouts/PageTitle";
import SpinnerLoading from "../../components/spinner-loading/SpinnerLoading";
import FaildLoadData from "../../global-components/FaildLoadData/FaildLoadData";
import { Link } from "react-router-dom";
import{getCountriesFunc} from "../../../store/custom-actions/countries-actions/GetContriesSlicer"
import swal from "sweetalert";
import LoadingScreen from "../../components/loading-screen/LoadingScreen";
import { toast } from "react-toastify";
import { getCitiesFunc } from "../../../store/custom-actions/cities-actions/GetCitiesSlicer";
import { deleteCityFunc } from "../../../store/custom-actions/cities-actions/DeleteCitySlicer";
import NewCityForm from "../../custom-forms/cities/NewCityForm";
import UpdateCityForm from "../../custom-forms/cities/UpdateCityForm";
import checkPermissions from "../../global-functions/checkPermissionsFunc";
import { getStatesFunc } from "../../../store/custom-actions/states-actions/GetStatesSlicer";
import { useTranslation } from "react-i18next";

export default function CitiesPage() {
    const {t} = useTranslation();
    const {loading:getCitiesLoading , data:citiesData} = useSelector(state => state.getCitiesSlicer)
    const {loading:deleteCityLoading} = useSelector(state => state.deleteCitySlicer)
    const {loading:getCountriesLoading , data:countriesData} = useSelector(state => state.getCountriesSlicer);
    const {loading:getStatesLoading , data:statesData} = useSelector(state => state.getStatesSlicer);
    const contextApi = useContext(ContextApi);
    const cities_API_URL = `${contextApi.dashboard_URL_API}/cities`;
    const [citiesList , setCitiesList] = useState([]);
    const [showNewCityForm , setShowNewCityForm] = useState();
    const [showUpdateForm , setShowUpdateForm] = useState();
    const [cityWhichUpdate , setCityWhichUpdate] = useState({});
    const [searchValue , setSearchValue] = useState("");
    const [countryFilterValue , setCountryFilterValue] = useState("");
    const [stateFilterValue , setStateFilterValue] = useState("");
    const dispatch = useDispatch();
    const countries_API_URL = `${contextApi.dashboard_URL_API}/countries`;
    const states_API_URL = `${contextApi.dashboard_URL_API}/states`;
    const getCountries = () => {
      dispatch(getCountriesFunc(countries_API_URL))
      .then((result) => {
        console.log(result)
        setCountryFilterValue(result?.payload[0]?.id)
      })
    }
    useEffect(() => {
      getCountries();
    },[])
    // useEffect(() => {
    //     const getCitites = async () => {
    //       try {
    //         dispatch(getCitiesFunc(cities_API_URL))
    //         .then((result) => {
    //           if(result?.payload?.length >= 1) {
    //             getCountries();
    //           }
    //         })
    //       } catch (error) {
    //         console.log(error);
    //       }
    //     };
    //     getCitites();
    // }, []);
    useEffect(() => {
      if(countryFilterValue !== "") {
        const getStates = () => {
          dispatch(getStatesFunc(`${states_API_URL}?country_id=${countryFilterValue}`))
          .then((result) => {
            console.log(result)
            setStateFilterValue(result?.payload[0]?.id)
          })
        }
        getStates();
      }
    },[countryFilterValue]);
      useEffect(() => {
        citiesData && setCitiesList(citiesData);
        citiesData && (jobData.current = citiesData.slice(
          activePag.current * sort,
          (activePag.current + 1) * sort
        ));
      }, [citiesData]);

      // const search = () => {
      //   if(searchValue !== "") {
      //     const searchResult = citiesData &&
      //     citiesData.length >= 1 && citiesData.filter((city) =>  city.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()));
      //     jobData.current = searchResult
      //   }else {
      //     dispatch(getCitiesFunc(cities_API_URL))
      //   }
      // }
      // useEffect(() => {
      //   searchValue === "" && dispatch(getCitiesFunc(cities_API_URL));
      // },[searchValue])
      const filterFunc = () => {
        const api = `${cities_API_URL}?${countryFilterValue !== "" ? `country_id=${countryFilterValue}` : ""}${searchValue !== "" ? `&search=${searchValue}` : ""}&${stateFilterValue !== "" ? `state_id=${stateFilterValue}` : ""}`;
        dispatch(getCitiesFunc(api))
      }
      useEffect(() => {
        if(countryFilterValue && stateFilterValue) {
          filterFunc();
        }
      },[countryFilterValue , stateFilterValue])
      const sort = 20;
      let paggination = Array(Math.ceil(citiesList.length / sort))
        .fill()
        .map((_, i) => i + 1);
    
      const activePag = useRef(0);
      const jobData = useRef(
        citiesList.slice(
          activePag.current * sort,
          (activePag.current + 1) * sort
        )
      );
      const onClick = (i) => {
        activePag.current = i;
        jobData.current = citiesList.slice(
          activePag.current * sort,
          (activePag.current + 1) * sort
        );
      };
      const deleteCityFunction = async (id) => {
        swal({
          title: "Confirm City Deletion",
          text: `Once deleted, This City will not be avilable!`,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            dispatch(deleteCityFunc(`${cities_API_URL}/${id}`))
            .then((result) => {
              if(result.payload.success === true){
                dispatch(getCitiesFunc(cities_API_URL));
                toast.success(`✔️ ${result.payload.message} !`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
              })
              }
            })
          } else {
            swal("City is safe!");
          }
        });
      };
      
    return (
        <>
        <PageTitle motherMenu={"Dashboard"} activeMenu={"Cities"} />
        {/* {
            !getCitiesLoading && citiesList.length < 1
            &&
            checkPermissions(
              "create addresses",
              <div className="relative mt-2 flex justify-end items-center">
                <Link 
                to={"#"} 
                className="btn btn-primary btn-sm"
                onClick={() => [
                  setShowNewCityForm(true)
                ]}
                >New City</Link>
              </div>
            )
        } */}
        {
            (
                // citiesData
                // ?
                (
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header flex justify-between items-center">
                        <h4 className="card-title">Cities</h4>
                        <div className="relative add-and-search flex items-center">
                          <div className="input-group search-area w-auto mr-2">
                              <span className="input-group-text cursor-pointer"
                                onClick={() => searchValue !== "" && filterFunc()}
                              >
                                  <Link to={"#"}>
                                  <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 32 32"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                  >
                                      <path
                                      d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                                      fill="var(--primary)"
                                      ></path>
                                  </svg>
                                  </Link>
                              </span>
                              <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search here..."
                                  onChange={(e) => {
                                    setSearchValue(e.target.value);
                                  }}
                              />
                          </div>
                          {
                            checkPermissions(
                              "create addresses",
                              <Link 
                              to={"#"} 
                              className="btn btn-primary btn-sm"
                              onClick={() => [
                                setShowNewCityForm(true)
                              ]}
                              >New City</Link>
                            )
                          }
                          <div className="relative flex items-center">
                            {
                                getCountriesLoading ? (
                                    <div className="form-group flex items-center">
                                        <p>{t("loading")}...</p>
                                    </div>
                                ) : countriesData &&
                                    countriesData.length <= 0 ? (
                                    <div className="form-group flex items-center">
                                        <p>{t("No data available")}</p>
                                    </div>
                                ) : (
                                  <select
                                    className="form-control mx-1"
                                    value={countryFilterValue}
                                    onChange={(e) => {
                                      setCountryFilterValue(e.target.value)
                                    }}
                                    >
                                      {/* <option value={""}>All</option> */}
                                      {
                                        countriesData?.map((item , index) => (
                                          <option value={item.id} key={index}>{item.name}</option>
                                        ))
                                      }
                                    </select>
                                )
                            }
                            {
                                getStatesLoading ? (
                                    <div className="form-group flex items-center">
                                        <p>{t("loading")}...</p>
                                    </div>
                                ) : statesData &&
                                    statesData.length <= 0 ? (
                                    <div className="form-group flex items-center">
                                        <p>{t("No data available")}</p>
                                    </div>
                                ) : (
                                  <select
                                    disabled = {countryFilterValue === "" || !countryFilterValue}
                                    className="form-control mx-1"
                                    value={stateFilterValue}
                                    onChange={(e) => {
                                      setStateFilterValue(e.target.value)
                                    }}
                                    >
                                      {/* <option value={""}>All</option> */}
                                      {
                                        statesData?.map((item , index) => (
                                          <option value={item.id} key={index}>{item.name}</option>
                                        ))
                                      }
                                    </select>
                                )
                            }
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="w-100 table-responsive">
                          <div id="example_wrapper" className="dataTables_wrapper">
                            <table id="example" className="display w-100 dataTable">
                              <thead>
                                <tr role="row">
                                  <th>#ID</th>
                                  <th>Name</th>
                                  <th>Code</th>
                                  <th>Status</th>
                                  <th>State</th>
                                  <th>Country</th>
                                  <th>Country Status</th>
                                  {
                                    checkPermissions
                                    (
                                      ["delete addresses" , "update addresses"],
                                      <th>Actions</th>
                                    )
                                  }
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  getCitiesLoading
                                  ?
                                  (
                                    <tr>
                                      <td colSpan={2}>
                                        <SpinnerLoading/>
                                      </td>
                                    </tr>
                                  )
                                  :
                                  (
                                  citiesList && citiesList.length >= 1
                                  ?
                                  jobData.current.map((city , index) => {
                                      return (
                                          <tr key={index}>
                                              <td>{city.id}</td>
                                              <td>{city.name}</td>
                                              <td>{city.code}</td>
                                              <td>
                                                  <span className={`relative text-sm rounded-lg text-white ${city.active === 1 ? "bg-green-500" : "bg-red-500"} p-1`}>
                                                      {
                                                          city.active === 1
                                                          ?
                                                          "Active"
                                                          :
                                                          "Not Active"
                                                      }
                                                  </span>
                                              </td>
                                              <td>{city.state && city.state.name && city.state.name}</td>
                                              <td>{city.country && city.country.name && city.country.name}</td>
                                              <td>
                                                  <span className={`relative text-sm rounded-lg text-white ${city.country.active && city.country.active === 1 ? "bg-green-500" : "bg-red-500"} p-1`}>
                                                      {
                                                          city.country.active && city.country.active === 1
                                                          ?
                                                          "Active"
                                                          :
                                                          "Not Active"
                                                      }
                                                  </span>
                                              </td>
                                              <td>
                                                {
                                                  checkPermissions(
                                                    "update addresses",
                                                    <Link
                                                        to="#"
                                                        className="btn btn-primary shadow btn-xs sharp me-1"
                                                        onClick={() => {
                                                          setShowUpdateForm(true);
                                                          setCityWhichUpdate(city);
                                                        }}
                                                    >
                                                        <i className="fas fa-pencil-alt"></i>
                                                    </Link>
                                                  )
                                                }
                                                {
                                                  checkPermissions(
                                                    "delete addresses",
                                                    <Link
                                                        to="#"
                                                        className="btn btn-danger shadow btn-xs sharp"
                                                        onClick={() => {
                                                            deleteCityFunction(city.id)
                                                        }}
                                                    >
                                                        <i className="fa fa-trash"></i>
                                                    </Link>
                                                  )
                                                }
                                              </td>
                                          </tr>
                                      )
                                  }) 
                                  :
                                  <tr>
                                    <td colSpan={4}>No data avilable</td>
                                  </tr>
                                  )
                                }
                              </tbody>
                            </table>
              
                            <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-2">
                              <div className="dataTables_info">
                                Showing {activePag.current * sort + 1} to{" "}
                                {citiesList.length > (activePag.current + 1) * sort
                                  ? (activePag.current + 1) * sort
                                  : citiesList.length}{" "}
                                of {citiesList.length} entries
                              </div>
                              <div
                                className="dataTables_paginate paging_simple_numbers mb-0"
                                id="example5_paginate"
                              >
                                <Link
                                  className="paginate_button previous disabled"
                                //   to="/table-datatable-basic"
                                  to="#"
                                  onClick={() =>
                                    activePag.current > 0 && onClick(activePag.current - 1)
                                  }
                                >
                                  <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                                </Link>
                                <span>
                                  {paggination.map((number, i) => (
                                    <Link
                                      key={i}
                                    //   to="/table-datatable-basic"
                                      to="#"
                                      className={`paginate_button  ${
                                        activePag.current === i ? "current" : ""
                                      } `}
                                      onClick={() => onClick(i)}
                                    >
                                      {number}
                                    </Link>
                                  ))}
                                </span>
                                <Link
                                  className="paginate_button next"
                                //   to="/table-datatable-basic"
                                  to="#"
                                  onClick={() =>
                                    activePag.current + 1 < paggination.length &&
                                    onClick(activePag.current + 1)
                                  }
                                >
                                  <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
                // :
                // <FaildLoadData
                //     refetchDataFunc={() => dispatch(getCitiesFunc(cities_API_URL))}
                // />
            )
        }
        <LoadingScreen loading={deleteCityLoading} />
        <NewCityForm
          show={showNewCityForm}
          onHide={() => setShowNewCityForm(false)}
        />
        <UpdateCityForm
          show={showUpdateForm}
          onHide={() => setShowUpdateForm(false)}
          cityId = {cityWhichUpdate.id}
          oldName = {cityWhichUpdate.name}
          oldCode = {cityWhichUpdate.code}
          oldStatus = {cityWhichUpdate.active}
          oldStateId={cityWhichUpdate.state_id}
          oldCountryId={cityWhichUpdate.country_id}
        />
        </>
    )
}