export default function SpinnerLoading () {
    return (
        <>
            <div className="relative w-full flex justify-center items-center">
                <span className="relative flex h-6 w-6 mx-2">
                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary-hover opacity-75"></span>
                    <span className="relative inline-flex rounded-full h-6 w-6 bg-primary"></span>
                </span>
                <span className="relative flex h-6 w-6 mx-2">
                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary-hover opacity-75"></span>
                    <span className="relative inline-flex rounded-full h-6 w-6 bg-primary"></span>
                </span>
                <span className="relative flex h-6 w-6 mx-2">
                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary-hover opacity-75"></span>
                    <span className="relative inline-flex rounded-full h-6 w-6 bg-primary"></span>
                </span>
                <span className="relative flex h-6 w-6 mx-2">
                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary-hover opacity-75"></span>
                    <span className="relative inline-flex rounded-full h-6 w-6 bg-primary"></span>
                </span>
            </div>
        </>
    )
}