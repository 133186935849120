import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ContextApi } from "../../../store/ContextApi";
import PageTitle from "../../layouts/PageTitle";
import SpinnerLoading from "../../components/spinner-loading/SpinnerLoading";
import FaildLoadData from "../../global-components/FaildLoadData/FaildLoadData";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { deleteCountryFunc } from "../../../store/custom-actions/countries-actions/DeleteCountrySlicer";
import LoadingScreen from "../../components/loading-screen/LoadingScreen";
import { toast } from "react-toastify";
import { getStatesFunc } from "../../../store/custom-actions/states-actions/GetStatesSlicer";
import NewStateForm from "../../custom-forms/states/NewStateForm";
import UpdateStateForm from "../../custom-forms/states/UpdateStateForm";
import checkPermissions from "../../global-functions/checkPermissionsFunc";

export default function StatesPage() {
    const {loading:getStatesLoading , data:statesData} = useSelector(state => state.getStatesSlicer)
    const {loading:deleteStatesLoading} = useSelector(state => state.deleteStateSlicer)
    const contextApi = useContext(ContextApi);
    const site_global_currency = contextApi.homeSettings.site_global_currency;
    const states_API_URL = `${contextApi.dashboard_URL_API}/states`;
    const [statesList , setStatesList] = useState([]);
    const [showNewStateForm , setShowNewStateForm] = useState();
    const [showUpdateForm , setShowUpdateForm] = useState();
    const [stateWichUpdate , setStateWhichUpdate] = useState({});
    const [searchValue , setSearchValue] = useState("");
    const dispatch = useDispatch();

    useEffect(() => {
        const getCitites = async () => {
          try {
            dispatch(getStatesFunc(states_API_URL))
          } catch (error) {
            console.log(error);
          }
        };
        getCitites();
      }, []);
      useEffect(() => {
        statesData && setStatesList(statesData);
        statesData && (jobData.current = statesData.slice(
            activePag.current * sort,
            (activePag.current + 1) * sort
          ));
      }, [statesData]);
      const sort = 20;
      let paggination = Array(Math.ceil(statesList.length / sort))
        .fill()
        .map((_, i) => i + 1);
    
      const activePag = useRef(0);
      const jobData = useRef(
        statesList.slice(
          activePag.current * sort,
          (activePag.current + 1) * sort
        )
      );
      const onClick = (i) => {
        activePag.current = i;
        jobData.current = statesList.slice(
          activePag.current * sort,
          (activePag.current + 1) * sort
        );
      };
      const search = () => {
        if(searchValue !== "") {
          const searchResult = statesData &&
          statesData.length >= 1 && statesData.filter((state) =>  state.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()));
          jobData.current = searchResult
        }else {
          dispatch(getStatesFunc(states_API_URL))
        }
      }
      useEffect(() => {
        searchValue === "" && dispatch(getStatesFunc(states_API_URL));
      },[searchValue])
      const deleteStateFunction = async (id) => {
        swal({
          title: "Confirm Sate Deletion",
          text: `Once deleted, This State will not be avilable!`,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            dispatch(deleteCountryFunc(`${states_API_URL}/${id}`))
            .then((result) => {
              if(result.payload.success === true){
                dispatch(getStatesFunc(states_API_URL));
                toast.success(`${result.payload.message} !`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
              })
              }
            })
          } else {
            swal("State is safe!");
          }
        });
      };
    return (
        <>
        <PageTitle motherMenu={"Dashboard"} activeMenu={"States"} />
        {
            !getStatesLoading && statesList.length < 1
            &&
            checkPermissions(
              "create addresses",
              <div className="relative mt-2 flex justify-end items-center">
                <Link 
                to={"#"} 
                className="btn btn-primary btn-sm"
                onClick={() => [
                  setShowNewStateForm(true)
                ]}
                >New State</Link>
              </div>
            )
        }
        {/* {

            (
                statesData
                ?
                ( */}

                    <div className="col-12">
                    <div className="card">
                      <div className="card-header flex justify-between items-center">
                        <h4 className="card-title">States</h4>
                        <div className="relative add-and-search flex items-center">
                          <div className="input-group search-area w-auto mr-2">
                              <span className="input-group-text"
                                onClick={() => search()}
                              >
                                  <Link to={"#"}>
                                  <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 32 32"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                  >
                                      <path
                                      d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                                      fill="var(--primary)"
                                      ></path>
                                  </svg>
                                  </Link>
                              </span>
                              <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search here..."
                                  onChange={(e) => {
                                  setSearchValue(e.target.value);
                                  }}
                              />
                          </div>
                            <Link 
                            to={"#"} 
                            className="btn btn-primary btn-sm"
                            onClick={() => [
                              setShowNewStateForm(true)
                            ]}
                            >New State</Link>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="w-100 table-responsive">
                          <div id="example_wrapper" className="dataTables_wrapper">
                            <table id="example" className="display w-100 dataTable">
                              <thead>
                                <tr role="row">
                                  <th>#ID</th>
                                  <th>Name</th>
                                  <th>Code</th>
                                  <th>Status</th>
                                  <th>Cities</th>
                                  <th>Shipping</th>
                                  {
                                    checkPermissions
                                    (
                                      ["delete addresses" , "update addresses"],
                                      <th>Actions</th>
                                    )
                                  }
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  getStatesLoading
                                  ?
                                  (
                                    <tr>
                                      <td colSpan={2}>
                                        <SpinnerLoading/>
                                      </td>
                                    </tr>
                                  )
                                  :
                                  (
                                    statesList && statesList.length >= 1
                                    ?
                                    jobData.current.map((state , index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{state.id}</td>
                                                <td>{state.name}</td>
                                                <td>{state.code}</td>
                                                <td>
                                                    <span className={`relative text-sm rounded-lg text-white ${state.active === 1 ? "bg-green-500" : "bg-red-500"} p-1`}>
                                                        {
                                                            state.active === 1
                                                            ?
                                                            "Active"
                                                            :
                                                            "Not Active"
                                                        }
                                                    </span>
                                                </td>
                                                <td>
                                                  <Link to={`/cities/${state.id}`}>{state.cities_count}</Link>
                                                </td>
                                                <td>{state.shipping_amount} {site_global_currency}</td>
                                                <td>
                                                  {
                                                    checkPermissions(
                                                      "update addresses",
                                                      <Link
                                                          to="#"
                                                          className="btn btn-primary shadow btn-xs sharp me-1"
                                                          onClick={() => {
                                                            setShowUpdateForm(true);
                                                            setStateWhichUpdate(state);
                                                          }}
                                                      >
                                                          <i className="fas fa-pencil-alt"></i>
                                                      </Link>
                                                    )
                                                  }
                                                  {
                                                    checkPermissions(
                                                      "delete addresses",
                                                      <Link
                                                          to="#"
                                                          className="btn btn-danger shadow btn-xs sharp"
                                                          onClick={() => {
                                                              deleteStateFunction(state.id)
                                                          }}
                                                      >
                                                          <i className="fa fa-trash"></i>
                                                      </Link>
                                                    )
                                                  }
                                                </td>
                                            </tr>
                                        )
                                    })
                                    :
                                    <tr>
                                      <td colSpan={4}>No data avilable</td>
                                    </tr>
                                  )
                                }
                              </tbody>
                            </table>
              
                            <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-2">
                              <div className="dataTables_info">
                                Showing {activePag.current * sort + 1} to{" "}
                                {statesList.length > (activePag.current + 1) * sort
                                  ? (activePag.current + 1) * sort
                                  : statesList.length}{" "}
                                of {statesList.length} entries
                              </div>
                              <div
                                className="dataTables_paginate paging_simple_numbers mb-0"
                                id="example5_paginate"
                              >
                                <Link
                                  className="paginate_button previous disabled"
                                //   to="/table-datatable-basic"
                                  to="#"
                                  onClick={() =>
                                    activePag.current > 0 && onClick(activePag.current - 1)
                                  }
                                >
                                  <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                                </Link>
                                <span>
                                  {paggination.map((number, i) => (
                                    <Link
                                      key={i}
                                    //   to="/table-datatable-basic"
                                      to="#"
                                      className={`paginate_button  ${
                                        activePag.current === i ? "current" : ""
                                      } `}
                                      onClick={() => onClick(i)}
                                    >
                                      {number}
                                    </Link>
                                  ))}
                                </span>
                                <Link
                                  className="paginate_button next"
                                //   to="/table-datatable-basic"
                                  to="#"
                                  onClick={() =>
                                    activePag.current + 1 < paggination.length &&
                                    onClick(activePag.current + 1)
                                  }
                                >
                                  <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    </div>
                {/* )
                :
                <FaildLoadData
                    refetchDataFunc={() => dispatch(getStatesFunc(states_API_URL))}
                />
            )
        } */}
        <LoadingScreen loading={deleteStatesLoading} />
        <NewStateForm
          show={showNewStateForm}
          onHide={() => setShowNewStateForm(false)}
        />
        <UpdateStateForm
          show={showUpdateForm}
          onHide={() => setShowUpdateForm(false)}
          stateId = {stateWichUpdate.id}
          oldName = {stateWichUpdate.name}
          oldCode = {stateWichUpdate.code}
          oldStatus = {stateWichUpdate.active}
          oldShipping = {stateWichUpdate.shipping_amount}
          oldCountry = {stateWichUpdate.country_id}
        />
        </>
    )
}