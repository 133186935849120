import { useDispatch, useSelector } from "react-redux"
import { useContext, useEffect, useRef, useState } from "react";
import { fetchAllCategoriesFunc } from "../../../store/custom-actions/categories-actions/GetAllCategoriesSlicer";
import { deleteCategoryFunc } from "../../../store/custom-actions/categories-actions/DeleteCategoriesSlicer";
import AddCategoryForm from "./AddCategoryForm";
import UpdateCategoryForm from "./UpdateCategoryForm";
import LoadingScreen from "../../components/loading-screen/LoadingScreen";
import { ContextApi } from "../../../store/ContextApi";
import EmptyData from "../../components/empty-data/EmptyData";
import SpinnerLoading from "../../components/spinner-loading/SpinnerLoading";
import { Link } from "react-router-dom";
import PageTitle from "../../layouts/PageTitle";
import { toast } from "react-toastify";
import swal from "sweetalert";
import checkPermissions from "../../global-functions/checkPermissionsFunc";

function CategoriesPage() {
    const {loading , categoriesData} = useSelector(state => state.getAllCategoriesSlicer)
    const {deleteCategoriesLoading} = useSelector(state => state.deleteCategorySlicer)
    const contextApi = useContext(ContextApi);
    const categories_API_URL = `${contextApi.dashboard_URL_API}/categories`;
    const [categoriesList , setCategoriesList] = useState([]);
    const [showCategoryForm , setShowCategoryForm] = useState();
    const [showUpdateCategoryForm , setShowUpdateCategoryForm] = useState();
    const [catNameWillUpdate , setCatNameWillUpdate] = useState();
    const [categoryActivaty , setCategoryActivaty] = useState();
    const [catIdWillUpdate , setCatIdWillUpdate] = useState({});
    const [searchValue , setSearchValue] = useState();
    const dispatch = useDispatch();

    useEffect(() => {
        const getCategoriesData = async () => {
          try {
            dispatch(fetchAllCategoriesFunc(categories_API_URL))
          } catch (error) {
            console.log(error);
          }
        };
        getCategoriesData();
      }, []);
      useEffect(() => {
        categoriesData && setCategoriesList(categoriesData);
        categoriesData && (jobData.current = categoriesData.slice(
          activePag.current * sort,
          (activePag.current + 1) * sort
        ));
      }, [categoriesData]);

      const deleteCategoryFunction = async (catId) => {
        
        swal({
          title: "Confirm Category Deletion",
          text: `Once deleted, This Category will not be avilable!`,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            try {
              dispatch(deleteCategoryFunc(`${categories_API_URL}/${catId}`))
              .then((result)=>{
                if(result.payload.success === true){
                  dispatch(fetchAllCategoriesFunc(categories_API_URL));
                  toast.success(`${result.payload.message} !`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                })
                }
              });
            }catch (error) {
              console.log(error);
            }
          }})
      };
      const sort = 20;
      let paggination = Array(Math.ceil(categoriesList.length / sort))
        .fill()
        .map((_, i) => i + 1);
    
      const activePag = useRef(0);
      const jobData = useRef(
        categoriesList.slice(
          activePag.current * sort,
          (activePag.current + 1) * sort
        )
      );
      const onClick = (i) => {
        activePag.current = i;
        jobData.current = categoriesList.slice(
          activePag.current * sort,
          (activePag.current + 1) * sort
        );
      };
      const search = () => {
        if(searchValue !== "") {
          const searchResult = categoriesData &&
          categoriesData.length >= 1 && categoriesData.filter((cat) =>  cat.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()));
          jobData.current = searchResult
        }else {
          dispatch(fetchAllCategoriesFunc(categories_API_URL))
        }
      }
      useEffect(() => {
        searchValue === "" && dispatch(fetchAllCategoriesFunc(categories_API_URL));
      },[searchValue])
    return (
        <>
        <PageTitle motherMenu={"Dashboard"} activeMenu={"Categories"} />
        {/* {
            !loading && categoriesList.length < 1
            &&
            checkPermissions(
              "create categories",
              <div className="relative mt-2 flex justify-end items-center">
                <Link 
                to={"#"} 
                className="btn btn-primary btn-sm"
                onClick={() => [
                  setShowCategoryForm(true)
                ]}
                >New Category</Link>
              </div>
            )
        } */}
        <div className="categories-section relative ">
            {
                loading 
                ?
                (
                  <SpinnerLoading/>
                )
                :
                <>
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header flex justify-between items-center">
                        <h4 className="card-title">Categories</h4>
                        <div className="relative add-and-search flex items-center">
                          <div className="input-group search-area w-auto mr-2">
                              <span className="input-group-text"
                                onClick={() => search()}
                              >
                                  <Link to={"#"}>
                                  <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 32 32"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                  >
                                      <path
                                      d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                                      fill="var(--primary)"
                                      ></path>
                                  </svg>
                                  </Link>
                              </span>
                              <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search here..."
                                  onChange={(e) => {
                                  setSearchValue(e.target.value);
                                  }}
                              />
                          </div>
                          {
                            checkPermissions(
                              "create categories",
                              <Link 
                              to={"#"} 
                              className="btn btn-primary btn-sm"
                              onClick={() => [
                                setShowCategoryForm(true)
                              ]}
                              >New Category</Link>
                            )
                          }
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="w-100 table-responsive">
                          <div id="example_wrapper" className="dataTables_wrapper">
                            <table id="example" className="display w-100 dataTable">
                              <thead>
                                <tr role="row">
                                  <th>#ID</th>
                                  <th>Category</th>
                                  <th>Creation Date</th>
                                  <th>Status</th>
                                  <th>Sub Categories</th>
                                  {
                                    checkPermissions(
                                      ["delete categories" , "update categories"],
                                      <th>Actions</th>
                                    )
                                  }
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  categoriesList && categoriesList.length > 0 
                                  ?
                                  jobData.current.map((category , index) => {
                                      const date = new Date(category.created_at)
                                      const year = date.getFullYear();
                                      const month = date.getMonth()+1;
                                      const day = date.getDate();
                                      return (
                                          <tr key={index}>
                                              <td>{category.id}</td>
                                              <td>{category.name}</td>
                                              <td>{year} / {month} / {day}</td>
                                              <td>
                                                  <span className={`relative text-sm rounded-lg text-white ${category.active === 1 ? "bg-green-500" : "bg-red-500"} p-1`}>
                                                      {
                                                          category.active === 1
                                                          ?
                                                          "Active"
                                                          :
                                                          "Not Active"
                                                      }
                                                  </span>
                                              </td>
                                              <td className="text-center">{category.sub_categories_count}</td>
                                              {
                                                checkPermissions(
                                                  ["delete categories" , "update categories"],
                                                  <td>
                                                    {
                                                      checkPermissions(
                                                        "update categories",
                                                        <Link
                                                            to="#"
                                                            className="btn btn-primary shadow btn-xs sharp me-1"
                                                            onClick={() => {
                                                              setShowUpdateCategoryForm(true);
                                                              setCatIdWillUpdate(category);
                                                              setCatNameWillUpdate(category.name)
                                                              setCategoryActivaty(category.active)
                                                            }}
                                                        >
                                                            <i className="fas fa-pencil-alt"></i>
                                                        </Link>
                                                      )
                                                    }
                                                    {
                                                      checkPermissions(
                                                        "delete categories",
                                                        <Link
                                                            to="#"
                                                            className="btn btn-danger shadow btn-xs sharp"
                                                            onClick={() => {
                                                                deleteCategoryFunction(category.id)
                                                            }}
                                                        >
                                                            <i className="fa fa-trash"></i>
                                                        </Link>
                                                      )
                                                    }
                                                  </td>
                                                )
                                              }
                                          </tr>
                                      )
                                  }) 
                                  :
                                  <tr>
                                    <td colSpan={4}>No data avilable</td>
                                  </tr>
                                }
                              </tbody>
                            </table>
              
                            <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-2">
                              <div className="dataTables_info">
                                Showing {activePag.current * sort + 1} to{" "}
                                {categoriesList.length > (activePag.current + 1) * sort
                                  ? (activePag.current + 1) * sort
                                  : categoriesList.length}{" "}
                                of {categoriesList.length} entries
                              </div>
                              <div
                                className="dataTables_paginate paging_simple_numbers mb-0"
                                id="example5_paginate"
                              >
                                <Link
                                  className="paginate_button previous disabled"
                                //   to="/table-datatable-basic"
                                  to="#"
                                  onClick={() =>
                                    activePag.current > 0 && onClick(activePag.current - 1)
                                  }
                                >
                                  <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                                </Link>
                                <span>
                                  {paggination.map((number, i) => (
                                    <Link
                                      key={i}
                                    //   to="/table-datatable-basic"
                                      to="#"
                                      className={`paginate_button  ${
                                        activePag.current === i ? "current" : ""
                                      } `}
                                      onClick={() => onClick(i)}
                                    >
                                      {number}
                                    </Link>
                                  ))}
                                </span>
                                <Link
                                  className="paginate_button next"
                                //   to="/table-datatable-basic"
                                  to="#"
                                  onClick={() =>
                                    activePag.current + 1 < paggination.length &&
                                    onClick(activePag.current + 1)
                                  }
                                >
                                  <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
            }
        </div>
        {
          showCategoryForm
          &&
          <AddCategoryForm
            showCategoryForm = {showCategoryForm} 
            hideCategoryForm = {()=>setShowCategoryForm(false)}
            categoriesApi = {categories_API_URL}
          />
        }
        <UpdateCategoryForm
          showUpdateCategoryForm = {showUpdateCategoryForm} 
          hideUpdateCategoryForm = {()=>setShowUpdateCategoryForm(false)}
          categoriesApi = {categories_API_URL}
          categoryName={catNameWillUpdate}
          categoryId={catIdWillUpdate.id}
          categoryActivaty = {catIdWillUpdate.active}
          translations = {catIdWillUpdate.translations}
        />
        <LoadingScreen loading={deleteCategoriesLoading}/>
        </>
    )
}


export default CategoriesPage