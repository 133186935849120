import React, { useCallback, useContext, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { addImagesToStorageFunc } from '../../../store/custom-actions/images/AddImagesToStorageSlicer';
import { useDispatch, useSelector } from 'react-redux';
import { ContextApi } from '../../../store/ContextApi';
import { toast } from 'react-toastify';

const MyDropzone = ({callbackFunc}) => {
  const [files, setFiles] = useState([]);
const dispatch = useDispatch();
const contextApi = useContext(ContextApi);
const imagesApi = `${contextApi.dashboard_URL_API}/uploads`;
const {loading:addImagesLoading} = useSelector(
    state => state.addImagesToStorageSlicer
)
  const onDrop = useCallback((acceptedFiles) => {
    const filePreviews = acceptedFiles.map((file) => 
      Object.assign(file, { preview: URL.createObjectURL(file) })
    );
    setFiles(filePreviews);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/*',
    multiple: true,
    maxFiles : 5
  });

  const thumbs = files.map((file) => (
    <div key={file.name} style={{ margin: '10px' }}>
      <img
        src={file.preview}
        alt="preview"
        style={{ width: '100px', height: '100px', objectFit: 'cover' }}
      />
    </div>
  ));

  React.useEffect(() => {
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(files)
    const uploadImages = files.map((file) => {
        const formData = new FormData();
        formData.append(`file`, file);
        return dispatch(addImagesToStorageFunc({api : imagesApi , data : formData}))
        .then(result => {
            console.log(result)
        })
    });
    Promise.all(uploadImages)
    .then(result => {
        setFiles([]);
        callbackFunc()
        toast.success(`Upload Images Successfully!`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        })
    })
    .catch(error => {
        toast.error(error, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        })
    })
  };

  return (
    <form onSubmit={handleSubmit} className='flex flex-col justify-center items-center'>
      <div
        {...getRootProps()}
        style={{
          border: '2px dashed #ddd',
          padding: '20px',
          textAlign: 'center',
          cursor: 'pointer',
        }}
        className='rounded-lg'
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the files here...</p>
        ) : (
          <p className='my-0'>Drag & drop some files here, or click to select files</p>
        )}
      </div>
      <aside style={{ display: 'flex', flexWrap: 'wrap', marginTop: '20px' }}>
        {thumbs}
      </aside>
      {
        files?.length >= 1
        &&
        <button type="submit" className={`btn btn-primary sw-btn-next ms-1 ${addImagesLoading ? "pointer-events-none opacity-70" : ""}`}>
            {
                addImagesLoading
                ?
                "loading.."
                :
                "Submit Files"
            }
            
        </button>
      }
    </form>
  );
};

export default MyDropzone;
