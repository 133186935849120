import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import SpinnerLoading from "../../components/spinner-loading/SpinnerLoading";
import PageTitle from "../../layouts/PageTitle";
import { ContextApi } from "../../../store/ContextApi";
import { toast } from "react-toastify";
import { getPaymentSettingsFunc } from "../../../store/custom-actions/settings-actions/payment-gateway-settings/GetPaymentSettingsSlicer";
import { postPaymentSettingsFunc } from "../../../store/custom-actions/settings-actions/payment-gateway-settings/PostPaymentSettingsSlicer";
import EmptyData from "../../components/empty-data/EmptyData";
import { Button } from "react-bootstrap";
// function PaymentsGatewaySettings() {
//     const contextApi = useContext(ContextApi);
//     const settings_URL_APi = `${contextApi.dashboard_URL_API}/general-settings/payment-gateway-settings`;
//     const {loading:getSettingsLoading , data} = useSelector(
//         state => state.getPaymentSettingsSlicer
//     );
//     const {loading:postSettingsLoading} = useSelector(
//         state => state.postPaymentSettingsSlicer
//     );
//     const dispatch = useDispatch();
//     const {register , setValue , formState : {errors} , handleSubmit} = useForm();
//     const [paymentsGateway , setPaymentsGateway] = useState({})
//     const getSettings = async () => {
//         dispatch(getPaymentSettingsFunc(settings_URL_APi))
//     }
//     useEffect(() => {
//         getSettings()
//     },[])
//     useEffect(() => {
//         if(data) {
//             setPaymentsGateway(data)
//         }
//     },[data])
//     const handleSubmitSettings = async () => {
//         if(Object.keys(paymentsGateway).length >= 1) {
//             const formData = new FormData();
//             for(const [key , value] of Object.entries(paymentsGateway)) {
//                 formData.append(key , value)
//             }
//             dispatch(postPaymentSettingsFunc({
//                 api : settings_URL_APi , 
//                 data : formData
//             }))
//             .then(result => {
//                 if(result.payload.success === true) {
//                     getSettings();
//                     toast.success(`${result.payload.message} !`, {
//                         position: "top-right",
//                         autoClose: 5000,
//                         hideProgressBar: false,
//                         closeOnClick: true,
//                         pauseOnHover: true,
//                         draggable: true,
//                     })
//                 }
//             })
//         }else {
//             toast.error(`Can Not Submit Empty Data!`, {
//                 position: "top-right",
//                 autoClose: 5000,
//                 hideProgressBar: false,
//                 closeOnClick: true,
//                 pauseOnHover: true,
//                 draggable: true,
//             })
//         }
//     }
//     const [key , setKey] = useState("")
//     const [valueOfKey , setValueOfKey] = useState("")
//     return (
//         <>
//             <PageTitle motherMenu={"Settings"} activeMenu={"Payments Gateway Settings"} customClasses={"mb-0"}/>
//             <div className="relative flex items-center justify-between my-3">
//                 <div className="relative flex items-center">
//                     <button class="rounded-lg relative w-36 h-10 cursor-pointer flex items-center border border-blue-500 bg-blue-500 group hover:bg-blue-500 active:bg-blue-500 active:border-blue-500 overflow-hidden"
//                         onClick={() => window.history.back()}
//                     >
//                         <span class="text-gray-200 font-semibold ml-8 transform group-hover:translate-x-20 transition-all duration-300">Back</span>
//                         <span class="absolute right-0 h-full w-10 rounded-lg bg-blue-500 flex items-center justify-center transform group-hover:translate-x-0 group-hover:w-full transition-all duration-300">
//                             <i className="fa-solid fa-arrow-left text-white"></i>
//                         </span>
//                     </button>
//                     <button 
//                         type="submit"
//                         form="payments-gateway-from"
//                         className={`flex items-center bg-primary text-white gap-1 px-4 py-2 cursor-pointer  font-semibold tracking-widest rounded-md hover:bg-blue-400 duration-300 ml-1 hover:gap-2 hover:translate-x-3
//                         ${postSettingsLoading ? "pointer-events-none opacity-80" : ""}
//                         `}
//                         >
//                             {
//                                 postSettingsLoading
//                                 ?
//                                 "Please Wait.."
//                                 :
//                                 <>
//                                     Submit
//                                     <i className="fa-solid fa-gear text-white ml-1"></i>
//                                 </>
//                             }
//                     </button>
//                 </div>
//             </div>
            
//             {
//                 getSettingsLoading
//                 ?
//                 <SpinnerLoading/>
//                 :
//                 (
//                     data && Object.keys(paymentsGateway).length >= 1
//                     ?
//                     <>
//                     <div className="row">
//                         <div className="col-xl-12 col-xxl-12">
//                             <div className="card p-3">
//                                 <form
//                                     id="payments-gateway-from"
//                                     onSubmit={handleSubmit((data) => {
//                                         handleSubmitSettings()
//                                     })}
//                                 >
//                                     <div className="row">
//                                         {
//                                         Object.keys(paymentsGateway).length < 1
//                                         &&
//                                         <p className="my-2 text-center">Add Product Attributes (At Least one Attribute..)</p>
//                                         }
//                                         {
//                                         Object.keys(paymentsGateway).length >= 1
//                                         &&
//                                         Object.keys(paymentsGateway).map((key , index) => {
//                                             return (
//                                             <div className="row flex items-center" key={index}>
//                                                 {/* <div className="form-group mb-3 col-sm-2 col-md-3"> */}
//                                                     {/* <input
//                                                     type="text"
//                                                     className="form-control key-input"
//                                                     value={key}
//                                                     disabled
//                                                     /> */}
//                                                 {/* </div> */}
//                                                 <div className="form-group mb-3 col-sm-12">
//                                                     <label >
//                                                         {key.replace(/_/g , " ")}
//                                                     </label>
//                                                     {
//                                                         key !== "paymob_card_gateway"
//                                                         &&
//                                                         key !== "paymob_wallet_gateway"
//                                                         &&
//                                                         key !== "cash_on_delivery"
//                                                         &&
//                                                         key !== "send_receipt"
//                                                         &&
//                                                         key !== "cash_on_site"
//                                                         ?
//                                                         <input
//                                                         type="text"
//                                                         className={`form-control`}
//                                                         defaultValue={paymentsGateway[key]}
//                                                         // disabled
//                                                         onChange={(e) => {
//                                                             setPaymentsGateway((prevObject) => ({
//                                                                 ...prevObject,
//                                                                 [key]: e.target.value,
//                                                             }));
//                                                         }}
//                                                         />
//                                                         :
//                                                         <input
//                                                         type="checkbox"
//                                                         id="customCheckBox5"
//                                                         className="form-check-input scale-110 cursor-pointer ml-2"
//                                                         checked = {parseInt(paymentsGateway[key]) === 1 ? true : false}
//                                                         onChange={(e) => {
//                                                             console.log(e.target.checked)
//                                                             setPaymentsGateway((prevObject) => ({
//                                                                 ...prevObject,
//                                                                 [key]: e.target.checked ? 1 : 0,
//                                                             }));
//                                                         }}
//                                                         />
//                                                     }
//                                                 </div>
//                                             </div>
//                                             )
//                                         })
//                                         }
//                                     </div>
//                                 </form>
//                             </div>
//                         </div>
//                     </div>
//                     </>	
//                 :
//                 <EmptyData/>
//                 )
//             }
//         </>
//     )
// }


// export default PaymentsGatewaySettings;



export default function PaymentsGatewaySettings(){
    const contextApi = useContext(ContextApi);
    const settings_URL_APi = `${contextApi.dashboard_URL_API}/general-settings/payment-gateway-settings`;
    const {loading:getSettingsLoading , data} = useSelector(
        state => state.getPaymentSettingsSlicer
    );
    const {loading:postSettingsLoading} = useSelector(
        state => state.postPaymentSettingsSlicer
    );
    const dispatch = useDispatch();
    const {register , setValue , formState : {errors} , handleSubmit} = useForm();
    const [paymentsGateway , setPaymentsGateway] = useState({})
    const getSettings = async () => {
        dispatch(getPaymentSettingsFunc(settings_URL_APi))
    }
    useEffect(() => {
        getSettings()
    },[])
    useEffect(() => {
        if(data) {
            setPaymentsGateway(data)
        }
    },[data])
    useEffect(() => {
        data && setPaymentsGateway(data);
        if(data) {
            setValue("paymob_card_api_key" , data.paymob_card_api_key);
            setValue("paymob_card_gateway" , data.paymob_card_gateway);
            setValue("paymob_card_hmac" , data.paymob_card_hmac);
            setValue("paymob_card_iframe_id" , data.paymob_card_iframe_id);
            setValue("paymob_card_integration_id" , data.paymob_card_integration_id);
            setValue("paymob_wallet_api_key" , data.paymob_wallet_api_key);
            setValue("paymob_wallet_gateway" , data.paymob_wallet_gateway);
            setValue("paymob_wallet_hmac" , data.paymob_wallet_hmac);
            setValue("paymob_wallet_integration_id" , data.paymob_wallet_integration_id);
            setValue("send_receipt" , data.send_receipt);
            setValue("cash_on_site" , data.cash_on_site);
            setValue("cash_on_delivery" , data.cash_on_delivery);
        }
    },[data])
    const handleSubmitSettings = async () => {
        if(Object.keys(paymentsGateway).length >= 1) {
            const formData = new FormData();
            for(const [key , value] of Object.entries(paymentsGateway)) {
                formData.append(key , value)
            }
            dispatch(postPaymentSettingsFunc({
                api : settings_URL_APi , 
                data : formData
            }))
            .then(result => {
                if(result.payload.success === true) {
                    getSettings();
                    toast.success(`${result.payload.message} !`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    })
                }
            })
        }else {
            toast.error(`Can Not Submit Empty Data!`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            })
        }
    }
    const [key , setKey] = useState("")
    const [valueOfKey , setValueOfKey] = useState("")

    return (
        <>
        <PageTitle motherMenu={"Dashboard"} activeMenu={"Payments Settings"} />
        <div className="relative flex items-center justify-between my-3">
                <div className="relative flex items-center">
                    <button 
                        type="submit"
                        form="payments-gateway-from"
                        className={`flex items-center bg-primary text-white gap-1 px-4 py-2 cursor-pointer  font-semibold tracking-widest rounded-md hover:bg-orange-400 duration-300 ml-1 hover:gap-2 hover:translate-x-3
                        ${postSettingsLoading ? "pointer-events-none opacity-80" : ""}
                        `}
                        >
                            {
                                postSettingsLoading
                                ?
                                <>Please Wait...</>
                                :
                                <>
                                    Submit
                                    <i className="fa-solid fa-gear text-white ml-1"></i>
                                </>
                            }
                    </button>
                </div>
        </div>
            {
                getSettingsLoading
                ?
                (
                <SpinnerLoading/>
                )
                :
                (
                
                    data
                    ?
                    (
                        paymentsGateway
                        &&
                        Object.keys(paymentsGateway).length >= 1
                        ?
                        
                            <form
                            id="payments-gateway-from"
                            onSubmit={handleSubmit((data) => {
                                handleSubmitSettings(data)
                            })}
                        >
                            <div className="row">
                                <div className="col-sm-12 col-md-4">
                                    <div className="card p-3 paymob-card">
                                        <h3>Paymob Card</h3>
                                        <div className="row">
                                            <div className="form-group mb-2 col-12">
                                                <label >
                                                    paymob card api key
                                                </label>
                                                <input
                                                    {...register("paymob_card_api_key")}
                                                    type="text"
                                                    className={`form-control`}
                                                    onChange={(e) => {
                                                        setPaymentsGateway((prevObject) => ({
                                                            ...prevObject,
                                                            ["paymob_card_api_key"]: e.target.value,
                                                        }));
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group mb-2 col-12">
                                                {/* <input
                                                    {...register("paymob_card_gateway")}
                                                    type="checkbox"
                                                    id="customCheckBox5"
                                                    className={`scale-110 mx-2 cursor-pointer`}
                                                    onChange={(e) => {
                                                        setPaymentsGateway((prevObject) => ({
                                                            ...prevObject,
                                                            ["paymob_card_gateway"]: e.target.checked ? 1 : 0,
                                                        }));
                                                    }}
                                                /> */}
                                                <label className="cursor-pointer" htmlFor="customCheckBox5">
                                                    paymob card gateway
                                                </label>
                                                <select
                                                {...register("paymob_card_gateway")}
                                                className="form-control"
                                                onChange={(e) => {
                                                    setPaymentsGateway((prevObject) => ({
                                                        ...prevObject,
                                                        ["paymob_card_gateway"]: e.target.value,
                                                    }));
                                                }}
                                                >
                                                    <option value={1}>Enable</option>
                                                    <option value={0}>Disable</option>
                                                </select>
                                            </div>
                                            <div className="form-group mb-2 col-12">
                                                <label >
                                                    paymob card hmac
                                                </label>
                                                <input
                                                    {...register("paymob_card_hmac")}
                                                    type="text"
                                                    className={`form-control`}
                                                    onChange={(e) => {
                                                        setPaymentsGateway((prevObject) => ({
                                                            ...prevObject,
                                                            ["paymob_card_hmac"]: e.target.value,
                                                        }));
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group mb-2 col-12">
                                                <label >
                                                    paymob card iframe id
                                                </label>
                                                <input
                                                    {...register("paymob_card_iframe_id")}
                                                    type="text"
                                                    className={`form-control`}
                                                    onChange={(e) => {
                                                        setPaymentsGateway((prevObject) => ({
                                                            ...prevObject,
                                                            ["paymob_card_iframe_id"]: e.target.value,
                                                        }));
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group mb-2 col-12">
                                                <label >
                                                    paymob card integration id
                                                </label>
                                                <input
                                                    {...register("paymob_card_integration_id")}
                                                    type="text"
                                                    className={`form-control`}
                                                    onChange={(e) => {
                                                        setPaymentsGateway((prevObject) => ({
                                                            ...prevObject,
                                                            ["paymob_card_integration_id"]: e.target.value,
                                                        }));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-4">
                                    <div className="card p-3 paymob-wallet">
                                        <h3>Paymob Wallet</h3>
                                        <div className="row">
                                            <div className="form-group mb-2 col-sm-12">
                                                <label >
                                                    paymob wallet api key
                                                </label>
                                                <input
                                                    {...register("paymob_wallet_api_key")}
                                                    type="text"
                                                    className={`form-control`}
                                                    onChange={(e) => {
                                                        setPaymentsGateway((prevObject) => ({
                                                            ...prevObject,
                                                            ["paymob_wallet_api_key"]: e.target.value,
                                                        }));
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group mb-2 col-sm-12">
                                                {/* <input
                                                    {...register("paymob_wallet_gateway")}
                                                    type="checkbox"
                                                    id="customCheckBox4"
                                                    className={`scale-110 mx-2 cursor-pointer`}
                                                    onChange={(e) => {
                                                        setPaymentsGateway((prevObject) => ({
                                                            ...prevObject,
                                                            ["paymob_wallet_gateway"]: e.target.checked ? 1 : 0,
                                                        }));
                                                    }}
                                                /> */}
                                                <label className="cursor-pointer" htmlFor="customCheckBox4">
                                                    paymob wallet gateway
                                                </label>
                                                <select
                                                {...register("paymob_wallet_gateway")}
                                                className="form-control"
                                                onChange={(e) => {
                                                    setPaymentsGateway((prevObject) => ({
                                                        ...prevObject,
                                                        ["paymob_wallet_gateway"]: e.target.value,
                                                    }));
                                                }}
                                                >
                                                    <option value={1}>Enable</option>
                                                    <option value={0}>Disable</option>
                                                </select>
                                            </div>
                                            <div className="form-group mb-2 col-sm-12">
                                                <label >
                                                    paymob wallet hmac
                                                </label>
                                                <input
                                                    {...register("paymob_wallet_hmac")}
                                                    type="text"
                                                    className={`form-control`}
                                                    onChange={(e) => {
                                                        setPaymentsGateway((prevObject) => ({
                                                            ...prevObject,
                                                            ["paymob_wallet_hmac"]: e.target.value,
                                                        }));
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group mb-2 col-sm-12">
                                                <label >
                                                    paymob wallet integration id
                                                </label>
                                                <input
                                                    {...register("paymob_wallet_integration_id")}
                                                    type="text"
                                                    className={`form-control`}
                                                    onChange={(e) => {
                                                        setPaymentsGateway((prevObject) => ({
                                                            ...prevObject,
                                                            ["paymob_wallet_integration_id"]: e.target.value,
                                                        }));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-4">
                                    <div className="card p-3 paymob-card">
                                        <h3>Send Receipt</h3>
                                        <div className="row">
                                            <div className="form-group mb-2 col-sm-12">
                                                <label className="cursor-pointer" htmlFor="customCheckBox3">
                                                    Send Receipt gateway
                                                </label>
                                                <select
                                                {...register("send_receipt")}
                                                className="form-control"
                                                onChange={(e) => {
                                                    setPaymentsGateway((prevObject) => ({
                                                        ...prevObject,
                                                        ["send_receipt"]: e.target.value,
                                                    }));
                                                }}
                                                >
                                                    <option value={1}>Enable</option>
                                                    <option value={0}>Disable</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-4">
                                    <div className="card p-3 paymob-card">
                                        <h3>Cash On Site</h3>
                                        <div className="row">
                                            <div className="form-group mb-2 col-sm-12">
                                                <label className="cursor-pointer" htmlFor="customCheckBox3">
                                                    Cash On Site
                                                </label>
                                                <select
                                                {...register("cash_on_site")}
                                                className="form-control"
                                                onChange={(e) => {
                                                    setPaymentsGateway((prevObject) => ({
                                                        ...prevObject,
                                                        ["cash_on_site"]: e.target.value,
                                                    }));
                                                }}
                                                >
                                                    <option value={1}>Enable</option>
                                                    <option value={0}>Disable</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-4">
                                    <div className="card p-3 paymob-card">
                                        <h3>Cash On Delivery</h3>
                                        <div className="row">
                                            <div className="form-group mb-2 col-sm-12">
                                                <label className="cursor-pointer" htmlFor="customCheckBox3">
                                                    Cash On Delivery
                                                </label>
                                                <select
                                                {...register("cash_on_delivery")}
                                                className="form-control"
                                                onChange={(e) => {
                                                    setPaymentsGateway((prevObject) => ({
                                                        ...prevObject,
                                                        ["cash_on_delivery"]: e.target.value,
                                                    }));
                                                }}
                                                >
                                                    <option value={1}>Enable</option>
                                                    <option value={0}>Disable</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        :
                        <div className='relative w-full h-[60vh] flex flex-col justify-center items-center'>
                            <p>No data available
                            </p>
                        </div>
                        
                    )
                    :
                    <div className='relative w-full h-[60vh] flex flex-col justify-center items-center'>
                        <p>No data available
                        <span className="text-[#4d5fe3] cursor-pointer"
                        onClick={()=> getSettings()}
                        >
                            Try again
                        </span>
                        </p>
                    </div>
                )
            }
        </>
    )
}