import { CloseButton, Modal, Nav , Tab} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getSiteImagesFunc } from "../../../store/custom-actions/images/GetSiteImagesSlicer";
import { useContext, useEffect, useState } from "react";
import { ContextApi } from "../../../store/ContextApi";
import MyDropzone from "./DropZone";
import ImagePicker from 'react-image-picker';
import 'react-image-picker/dist/index.css';
import "./upload-images-modale.css";
import CheckboxBtn from "./CheckboxInput";
export default function UploadImagesModale({show , onHide , multipleOption , setImagesFunc , checkedList}) {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const contextApi = useContext(ContextApi);
    const imagesApi = `${contextApi.dashboard_URL_API}/uploads`;
    const [activeTab , setActiveTab] = useState("Choose")
    const {loading:GetImagesLoading , data} = useSelector(
        state => state.getSiteImagesSlicer
    )
    const [imagesListWillAdd , setImagesListWillAdd] = useState([])
    const [dataList , setDataList] = useState([])
    const handleGetImages = async () => {
        dispatch(getSiteImagesFunc(imagesApi))
        .then(result => {
            console.log(result);
            if(result?.payload) {
                setDataList(result?.payload?.uploads);
            }
        })
    }
    useEffect(() => show && handleGetImages() , [show])

    const onPickImages = (pickedImages) => {
        console.log('Picked images:', pickedImages); // To verify selection
        setImagesListWillAdd(pickedImages); // This function should update the selected images
    };
    const refetchData = () => {
        setActiveTab("Choose")
        handleGetImages();
    }
    useEffect(() => {
        !show && setImagesListWillAdd([])
    },[show])
    const handleChange = (e , id , src) => {
        multipleOption === true 
        ? setImagesListWillAdd((prev) => [...prev, { value: id, src }])
        : setImagesListWillAdd([{ value: id, src }])
    }
    const handleRemove = (id) => {
        const filtred = imagesListWillAdd?.filter(item => item?.id !== id);
        setImagesListWillAdd(prev => [...prev , filtred])
    }
    useEffect(() => {
        console.log(checkedList)
        if(checkedList?.length >= 1) {
            setImagesListWillAdd(checkedList)
        }
    } , [checkedList])
    useEffect(() => {
        console.log(imagesListWillAdd)
    },[imagesListWillAdd])
    const removingSt = (arrayAfterRemoving) => {
        setImagesListWillAdd(arrayAfterRemoving)
    }
      const additionSt = (id) => {
        setImagesListWillAdd([...imagesListWillAdd , id])
    }
    return (
        <>
            <Modal show={show} size="lg" className="xxl" onHide={() => onHide()} style={{zIndex : 999999999}}>
                <Modal.Body>
                <div className=" style-2">
                    <Tab.Container defaultActiveKey={"Choose"} activeKey={activeTab}>
                        <nav>
                            <Nav as='div' className="nav nav-tabs justify-content-start tab-auto" id="nav-tab" role="tablist">
                                <Nav.Link as='button' className="nav-link" id="" eventKey='Choose' onClick={() => setActiveTab("Choose")}>{t("Choose")}</Nav.Link>
                                <Nav.Link as='button' className="nav-link" id=""  eventKey='Upload' onClick={() => setActiveTab("Upload")}>{t("Upload")}</Nav.Link>
                            </Nav>
                            <div className="relative w-full flex justify-end items-center my-2">
                                <button type="submit" className={`btn btn-primary sw-btn-next ms-1 ${(!imagesListWillAdd || imagesListWillAdd?.length <= 0) ? "pointer-events-none opacity-70" : ""}`}
                                onClick={() => {
                                    setImagesFunc(imagesListWillAdd);
                                    onHide();
                                }}
                                >
                                    Submit Files
                                </button>
                                <CloseButton onClick={onHide}/>
                            </div>
                            
                        </nav>
                      <Tab.Content >
                        <Tab.Pane eventKey='Choose' className="">
                            {
                                GetImagesLoading
                                ?
                                <div className="h-[80vh] flex justify-center items-center w-full">loaidng...</div>
                                :
                                <div className="relative image-picker-parent flex flex-wrap h-[80vh] overflow-y-auto p-3">
                                    {
                                        dataList?.length >=1
                                        ?
                                        (
                                            // <ImagePicker
                                            //     images={dataList.map((image, index) => {
                                            //         return ({ 
                                            //             src: `${contextApi.domainUrl}/${image?.path}`, value: image?.id,
                                            //         })
                                            //     })}
                                            //     onPick={onPickImages}
                                            //     multiple = {multipleOption}
                                            // />
                                            dataList?.map((image , index) => {
                                                const alreadyChecked = imagesListWillAdd?.length >= 1 &&  imagesListWillAdd?.some((item) => {
                                                    return parseInt(item.value) === parseInt(image.id)
                                                })
                                                console.log(alreadyChecked)
                                                return (
                                                <div className="m-2" key={index}>
                                                    {
                                                        multipleOption === true
                                                        ?
                                                        <>
                                                        <CheckboxBtn              
                                                            index={index}
                                                            key={index}
                                                            image = {image}
                                                            arrayWillSend = {imagesListWillAdd}
                                                            removing = {removingSt}
                                                            additionDone = {additionSt}
                                                        />
                                                        </>
                                                        :
                                                        <>
                                                            <input type="radio" name="img" id={index}
                                                            checked = {alreadyChecked}
                                                            onChange={(e) => {
                                                                if(e.target.checked) {
                                                                    handleChange(e , image.id , `${contextApi.domainUrl}/${image?.path}`)
                                                                }else {
                                                                    handleRemove(image?.id)
                                                                }
                                                            }}
                                                            className="hidden"
                                                            />
                                                            <label
                                                            className={`relative w-[120px] h-[120px] bg-center bg-no-repeat bg-cover cursor-pointer rounded-md p-1 image-picker-label ${alreadyChecked ? "border-2 border-primary" : ""} ${image.id}`} htmlFor={index}
                                                            >
                                                                <img src={`${contextApi.domainUrl}/${image?.path}`} className="w-full h-full object-cover" />
                                                            </label>
                                                        </>
                                                    }
                                                </div>
                                                
                                            )})
                                        )
                                        :
                                        <div className="absolute top-0 w-full h-full flex justify-center items-center">
                                            <p>No data avilable</p>
                                        </div>
                                    }
                                </div>
                            }
                        </Tab.Pane>
                        <Tab.Pane eventKey='Upload' className="flex justify-center items-center  h-[80vh]">											
                            <div className="relative flex flex-col justify-center items-center w-full h-full">
                                <MyDropzone callbackFunc={refetchData}/>
                            </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>	
                  </div>

                </Modal.Body>
            </Modal>
        </>
    )
}