import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ContextApi } from "../../../store/ContextApi";
import PageTitle from "../../layouts/PageTitle";
import SpinnerLoading from "../../components/spinner-loading/SpinnerLoading";
import FaildLoadData from "../../global-components/FaildLoadData/FaildLoadData";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import LoadingScreen from "../../components/loading-screen/LoadingScreen";
import { toast } from "react-toastify";
import checkPermissions from "../../global-functions/checkPermissionsFunc";
import { getLanguagesFunc } from "../../../store/custom-actions/language/GetLanguagesSlicer";
import { deleteLanguageFunc } from "../../../store/custom-actions/language/DeleteLanguageSlicer";
import { makeLangDefaultFunc } from "../../../store/custom-actions/language/MakeLangDefaultSlicer";
import LanguageDescion from "./languageDescion";
import NewLangForm from "../../custom-forms/settings-pages/languages/NewLang";

export default function LanguagesPage() {
    const {loading:getLanguagesLoading , data:languagesData} = useSelector(state => state.getLanguageSlicer)
    const {loading:deleteLanguageLoading} = useSelector(state => state.deleteLanguageSlicer)
    const {loading:makeLangDefaultLoading} = useSelector(state => state.makeLangDefaultSlicer)
    const contextApi = useContext(ContextApi);
    const languages_API_URL = `${contextApi.dashboard_URL_API}/languages`;
    const [languagesList , setLanguagesList] = useState([]);
    const [searchValue , setSearchValue] = useState("");
    const [showNewLangForm , setShowNewLangForm] = useState();
    const dispatch = useDispatch();

    useEffect(() => {
        const getLanguages = async () => {
          try {
            dispatch(getLanguagesFunc(languages_API_URL))
          } catch (error) {
            console.log(error);
          }
        };
        getLanguages();
      }, []);
      useEffect(() => {
        languagesData && setLanguagesList(languagesData);
        languagesData && (jobData.current = languagesData.slice(
            activePag.current * sort,
            (activePag.current + 1) * sort
          ));
      }, [languagesData]);
      const sort = 20;
      let paggination = Array(Math.ceil(languagesList.length / sort))
        .fill()
        .map((_, i) => i + 1);
    
      const activePag = useRef(0);
      const jobData = useRef(
        languagesList.slice(
          activePag.current * sort,
          (activePag.current + 1) * sort
        )
      );
      const onClick = (i) => {
        activePag.current = i;
        jobData.current = languagesList.slice(
          activePag.current * sort,
          (activePag.current + 1) * sort
        );
      };
      const search = () => {
        if(searchValue !== "") {
          const searchResult = languagesData &&
          languagesData.length >= 1 && languagesData.filter((lang) =>  lang.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()));
          jobData.current = searchResult
        }else {
          dispatch(getLanguagesFunc(languages_API_URL))
        }
      }
      useEffect(() => {
        searchValue === "" && dispatch(getLanguagesFunc(languages_API_URL));
      },[searchValue])
      const deleteLang = async (id) => {
        swal({
          title: "Confirm Language Deletion",
          text: `Once deleted, This Language will not be avilable!`,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            dispatch(deleteLanguageFunc(`${languages_API_URL}/${id}`))
            .then((result) => {
              if(result.payload.success === true){
                dispatch(getLanguagesFunc(languages_API_URL));
                toast.success(`${result.payload.message} !`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
              })
              }
            })
          }
        });
      };

      const makeLangDefault = async (id) => {
        swal({
          title: "Confirm Make This Language Default",
          text: `Once Make it, This Language Will be In all of App!`,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            const form = new FormData();
            form.append("_method" , "PUT")
            dispatch(makeLangDefaultFunc({api : `${languages_API_URL}/${id}/make-default` , data : form}))
            .then((result) => {
              if(result.payload.success === true){
                dispatch(getLanguagesFunc(languages_API_URL));
                toast.success(`${result.payload.message} !`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
              })
              }
            })
          }
        });
      };

    return (
        <>
        <PageTitle motherMenu={"Dashboard"} activeMenu={"Languages"} />
        {/* {
            !getLanguagesLoading && languagesList.length < 1
            &&
            checkPermissions(
              "create language",
              <div className="relative mt-2 flex justify-end items-center">
                <Link 
                to={"#"} 
                className="btn btn-primary btn-sm"
                onClick={() => setShowNewLangForm(true)}
                >New Language</Link>
              </div>
            )
        } */}
        {
            getLanguagesLoading
            ?
            (
                <SpinnerLoading/>
            )
            :
            (
                languagesData
                ?
                (
                    <div className="col-12">
                    <div className="card">
                      <div className="card-header flex justify-between items-center">
                        <h4 className="card-title">Languages</h4>
                        <div className="relative add-and-search flex items-center">
                          <div className="input-group search-area w-auto mr-2">
                              <span className="input-group-text"
                                onClick={() => search()}
                              >
                                  <Link to={"#"}>
                                  <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 32 32"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                  >
                                      <path
                                      d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                                      fill="var(--primary)"
                                      ></path>
                                  </svg>
                                  </Link>
                              </span>
                              <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search here..."
                                  onChange={(e) => {
                                  setSearchValue(e.target.value);
                                  }}
                              />
                          </div>
                          {
                            checkPermissions(
                              "create language",
                              <Link 
                              to={"#"} 
                              className="btn btn-primary btn-sm"
                              onClick={() => setShowNewLangForm(true)}
                              >New Language</Link>
                            )
                          }
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="w-100 table-responsive">
                          <div id="example_wrapper" className="dataTables_wrapper">
                            <table id="example" className="display w-100 dataTable">
                              <thead>
                                <tr role="row">
                                  <th>#ID</th>
                                  <th>Lang</th>
                                  <th>Direction</th>
                                  <th>Slug</th>
                                  <th>Default</th>
                                  {/* <th>Limit</th>
                                  <th>Type</th>
                                  <th>Discount</th>
                                  <th>Users</th> */}
                                  {
                                    checkPermissions(
                                      ["update coupons" , "delete coupons"],
                                      <th>Actions</th>
                                    )
                                  }
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  languagesList && languagesList.length >= 1
                                  ?
                                  jobData.current.map((lang , index) => {
                                      return (
                                          <tr key={index}>
                                              <td>{lang.id}</td>
                                              <td>{lang.name}</td>
                                              <td>{lang.direction && lang.direction.toUpperCase()}</td>
                                              <td>{lang.slug}</td>
                                              <td>
                                                {
                                                    lang.default ===1
                                                    ?
                                                    "Yes"
                                                    :
                                                    "No"
                                                }
                                              </td>
                                              {
                                                checkPermissions(
                                                  ["update language" , "delete language"],
                                                  <td>
                                                      {/* <Link
                                                          to="#"
                                                          className="btn btn-danger shadow btn-xs sharp"
                                                          onClick={() => {
                                                            deleteLang(lang.id)
                                                          }}
                                                      >
                                                          <i className="fa fa-trash"></i>
                                                      </Link> */}
                                                      <LanguageDescion
                                                        langId={lang.id}
                                                        deleteFunction={() => deleteLang(lang.id)}
                                                        makeDefaultFunction={() => makeLangDefault(lang.id)}
                                                        defaultStatus = {lang.default}
                                                      />
                                                  </td>
                                                )
                                              }
                                          </tr>
                                      )
                                  }) 
                                  :
                                  <tr>
                                    <td colSpan={4}>No data avilable</td>
                                  </tr>
                                }
                              </tbody>
                            </table>
              
                            <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-2">
                              <div className="dataTables_info">
                                Showing {activePag.current * sort + 1} to{" "}
                                {languagesList.length > (activePag.current + 1) * sort
                                  ? (activePag.current + 1) * sort
                                  : languagesList.length}{" "}
                                of {languagesList.length} entries
                              </div>
                              <div
                                className="dataTables_paginate paging_simple_numbers mb-0"
                                id="example5_paginate"
                              >
                                <Link
                                  className="paginate_button previous disabled"
                                //   to="/table-datatable-basic"
                                  to="#"
                                  onClick={() =>
                                    activePag.current > 0 && onClick(activePag.current - 1)
                                  }
                                >
                                  <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                                </Link>
                                <span>
                                  {paggination.map((number, i) => (
                                    <Link
                                      key={i}
                                    //   to="/table-datatable-basic"
                                      to="#"
                                      className={`paginate_button  ${
                                        activePag.current === i ? "current" : ""
                                      } `}
                                      onClick={() => onClick(i)}
                                    >
                                      {number}
                                    </Link>
                                  ))}
                                </span>
                                <Link
                                  className="paginate_button next"
                                //   to="/table-datatable-basic"
                                  to="#"
                                  onClick={() =>
                                    activePag.current + 1 < paggination.length &&
                                    onClick(activePag.current + 1)
                                  }
                                >
                                  <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    </div>
                )
                :
                <FaildLoadData
                    refetchDataFunc={() => dispatch(getLanguagesFunc(languages_API_URL))}
                />
            )
        }
        <LoadingScreen loading={deleteLanguageLoading} />
        <LoadingScreen loading={makeLangDefaultLoading} />
        <NewLangForm
        show={showNewLangForm}
        hide={() => setShowNewLangForm(false)}
        languagesApi={languages_API_URL}
        />
        </>
    )
}