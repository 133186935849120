import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ContextApi } from "../../../store/ContextApi";
import PageTitle from "../../layouts/PageTitle";
import SpinnerLoading from "../../components/spinner-loading/SpinnerLoading";
import FaildLoadData from "../../global-components/FaildLoadData/FaildLoadData";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import LoadingScreen from "../../components/loading-screen/LoadingScreen";
import { toast } from "react-toastify";
import { getAllProductsFunc } from "../../../store/custom-actions/products-actions/GetAllProductsSlicer";
import { deleteProductFunc } from "../../../store/custom-actions/products-actions/DeleteProductSlicer";
import checkPermissions from "../../global-functions/checkPermissionsFunc";
import { Button, Dropdown, Modal } from "react-bootstrap";

export default function ProductsPage() {
    const contextApi = useContext(ContextApi);
    const site_global_currency = contextApi.homeSettings.site_global_currency;
    const productsApi = `${contextApi.dashboard_URL_API}/products`;
    const [productsList , setProductsList] = useState([]);
    const dispatch = useDispatch();
    const {loading:getProductsLoading , data:productsData} = useSelector(
      state => state.getAllProductsSlicer
    )
    const {loading:deleteProductLoading} = useSelector(
      state => state.deleteProductSlicer
    )
	const [searchValue , setSearchValue] = useState("")
	const [filterName , setFilterName] = useState("All")
	const [recommendedStatusFilter , setRecommendedStatusFilter] = useState("")
	const [activeFilter , setActiveFilter] = useState("")
    useEffect(() => {
        const getProducts = async () => {
            dispatch(getAllProductsFunc(productsApi))
            .then((result) => console.log(result))
        }
        getProducts()
    },[])
    useEffect(() => {
        productsData && setProductsList(productsData);
    },[productsData])

      useEffect(() => {
        productsData && setProductsList(productsData);
        productsData && (jobData.current = productsData.slice(
            activePag.current * sort,
            (activePag.current + 1) * sort
          ));
      }, [productsData]);
      const sort = 20;
      let paggination = Array(Math.ceil(productsList.length / sort))
        .fill()
        .map((_, i) => i + 1);
    
      const activePag = useRef(0);
      const jobData = useRef(
        productsList.slice(
          activePag.current * sort,
          (activePag.current + 1) * sort
        )
      );
      const onClick = (i) => {
        activePag.current = i;
        jobData.current = productsList.slice(
          activePag.current * sort,
          (activePag.current + 1) * sort
        );
      };
      const search = () => {
        if(searchValue !== "") {
          const searchResult = productsData &&
          productsData.length >= 1 && productsData.filter((product) =>  
            product.title.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
            ||
            product.sku.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
        );
          jobData.current = searchResult
        }else {
          dispatch(getAllProductsFunc(productsApi))
        }
      }
      useEffect(() => {
        searchValue === "" && dispatch(getAllProductsFunc(productsApi));
      },[searchValue])
      const deleteProductFunction = async (id) => {
        swal({
          title: "Confirm Product Deletion",
          text: `Once deleted, This Product will not be avilable!`,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            dispatch(deleteProductFunc(`${productsApi}/${id}`))
            .then((result) => {
              if(result.payload.success === true){
                dispatch(getAllProductsFunc(productsApi));
                toast.success(`${result.payload.message} !`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
              })
              }
            })
          }
        });
      };
      // const hideFilterOptions = () => {
      //   setShowFilterOptions(false);
      // }
      const filterFunc = () => {
        const filterApi = `${productsApi}?${recommendedStatusFilter !== "" ? `recomended=${recommendedStatusFilter}` : ""}&${activeFilter !== "" ? `active=${activeFilter}` : ""}`;
        dispatch(getAllProductsFunc(filterApi))
        .then(result => {
          console.log(result)
          // if(result.payload.success === true) {
            const filterProductsObject = {
              active : activeFilter,
              // featured : featuredFilter,
              recomended : recommendedStatusFilter,
            }
            window.localStorage.setItem("filteProductsrObjct" , JSON.stringify(filterProductsObject));
            const filterObj = JSON.parse(window.localStorage.getItem("filteProductsrObjct"));
            console.log(filterObj)
          // }
        })
      }
      useEffect(() => {
        filterFunc()
      },[recommendedStatusFilter , activeFilter])
      useEffect(() => {
        if(window.localStorage.getItem("filteProductsrObjct")) {
          const filterObj = JSON.parse(window.localStorage.getItem("filteProductsrObjct"));
          // setFeaturedFilter(filterObj.featured)
          setRecommendedStatusFilter(filterObj.recomended)
          setActiveFilter(filterObj.active)
        }else {
          // setFeaturedFilter("");
          setRecommendedStatusFilter("");
          setActiveFilter("");
        }
        return () => {
          window.localStorage.removeItem("filteProductsrObjct")
        }
      },[])
    return (
        <>
        <PageTitle motherMenu={"Dashboard"} activeMenu={"Products"} />
        <div className="col-12">
          <div className="card">
            <div className="card-header flex justify-between items-center">
              <h4 className="card-title">Products</h4>
              <div className="relative add-and-search flex items-center">
                <div className="input-group search-area w-auto mr-2">
                    <span className="input-group-text"
                      onClick={() => search()}
                    >
                        <Link to={"#"}>
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                            d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                            fill="var(--primary)"
                            ></path>
                        </svg>
                        </Link>
                    </span>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Search here..."
                        onChange={(e) => {
                        setSearchValue(e.target.value);
                        }}
                    />
                </div>
                {
                  checkPermissions(
                    "create products",
                    <Link 
                    to={"/new-item"} 
                    className="btn btn-primary btn-sm bg-primary"
                    >New Item</Link>
                  )
                }
                {/* <Link 
                  to={"#"} 
                  className="btn btn-primary btn-sm ml-2"
                  onClick={() => setShowFilterOptions(true)}
                  >
                    Filter
                    <i className="fa-solid fa-filter ml-1"></i>
                </Link> */}
                <Dropdown
                className="mx-1"
                >
                  <Dropdown.Toggle variant="primary" id="dropdown-basic">
                    {filterName}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => {setActiveFilter(""); setRecommendedStatusFilter(""); setFilterName("All")}}>All</Dropdown.Item>
                    <Dropdown.Item onClick={() => {setActiveFilter(1); setRecommendedStatusFilter(""); setFilterName("Active")}}>Active</Dropdown.Item>
                    <Dropdown.Item onClick={() => {setRecommendedStatusFilter(1); setActiveFilter(""); setFilterName("Recommended")}}>Recommended</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className="card-body">
              <div className="w-100 table-responsive">
                <div id="example_wrapper" className="dataTables_wrapper">
                  <table id="example" className="display w-100 dataTable">
                    <thead>
                      <tr role="row">
                        <th>#ID</th>
                        <th>Product</th>
                        <th>SKU</th>
                        <th>Sub Category</th>
                        <th>Price</th>
                        <th>Before Discount</th>
                        <th>Status</th>
                        {
                          checkPermissions(
                            ["delete products" , "update products"],
                            <th>Actions</th>
                          )
                        }
                      </tr>
                    </thead>
                    <tbody>
                      {
                        getProductsLoading
                        ?
                        (
                          <tr>
                            <td colSpan={2}>
                              <SpinnerLoading/>
                            </td>
                          </tr>
                        )
                        :
                        (
                        productsData && productsList.length >= 1
                        ?
                        jobData.current.map((product , index) => {
                            return (
                                <tr key={index}>
                                    <td>{product.id}</td>
                                    <td>
                                      <Link to={`/product-details/${product.id}`} className="relative flex items-center">
                                        <img 
                                          src={product.image_url ? product.image_url : "https://i.pinimg.com/564x/b9/8f/43/b98f4351a2f0e183156d8583e8184f9a.jpg"} 
                                          className="mr-1 w-[30px] h-[30px] rounded-[50%] cursor-pointer" 
                                          alt="user img"
                                        />
                                        {product.title}
                                      </Link>
                                    </td>
              <td>{product.sku}</td>
              <td>{product.sub_category && product.sub_category.name && product.sub_category.name}</td>
                                    <td>{product.price} {site_global_currency}</td>
                                    <td>{parseInt(product.discount) >= 1 ? <del>{product.discount} {site_global_currency}</del> : "No Discount"}</td>
              <td>
                                      <span className={`relative text-sm rounded-lg text-white ${product.active === 1 ? "bg-green-500" : "bg-red-500"} p-1`}>
                                          {
                                              product.active === 1
                                              ?
                                              "Active"
                                              :
                                              "Not Active"
                                          }
                                      </span>
                                    </td>
                                    {
                                      checkPermissions(
                                        ["delete products" , "update products"],
                                        <td>
                                          {
                                            checkPermissions(
                                              "update products",
                                              <Link
                                                  to={`/update-item/${product.id}`}
                                                  className="btn btn-primary shadow btn-xs sharp me-1"
                                              >
                                                  <i className="fas fa-pencil-alt"></i>
                                              </Link>
                                            )
                                          }
                                          {
                                            checkPermissions(
                                              "delete products",
                                              <Link
                                                  to="#"
                                                  className="btn btn-danger shadow btn-xs sharp"
                                                  onClick={() => {
                                                      deleteProductFunction(product.id)
                                                  }}
                                              >
                                                  <i className="fa fa-trash"></i>
                                              </Link>
                                            )
                                          }
                                        </td>
                                      )
                                    }
                                </tr>
                            )
                        }) 
                        :
                        <tr>
                          <td colSpan={4}>No data avilable</td>
                        </tr>
                        )
                      }
                    </tbody>
                  </table>
    
                  <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-2">
                    <div className="dataTables_info">
                      Showing {activePag.current * sort + 1} to{" "}
                      {productsList.length > (activePag.current + 1) * sort
                        ? (activePag.current + 1) * sort
                        : productsList.length}{" "}
                      of {productsList.length} entries
                    </div>
                    <div
                      className="dataTables_paginate paging_simple_numbers mb-0"
                      id="example5_paginate"
                    >
                      <Link
                        className="paginate_button previous disabled"
                      //   to="/table-datatable-basic"
                        to="#"
                        onClick={() =>
                          activePag.current > 0 && onClick(activePag.current - 1)
                        }
                      >
                        <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                      </Link>
                      <span>
                        {paggination.map((number, i) => (
                          <Link
                            key={i}
                          //   to="/table-datatable-basic"
                            to="#"
                            className={`paginate_button  ${
                              activePag.current === i ? "current" : ""
                            } `}
                            onClick={() => onClick(i)}
                          >
                            {number}
                          </Link>
                        ))}
                      </span>
                      <Link
                        className="paginate_button next"
                      //   to="/table-datatable-basic"
                        to="#"
                        onClick={() =>
                          activePag.current + 1 < paggination.length &&
                          onClick(activePag.current + 1)
                        }
                      >
                        <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Modal show={showFilterOptions} onHide={hideFilterOptions}>
          <Modal.Header closeButton>
            <Modal.Title>Filter Products</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="form-group mb-3 col-sm-12 col-md-4">
                <input
                  type="checkbox"
                  id="customCheckBox5"
                  className="form-check-input scale-110 cursor-pointer ml-2"
                  checked = {parseInt(recommendedStatusFilter) === 1 ? true : false}
                  onChange={(e) => {
                      console.log(e.target.checked)
                      e.target.checked ? setRecommendedStatusFilter(1) : setRecommendedStatusFilter("")
                  }}/>
                <label htmlFor="customCheckBox5" className="ml-2 translate-y-[3px]">Recomended</label>
              </div>
              <div className="form-group mb-3 col-sm-12 col-md-4">
                <input
                  type="checkbox"
                  id="customCheckBox3"
                  className="form-check-input scale-110 cursor-pointer ml-2"
                  checked = {parseInt(activeFilter) === 1 ? true : false}
                  onChange={(e) => {
                      console.log(e.target.checked)
                      e.target.checked ? setActiveFilter(1) : setActiveFilter("")
                  }}/>
                <label htmlFor="customCheckBox3" className="ml-2 translate-y-[3px]">Active</label>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={hideFilterOptions}>
              Close
            </Button>
            <Button variant="primary" onClick={() => {
              filterFunc();
              hideFilterOptions();
              }}>
              {
                getProductsLoading
                ?
                "loading.."
                :
                "Filter"
              }
            </Button>
          </Modal.Footer>
        </Modal> */}
        <LoadingScreen loading={deleteProductLoading} />
        </>
    )
}