import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { getLanguageSettingsFunc } from "../../../../../store/custom-actions/language/GetLanguageSettingsSlicer";
import { ThemeContext } from "../../../../../context/ThemeContext";
export default function ToggelerLang() {
  const {loading:switchLangLoading , data:languageData} = useSelector(
    state => state.getLanguageSettingsSlicer
  )
	const [languagesList , setLanguagesList] = useState([])
	useEffect(() => {
		languageData && languageData.languages && setLanguagesList(languageData.languages);
	},[languageData])
  const languageAPi = `https://apis-books.topgrowth-eg.com/api/language-settings`;
  const dispatch = useDispatch();
  const [langSlug , setlangSlug] = useState("")
  useEffect(() => {
    if(languageData && languageData.defaultLang) {
      const dfLangSlug = languageData.defaultLang.slug
      setlangSlug(dfLangSlug)
    }
  },[languageData])
  const handleGetLang = (lang) => {
    if(lang.slug) {
      if(lang.slug !== langSlug) {
        dispatch(getLanguageSettingsFunc(`${languageAPi}?lang=${lang.slug}`));
      }
    }
  }
  const { direction, changeDirectionLayout } = useContext(ThemeContext);

  useEffect(() => {
    if (languageData?.defaultLang?.slug) {
      let newDirection;
      if (languageData.defaultLang.slug === "en") {
        newDirection = 'ltr';
      } else {
        newDirection = 'rtl';
      }
      changeDirectionLayout({ value: newDirection, label: newDirection.toUpperCase() });
    }
  }, [languageData]);
  return (
    <DropdownButton id="dropdown-basic-button" className="switch-lang-button bg-primary after:hidden rounded-lg after:text-white mb-[20px] after:translate-y-1" title={
      <div className="relative flex items-center group">
        {/* <p className="relative transition-all duration-300 translate-x-[-10px] opacity-0 group-hover:translate-x-0 group-hover:opacity-100">{langSlug}</p> */}
        <i className="fa-solid fa-earth-americas text-white"></i>
      </div>
      }>
      {
        switchLangLoading
        ?
        "جاري التحميل..."
        :
        (
            languagesList && languagesList.length >= 1
            ?
            languagesList.map((lang , index) => (
            <Dropdown.Item href="#"
            key={index}
            onClick={() => handleGetLang(lang)}
            >{lang?.name}</Dropdown.Item>
            ))
            :
            <li><Link className="dropdown-item" to="#" style={{pointerEvents : "none"}}>No Languages</Link></li>  
        )
      }
    </DropdownButton>
  );
}