import { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import SpinnerLoading from "../../components/spinner-loading/SpinnerLoading";
import PageTitle from "../../layouts/PageTitle";
import { ContextApi } from "../../../store/ContextApi";
import { toast } from "react-toastify";
import { getSmtpSettingsFunc } from "../../../store/custom-actions/settings-actions/smtp/GetSmtpSettingsSlicer";
import { postSmtpSettingsFunc } from "../../../store/custom-actions/settings-actions/smtp/PostSmtpSettingsSlicer";
import { Link, useNavigate } from "react-router-dom";
function SmtpSettingsForm() {
    const contextApi = useContext(ContextApi);
    const settings_URL_APi = `${contextApi.dashboard_URL_API}/general-settings/smtp-settings`;
    const {loading:getSettingsLoading , data} = useSelector(
        state => state.getSmtpSettingsSlicer
    );
    const {loading:postSettingsLoading} = useSelector(
        state => state.postSmtpSettingsSlicer
    );
    const dispatch = useDispatch();
    const {register , setValue , formState : {errors} , handleSubmit} = useForm();
    const navigate = useNavigate();
    const getBasicSettings = async () => {
        dispatch(getSmtpSettingsFunc(settings_URL_APi))
    }
    useEffect(() => {
        getBasicSettings()
    },[])
    useEffect(() => {
        if(data) {
            setValue("site_smtp_mail_host" , data.site_smtp_mail_host);
            setValue("site_smtp_mail_port" , data.site_smtp_mail_port);
            setValue("site_smtp_mail_username" , data.site_smtp_mail_username);
            setValue("site_smtp_mail_encryption" , data.site_smtp_mail_encryption);
        }
    },[data])
    const handleSubmitSettings = async (data) => {
        if(data) {
            const formData = new FormData();
            formData.append("site_smtp_mail_host" , data.site_smtp_mail_host)
            formData.append("site_smtp_mail_port" , data.site_smtp_mail_port)
            formData.append("site_smtp_mail_username" , data.site_smtp_mail_username)
            formData.append("site_smtp_mail_encryption" , data.site_smtp_mail_encryption)
            formData.append("site_smtp_mail_password" , data.site_smtp_mail_password)
            dispatch(postSmtpSettingsFunc({
                api : settings_URL_APi , 
                data : formData
            }))
            .then(result => {
                if(result.payload.success === true) {
                    getBasicSettings();
                    navigate('/test-smtp')
                    toast.success(`${result.payload.message} !`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    })
                }
            })
        }
    }
    return (
        <>
            <PageTitle motherMenu={"Settings"} activeMenu={"SMTP Settings"} customClasses={"mb-0"}/>
            <div className="relative flex items-center justify-between my-3">
                <div className="relative flex items-center">
                    <button 
                        type="submit"
                        form="new-settings-form"
                        className={`flex items-center bg-primary text-white gap-1 px-4 py-2 cursor-pointer  font-semibold tracking-widest rounded-md hover:bg-blue-400 duration-300 ml-1 hover:gap-2 hover:translate-x-3
                        ${postSettingsLoading ? "pointer-events-none opacity-80" : ""}
                        `}
                        >
                            {
                                postSettingsLoading
                                ?
                                "Please Wait.."
                                :
                                <>
                                    Submit
                                    <i className="fa-solid fa-gear text-white ml-1"></i>
                                </>
                            }
                    </button>
                    <Link
                        to="/test-smtp"
                        className={`flex items-center bg-primary text-white gap-1 px-4 py-2 cursor-pointer  font-semibold tracking-widest rounded-md hover:bg-blue-400 duration-300 ml-1 hover:gap-2 hover:translate-x-3
                        ${postSettingsLoading ? "pointer-events-none opacity-80" : ""}
                        `}>Test Settings</Link>
                </div>
            </div>
            
            {
                getSettingsLoading
                ?
                <SpinnerLoading/>
                :
                <div className="row">
                    <div className="col-xl-12 col-xxl-12">
                        <div className="card p-3">
                            <div className="relative settings-form-content">
                                <div className="new-settings-form-div relative w-full ">
                                    <form 
                                        className="new-settings-form"
                                        id="new-settings-form"
                                        onSubmit={handleSubmit((data) => {
                                            handleSubmitSettings(data)
                                        })}
                                    >
                                        {
                                            <>
                                                <div className={'row'}>
                                                    <div className="form-group mb-3 col-sm-12 col-md-6">
                                                        <label className="flex items-center">
                                                            <i className="fa-solid fa-arrow-up-9-1 mr-1"></i>
                                                            SMPT mail host
                                                        </label>
                                                        {errors.site_smtp_mail_host?.message && (
                                                        <p className="text-red-600">
                                                            {errors.site_smtp_mail_host?.message}
                                                        </p>
                                                        )}
                                                        <input
                                                        {...register("site_smtp_mail_host")}
                                                        type="text"
                                                        className="form-control"
                                                        />
                                                    </div>
                                                    <div className="form-group mb-3 col-sm-12 col-md-6">
                                                        <label className="flex items-center">
                                                            <i className="fa-solid fa-arrow-up-9-1 mr-1"></i>
                                                            Port
                                                        </label>
                                                        {errors.site_smtp_mail_port?.message && (
                                                        <p className="text-red-600">
                                                            {errors.site_smtp_mail_port?.message}
                                                        </p>
                                                        )}
                                                        <input
                                                        {...register("site_smtp_mail_port")}
                                                        type="text"
                                                        className="form-control"
                                                        />
                                                    </div>
                                                </div>
                                                <div className={'row'}>
                                                    <div className="form-group mb-3 col-sm-12 col-md-6">
                                                        <label className="flex items-center">
                                                            <i className="fa-solid fa-arrow-up-9-1 mr-1"></i>
                                                            SMPT mail username
                                                        </label>
                                                        {errors.site_smtp_mail_username?.message && (
                                                        <p className="text-red-600">
                                                            {errors.site_smtp_mail_username?.message}
                                                        </p>
                                                        )}
                                                        <input
                                                        {...register("site_smtp_mail_username")}
                                                        type="text"
                                                        className="form-control"
                                                        />
                                                    </div>
                                                    <div className="form-group mb-3 col-sm-12 col-md-6">
                                                        <label className="flex items-center">
                                                            <i className="fa-solid fa-arrow-up-9-1 mr-1"></i>
                                                            SMPT mail encryption
                                                        </label>
                                                        {errors.site_smtp_mail_encryption?.message && (
                                                        <p className="text-red-600">
                                                            {errors.site_smtp_mail_encryption?.message}
                                                        </p>
                                                        )}
                                                        <input
                                                        {...register("site_smtp_mail_encryption")}
                                                        type="text"
                                                        className="form-control"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group mb-3 col-sm-12">
                                                        <label className="flex items-center">
                                                            <i className="fa-solid fa-arrow-up-9-1 mr-1"></i>
                                                            SMPT mail Password
                                                        </label>
                                                        {errors.site_smtp_mail_password?.message && (
                                                        <p className="text-red-600">
                                                            {errors.site_smtp_mail_password?.message}
                                                        </p>
                                                        )}
                                                        <input
                                                        {...register("site_smtp_mail_password")}
                                                        type="password"
                                                        className="form-control"
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}


export default SmtpSettingsForm;