import React, { Fragment, useContext, useEffect, useState } from "react";
// images
import qrcode from "../../../../images/qr.png";
//import logo from "../../../../../images/logo.png";
import logoText from "../../../../images/logo-text.png";
import PageTitle from "../../../layouts/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import SpinnerLoading from "../../../components/spinner-loading/SpinnerLoading";
import EmptyData from "../../../components/empty-data/EmptyData";
import { getSingleOrderFunc } from "../../../../store/custom-actions/orders-actions/GetSingleOrderSlicer";
import { Link, useParams } from "react-router-dom";
import { ContextApi } from "../../../../store/ContextApi";
import { getGeneralSettingsFunc } from "../../../../store/custom-actions/settings-actions/general-settings/GetGeneralSettingsSlicer";

const InvoicePage = () => {
    const {orderId} = useParams();
    const {loading , data:orderData} = useSelector(
        state => state.getSingleOrderSlicer
    );
    const {loading:getSettingsData , data:settingsData} = useSelector(
        state => state.getGeneralSettingsSlicer
    );
    const contextApi = useContext(ContextApi);
    const site_global_currency = contextApi.homeSettings.site_global_currency;
    const order_API_URL = `${contextApi.dashboard_URL_API}/orders/${orderId}`;
    const generalSettingsApi = `${contextApi.dashboard_URL_API}/general-settings`;
    const siteLogo = contextApi.homeSettings.site_logo;
    const siteTitle = contextApi.homeSettings.site_title;
    const dispatch = useDispatch();
    const [order , setOrder] = useState({})
    const [year , stYear] = useState("");
    const [month , setMonth] = useState("");
    const [day , setDay] = useState("");
    const [orderProducts , setOrderProducts] = useState([]);
    const [orderOwn , setorderOwn] = useState({});
    const [totalRefudProductsPrice , setTotalRefudProductsPrice] = useState();
    useEffect(() => {
        const getSingleOrder = async () => {
          try {
            dispatch(getSingleOrderFunc(order_API_URL))
          } catch (error) {
            console.log(error);
          }
        };
        getSingleOrder();
    }, []);
    useEffect(() => {
        if(!settingsData) {
            dispatch(getGeneralSettingsFunc(generalSettingsApi))
        }
    },[settingsData])
    useEffect(() => {
        if(orderData) {
            setOrder(orderData)
            const date = new Date(orderData.created_at)
            const year = date.getFullYear();
            const month = date.getMonth()+1;
            const day = date.getDate();
            stYear(year);
            setMonth(month);
            setDay(day);
            setOrderProducts(orderData.products);
            setorderOwn(orderData.user) 
        }
    },[orderData])
    const switchOrderStatus = (status) => {
        switch(status) {
          case 0:
            return (
                "Pending"
            );
          case 1:
            return (
                "Active"
            );
          case 2:
            return (
                "Delivery"
            );
          case 3:
            return (
                "completed"
            );
          default :
            return (
                "Pending"
            );
            
        }
      }
      
      useEffect(() => {
        const allRefundPrice = orderProducts
        .map(product => product.pivot.refund_qyt * product.pivot.price)
        .reduce((acc, current) => acc + current, 0);
  
        setTotalRefudProductsPrice(allRefundPrice);
      },[orderProducts])
  return (
    <Fragment>
      <PageTitle activeMenu="Invoice" motherMenu="Orders" />
      <Link
        to={"#"} 
        onClick={() => {
            window.print()
        }}
        className="btn btn-primary btn-sm mt-2 mb-2"
        >
            Print Invoice
            <i className="fa-solid fa-print ml-1"></i>
        </Link>
      {
        loading
        ?
        <SpinnerLoading/>
        :
        (
            orderData && Object.keys(order).length >= 1
            ?
            <div className="row">
                <div className="col-lg-12">
                <div className="card">
                    <div className="card-header">
                    {" "}
                    Invoice <strong>{year} / {month} / {day}</strong>{" "}
                    <span className="float-right">
                        <strong>Status:</strong> {switchOrderStatus(order.status)}
                    </span>{" "}
                    </div>
                    <div className="card-body">
                    <div className="row mb-5">
                        <div className="mt-4 col-xl-3 col-lg-6 col-md-6 col-sm-6">
                        <h6>From:</h6>
                        <div>
                            {" "}
                            <strong>{siteTitle}</strong>{" "}
                        </div>
                        <div>Madalinskio 8</div>
                        <div>71-101 Szczecin, Poland</div>
                        <div>Email: info@webz.com.pl</div>
                        <div>Phone: +48 444 666 3333</div>
                        </div>
                        <div className="mt-4 col-xl-3 col-lg-6 col-md-6 col-sm-6">
                        <h6>To:</h6>
                        <div>
                            {" "}
                            <strong>{orderOwn.first_name} {orderOwn.last_name}</strong>{" "}
                        </div>
                        <div>Attn: {orderOwn.first_name} {orderOwn.last_name}</div>
                        <div>43-190 Mikolow, Poland</div>
                        <div>Email: {orderOwn.email}</div>
                        {/* <div>Phone: {orderOwn.phone}</div> */}
                        </div>
                        <div className="mt-4 col-xl-6 col-lg-12 col-md-12 col-sm-12 d-flex justify-content-lg-end justify-content-md-center justify-content-xs-start">
                        <div className="row align-items-center">
                            <div className="col-sm-9">
                            <div className="brand-logo mb-3">
                                    {/* <div className="d-flex align-items-center">
                                        <img className="logo-abbr me-3" src={logo} alt="" style={{width:'50px'}}  />
                                        <h3 className="mb-1 font-w600">Travl</h3>
                                    </div> */}
                                {/* <img className="logo-compact" src={logoText} alt="" style={{width:'170px'}} />  */}
                                {/* <img src={siteLogo} alt="site logo" className="relative logo-compact" style={{width:'120px'}} /> */}
                                <img src={settingsData?.invoice_logo} alt="invoice logo" className="relative logo-compact" style={{width:'120px'}} />
                            </div>
                            <span>
                                Please send exact amount:{" "}
                                <strong className="d-block">0.15050000 BTC</strong>
                                <strong>1DonateWffyhwAjskoEwXt83pHZxhLTr8H</strong>
                            </span>
                            <br />
                            <small className="text-muted">
                                Current exchange rate 1BTC = $6590 USD
                            </small>
                            </div>
                            <div className="col-sm-3 mt-3">
                            {" "}
                            <img
                                src={qrcode}
                                className="img-fluid width110"
                                alt=""
                            />{" "}
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="table table-striped">
                        <thead>
                            <tr>
                            <th className="center">#</th>
                            <th>Item</th>
                            <th>Quantity</th>
                            <th className="right">Unit Cost</th>
                            <th className="center">Refund</th>
                            <th className="center">SKU</th>
                            <th className="right">Total</th>
                            <th className="right">Refund Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                orderProducts
                                &&
                                orderProducts.length >=1
                                &&
                                orderProducts.map((product , index) => {
                                    return (
                                        <tr key={index}>
                                            <td className="center">{product.id}</td>
                                            <td className="left strong">{product.title}</td>
                                            <td className="left">{product.pivot.qyt}</td>
                                            <td className="left">{product.pivot.price} {site_global_currency}</td>
                                            <td className="left">
                                                {
                                                    parseInt(product.pivot.refund_qyt) >= 1
                                                    ?
                                                    `-${product.pivot.refund_qyt}`
                                                    :
                                                    "No Refund"
                                                }
                                            </td>
                                            <td className="left">{product.sku}</td>
                                            <td className="left">{parseInt(product.pivot.qyt) * parseInt(product.pivot.price)} {site_global_currency}</td>
                                            <td className="left">
                                                {
                                                    parseInt(product.pivot.refund_qyt) >= 1
                                                    ?
                                                    parseInt(product.pivot.refund_qyt) * parseInt(product.pivot.price)
                                                    :
                                                    0
                                                }
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                        </table>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-sm-5"> </div>
                        <div className="col-lg-4 col-sm-5 ms-auto">
                        <table className="table table-clear">
                            <tbody>
                            <tr>
                                <td className="left">
                                <strong>Subtotal</strong>
                                </td>
                                <td className="right">
                                    {parseInt(order.payable_amount) + parseInt(order.discount)} {site_global_currency}
                                </td>
                            </tr>
                            <tr>
                                <td className="left">
                                <strong>Discount</strong>
                                </td>
                                <td className="right">({order.discount} {site_global_currency})</td>
                            </tr>
                            <tr>
                                <td className="left">
                                {/* <strong>VAT (10%)</strong> */}
                                <strong>TAX</strong>
                                </td>
                                <td className="right">{parseInt(order.tax_fee_amount).toFixed(2)} {site_global_currency}</td>
                            </tr>
                            <tr>
                                <td className="left">
                                <strong>Total</strong>
                                </td>
                                <td className="right">
                                <strong>{parseInt(order.total_price).toFixed(2)} {site_global_currency}</strong>
                                <br />
                                </td>
                            </tr>
                            <tr>
                                <td className="left">
                                <strong>Total Refund Price</strong>
                                </td>
                                <td className="right">
                                    <strong>
                                        {
                                        parseInt(totalRefudProductsPrice) >= 1
                                        ?
                                        `-${parseInt(totalRefudProductsPrice).toFixed(2)} ${site_global_currency}`
                                        :
                                        totalRefudProductsPrice
                                        }
                                    </strong>
                                <br />
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            :
            <EmptyData/>
        )
      }
    </Fragment>
  );
};

export default InvoicePage;
