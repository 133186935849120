import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ContextApi } from "../../../store/ContextApi";
import PageTitle from "../../layouts/PageTitle";
import SpinnerLoading from "../../components/spinner-loading/SpinnerLoading";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import LoadingScreen from "../../components/loading-screen/LoadingScreen";
import { toast } from "react-toastify";
import { deleteCouponFunc } from "../../../store/custom-actions/coupons-actions/DeleteCouponSlicer";
import { getOrdersFunc } from "../../../store/custom-actions/orders-actions/GetOrdersSlicer";
import { Button, Modal } from "react-bootstrap";
import EmptyData from "../../components/empty-data/EmptyData";
import OrderDescion from "./OrderDescion";
import checkPermissions from "../../global-functions/checkPermissionsFunc";

export default function OrdersPage() {
    const {loading:getOrdersLoading , data:ordersData} = useSelector(state => state.getOrdersSlicer)
    const {loading:deleteOrderLoading} = useSelector(state => state.deleteOrderSlicer)
    const contextApi = useContext(ContextApi);
    const orders_API_URL = `${contextApi.dashboard_URL_API}/orders`;
    const site_global_currency = contextApi.homeSettings.site_global_currency;
    
    const [ordersList , setOrdersList] = useState([]);
    const [searchValue , setSearchValue] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const activePag = useRef(0);
    const sort = 20;
    let paggination = Array(Math.ceil(ordersList.length / sort))
      .fill()
      .map((_, i) => i + 1);
    const [jobData , setJobData] = useState(
      ordersList.slice(
        activePag.current * sort,
        (activePag.current + 1) * sort
      )
    )
    useEffect(() => {
        const getOrders = async () => {
          try {
            dispatch(getOrdersFunc(orders_API_URL))
          } catch (error) {
            console.log(error);
          }
        };
        getOrders();
      }, []);
      useEffect(() => {
        ordersData && setOrdersList(ordersData);
        // ordersData && (jobData.current = ordersData.slice(
        //     activePag.current * sort,
        //     (activePag.current + 1) * sort
        //   ));
          ordersData && setJobData(ordersData.slice(
            activePag.current * sort,
            (activePag.current + 1) * sort
          ))
      }, [ordersData]);
    
      const onClick = (i) => {
        activePag.current = i;
        setJobData(ordersList.slice(
          activePag.current * sort,
          (activePag.current + 1) * sort
        ))
      };
      const search = () => {
        if(searchValue !== "") {
          const searchResult = ordersData &&
          ordersData.length >= 1 && ordersData.filter((order) =>  order.invoice_no.includes(searchValue));
          setJobData(searchResult.slice(
            activePag.current * sort,
            (activePag.current + 1) * sort
          ))
        }else {
          ordersData && setJobData(ordersData.slice(
            activePag.current * sort,
            (activePag.current + 1) * sort
          ))
        }
      }
      const [paymentStatusFilter , setPaymentStatusFilter] = useState("")
      const [orderStatusFilter , setOrderStatusFilter] = useState("")
      const [refundStatusFilter , setRefundStatusFilter] = useState("")
      const [paymentGatewayFilter , setPaymentGatewayFilter] = useState("")
      const [filterStartDate , setFilterStartDate] = useState("")
      const [filterEndDate , setFilterEndDate] = useState("")
      const filterFunc = () => {
        const filterApi = `${orders_API_URL}?${paymentStatusFilter !== "" ? `payment_status=${paymentStatusFilter}` : ""}&${orderStatusFilter !== "" ? `status=${orderStatusFilter}` : ""}&${refundStatusFilter !== "" ? `refund_status=${refundStatusFilter}` : ""}&${paymentGatewayFilter !== "" ? `payment_gateway=${paymentGatewayFilter}` : ""}&${filterStartDate !== ""  ? `created_at=${filterStartDate}` : ""}&${filterEndDate !== "" ? `end_date=${filterEndDate}` : ""}`;
        dispatch(getOrdersFunc(filterApi))
        .then(result => {
          console.log(result)
          // if(result.payload.success === true) {
            const filterObject = {
              status : orderStatusFilter,
              payment_status : paymentStatusFilter,
              payment_gateway : paymentGatewayFilter,
              refund_status : refundStatusFilter,
              start_date : filterStartDate,
              end_date : filterEndDate,
            }
            window.localStorage.setItem("filterObjct" , JSON.stringify(filterObject));
            const filterObj = JSON.parse(window.localStorage.getItem("filterObjct"));
            console.log(filterObj)
          // }
        })
      }
      useEffect(() => {
        if(window.localStorage.getItem("filterObjct")) {
          const filterObj = JSON.parse(window.localStorage.getItem("filterObjct"));
          setPaymentStatusFilter(filterObj.payment_status)
          setOrderStatusFilter(filterObj.status)
          setRefundStatusFilter(filterObj.refund_status)
          setPaymentGatewayFilter(filterObj.payment_gateway)
          setFilterStartDate(filterObj.start_date)
          setFilterEndDate(filterObj.end_date)
        }else {
          setPaymentStatusFilter("")
          setOrderStatusFilter("")
          setRefundStatusFilter("")
          setPaymentGatewayFilter("")
          setFilterStartDate("")
          setFilterEndDate("")
        }
        return () => {
          window.localStorage.removeItem("filterObjct")
        }
      },[])
      useEffect(() => {
        if(searchValue === ""){
          // jobData.current = ordersData;
          ordersData && setJobData(ordersData.slice(
            activePag.current * sort,
            (activePag.current + 1) * sort
          ))
        }
      },[searchValue , ordersData])

      const deleteOrderFunction = async (id) => {
        swal({
          title: "Confirm Order Deletion",
          text: `Once deleted, This Order will not be avilable!`,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            dispatch(deleteCouponFunc(`${orders_API_URL}/${id}`))
            .then((result) => {
              if(result.payload.success === true){
                dispatch(getOrdersFunc(orders_API_URL));
                toast.success(`${result.payload.message} !`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
              })
              }
            })
          } else {
            swal("Order is safe!");
          }
        });
      };
      
      const switchOrderStatus = (status) => {
        switch(status) {
          case 0:
            return (
              <span className="relative text-sm rounded-lg text-white bg-orange-500 p-1">
                Pending</span>
            );
          case 1:
            return (
              <span className="relative text-sm rounded-lg text-white bg-[#8bc34a] p-1">
                Active</span>
            );
          case 2:
            return (
              <span className="relative text-sm rounded-lg text-white bg-orange-500 p-1">
                Delivery</span>
            );
          case 3:
            return (
              <span className="relative text-sm rounded-lg text-white bg-green-500 p-1">
                completed</span>
            );
          default :
            return (
              <span className="relative text-sm rounded-lg text-white bg-orange-500 p-1">
                Pending</span>
            );
            
        }
      }
      const switchPaymentStatus = (status) => {
        switch(status) {
          case "complete":
            return (
              <span className="relative text-sm rounded-lg text-white bg-green-500 p-1">
                completed</span>
            );
          case "pending":
            return (
              <span className="relative text-sm rounded-lg text-white bg-orange-500 p-1">
                Pending</span>
            );
          case "failed":
            return (
              <span className="relative text-sm rounded-lg text-white bg-red-500 p-1">
                Failed</span>
            );
          default :
            return (
              <span className="relative text-sm rounded-lg text-white bg-orange-500 p-1">
                Pending</span>
            );
            
        }
      }
      
      const [showFilterOptions , setShowFilterOptions] = useState();
      const hideFilterOptions = () => setShowFilterOptions(false)
    return (
        <>
        <PageTitle motherMenu={"Dashboard"} activeMenu={"Orders"} />
        {
            (
               
                (
                    <div className="col-12">
                    <Modal show={showFilterOptions} onHide={hideFilterOptions}>
                      <Modal.Header closeButton>
                        <Modal.Title>Filter Orders</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="row">
                          <div className="form-group mb-3 col-sm-12 col-md-6">
                              <label>Payment Gateway</label>
                              <select
                                className="form-control mr-1"
                                onChange={(e) => setPaymentGatewayFilter(e.target.value)}
                                value={paymentGatewayFilter}
                              >
                                <option value={""}>All</option>
                                <option value={"cash_on_delivery"}>Cash On Delivery</option>
                                <option value={"send_receipt"}>Send Receipt</option>
                                <option value={"paymob_card_gateway"}>Paymob Card Gateway</option>
                                <option value={"paymob_wallet_gateway"}>Paymob Wallet Gateway</option>
                              </select>
                          </div>
                          <div className="form-group mb-3 col-sm-12 col-md-6">
                              <label>Refund Status</label>
                                <select
                                  className="form-control mr-1"
                                  onChange={(e) => setRefundStatusFilter(e.target.value)}
                                  value={refundStatusFilter}
                                >
                                  <option value={""}>All</option>
                                  <option value={0}>Pending</option>
                                  <option value={1}>Paid</option>
                                </select>
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group mb-3 col-sm-12 col-md-6">
                              <label>Order Status</label>
                              <select
                                className="form-control mr-1"
                                onChange={(e) => {
                                  setOrderStatusFilter(e.target.value)
                                }}
                                value={orderStatusFilter}
                              >
                                <option value={""}>All</option>
                                <option value={0}>Pending</option>
                                <option value={1}>Active</option>
                                <option value={2}>Delivery</option>
                                <option value={3}>Complete</option>
                              </select>
                          </div>
                          <div className="form-group mb-3 col-sm-12 col-md-6">
                              <label>Paymnet Status</label>
                              <select
                                className="form-control mr-1"
                                onChange={(e) => {
                                  setPaymentStatusFilter(e.target.value)
                                }}
                                value={paymentStatusFilter}
                              >
                                <option value={""}>All</option>
                                <option value={"pending"}>Pending</option>
                                <option value={"complete"}>Complete</option>
                                <option value={"failed"}>Faild</option>
                              </select>
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group mb-3 col-sm-12 col-md-6">
                              <label>Start Date</label>
                              <input
                                className="form-control col-sm-12"
                                type="date"
                                onChange={(e) => setFilterStartDate(e.target.value)}
                                value={filterStartDate}
                              />
                          </div>
                          <div className="form-group mb-3 col-sm-12 col-md-6">
                              <label>End Date</label>
                              <input
                                className="form-control col-sm-12"
                                type="date"
                                onChange={(e) => setFilterEndDate(e.target.value)}
                                value={filterEndDate}
                              />
                          </div>
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={hideFilterOptions}>
                          Close
                        </Button>
                        <Button variant="primary" onClick={() => {
                          filterFunc();
                          hideFilterOptions();
                          }}>
                          {
                            getOrdersLoading
                            ?
                            "loading.."
                            :
                            "Filter"
                          }
                        </Button>
                      </Modal.Footer>
                    </Modal>
                    <div className="card">
                      <div className="card-header flex justify-between items-center">
                        <h4 className="card-title">Orders</h4>
                        <div className="relative add-and-search flex items-center">
                          <div className="input-group search-area w-auto mr-2">
                              <span className="input-group-text"
                                onClick={() => search()}
                              >
                                  <Link to={"#"}>
                                  <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 32 32"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                  >
                                      <path
                                      d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                                      fill="var(--primary)"
                                      ></path>
                                  </svg>
                                  </Link>
                              </span>
                              <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search With Invoice..."
                                  onChange={(e) => {
                                  setSearchValue(e.target.value);
                                }}
                              />
                          </div>
                            <Link 
                            to={"#"} 
                            className="btn btn-primary btn-sm"
                            onClick={() => setShowFilterOptions(true)}
                            >
                              Filter
                              <i className="fa-solid fa-filter ml-1"></i>
                            </Link>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="w-100 table-responsive">
                          <div id="example_wrapper" className="dataTables_wrapper">
                            <table id="example" className="display w-100 dataTable">
                              <thead>
                                <tr role="row">
                                  <th>#Invoice ID</th>
                                  <th>User</th>
                                  <th>Status</th>
                                  <th>Refund Status</th>
                                  <th>Payment Status</th>
                                  <th>Total</th>
                                  <th>Payment Getway</th>
                                  {
                                    checkPermissions(
                                      "update orders",
                                      <th>Actions</th>
                                    )
                                  }
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  getOrdersLoading
                                  ?
                                  (
                                    <div className="my-4">
                                      <SpinnerLoading/>
                                    </div>
                                  )
                                  :
                                  (
                                    ordersData && ordersList.length >= 1
                                    ?
                                    ordersData && jobData.map((order , index) => {
                                        return (
                                            <tr key={index}>
                                                <td>
                                                  <Link to={`/invoice-page/${order.id}`}>
                                                    #{order.invoice_no}
                                                  </Link>
                                                </td>
                                                <td>{order.user.first_name} {order.user.last_name}</td>
                                                <td>
                                                  {switchOrderStatus(order.status)}
                                                </td>
                                                <td>
                                                  <span className={`relative text-sm rounded-lg text-white ${order.refund_status === 0 ? "bg-green-500" : "bg-red-500"} p-1`}>
                                                      {
                                                          order.refund_status === 0
                                                          ?
                                                          "No Refund"
                                                          :
                                                          "Refund"
                                                      }
                                                  </span>
                                                </td>
                                                <td>
                                                  {switchPaymentStatus(order.payment_status)}
                                                </td>
                                                <td>{order.total_price} {site_global_currency}</td>
                                                <td>{order.payment_gateway.replace(/_/g , " ")}</td>
                                                {
                                                  checkPermissions(
                                                    "update orders",
                                                    <td>
                                                        <OrderDescion
                                                        deleteFunction={() => deleteOrderFunction(order.id)}
                                                        showUpdateStatusForm={() => navigate(`/update-order-status/${order.id}`)}
                                                        showUpdatePaymnetStatusForm={() => navigate(`/update-order-payment-status/${order.id}`)}
                                                        showUpdateRefundForm={() => navigate(`/update-order-redfund/${order.id}`)}
                                                        paymentStatus = {order.payment_status}
                                                        
                                                        />
                                                    </td>
                                                  )
                                                }
                                            </tr>
                                        )
                                    }) 
                                    :
                                    <td colSpan={4}>
                                      {/* <EmptyData/> */}
                                      No data avilable
                                    </td>
                                  )
                                }
                              </tbody>
                            </table>
                            {
                              ordersData
                              &&
                              <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-2">
                                <div className="dataTables_info">
                                  Showing {activePag.current * sort + 1} to{" "}
                                  {ordersList.length > (activePag.current + 1) * sort
                                    ? (activePag.current + 1) * sort
                                    : ordersList.length}{" "}
                                  of {ordersList.length} entries
                                </div>
                                <div
                                  className="dataTables_paginate paging_simple_numbers mb-0"
                                  id="example5_paginate"
                                >
                                  <Link
                                    className="paginate_button previous disabled"
                                  //   to="/table-datatable-basic"
                                    to="#"
                                    onClick={() =>
                                      activePag.current > 0 && onClick(activePag.current - 1)
                                    }
                                  >
                                    <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                                  </Link>
                                  <span>
                                    {paggination.map((number, i) => (
                                      <Link
                                        key={i}
                                      //   to="/table-datatable-basic"
                                        to="#"
                                        className={`paginate_button  ${
                                          activePag.current === i ? "current" : ""
                                        } `}
                                        onClick={() => onClick(i)}
                                      >
                                        {number}
                                      </Link>
                                    ))}
                                  </span>
                                  <Link
                                    className="paginate_button next"
                                  //   to="/table-datatable-basic"
                                    to="#"
                                    onClick={() =>
                                      activePag.current + 1 < paggination.length &&
                                      onClick(activePag.current + 1)
                                    }
                                  >
                                    <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                                  </Link>
                                </div>
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    </div>
                )
            )
        }
        <LoadingScreen loading={deleteOrderLoading} />
        </>
    )
}