import { useContext , useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { postBasicSettingsFunc } from "../../../store/custom-actions/settings-actions/basic-settings/PostBasicSettingsSlicer";
import PageTitle from "../../layouts/PageTitle";
import { ContextApi } from "../../../store/ContextApi";
import ReactQuill from "react-quill";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { testSmtpSettingsFunc } from "../../../store/custom-actions/settings-actions/smtp/TestSmtpSettingsSlicer";

function TestSmtpSettingsForm() {
    const contextApi = useContext(ContextApi);
    const test_settings_URL_APi = `${contextApi.dashboard_URL_API}/general-settings/test-smtp-settings`;
    const {loading:testLoading} = useSelector(
        state => state.testSmtpSettingsSlicer
    );
    const dispatch = useDispatch();
    const [messageContent , setMessageContent] = useState("")
    const {register , formState : {errors} , handleSubmit , reset} = useForm();

    const handleSubmitTest = async (data) => {
        if(data) {
            const testFormData = new FormData();
            testFormData.append("email" , data.email)
            testFormData.append("subject" , data.subject)
            testFormData.append("message" , messageContent)
            dispatch(testSmtpSettingsFunc({
                api : test_settings_URL_APi , 
                data : testFormData
            }))
            .then(result => {
                if(result.payload.success === true) {
                    reset()
                    toast.success(`${result.payload.message} !`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    })
                }
            })
        }
    }
    const colors = [
        '#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff',
        '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff',
        '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff',
        '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2',
        '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466',
        '#000000', '#990000', '#b26b00', '#b2b200', '#005c00', '#003399', '#592966'
    ];
    const modules = {
        toolbar: {
        container: [
        [{"color" : colors}],
        [{ 'background': colors }]
        [{ 'header': [1, 2, 3, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        ['link' , "video"],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'align': [] }],
        ['clean']
        ],
    }
    };
    return (
        <>
            <PageTitle motherMenu={"Settings"} activeMenu={"Basic Settings"} customClasses={"mb-0"}/>
            <div className="relative flex items-center justify-between my-3">
                <div className="relative flex items-center">
                    <button 
                        type="submit"
                        form="new-settings-form"
                        className={`flex items-center bg-primary text-white gap-1 px-4 py-2 cursor-pointer  font-semibold tracking-widest rounded-md hover:bg-blue-400 duration-300 ml-1 hover:gap-2 hover:translate-x-3
                        ${testLoading ? "pointer-events-none opacity-80" : ""}
                        `}
                        >
                            {
                                testLoading
                                ?
                                "Please Wait.."
                                :
                                <>
                                    Send
                                    <i className="fa-regular fa-paper-plane text-white ml-1"></i>
                                </>
                            }
                    </button>
                </div>
            </div>
            <div className="relative settings-form-content">
                <div className="new-settings-form-div relative w-full ">
                    <form 
                        className="new-settings-form"
                        id="new-settings-form"
                        onSubmit={handleSubmit((data) => {
                            handleSubmitTest(data)
                        })}
                    >
                        {
                            <>
                                <div className={'row'}>
                                    <div className="form-group mb-3 col-sm-12 col-md-6">
                                        <label className="flex items-center">
                                            <i className="fa-solid fa-arrow-up-9-1 mr-1"></i>
                                            Email
                                        </label>
                                        {errors.email?.message && (
                                        <p className="text-red-600">
                                            {errors.email?.message}
                                        </p>
                                        )}
                                        <input
                                        {...register("email")}
                                        type="email"
                                        className="form-control"
                                        />
                                    </div>
                                    <div className="form-group mb-3 col-sm-12 col-md-6">
                                        <label className="flex items-center">
                                            <i className="fa-solid fa-arrow-up-9-1 mr-1"></i>
                                            Subject
                                        </label>
                                        {errors.subject?.message && (
                                        <p className="text-red-600">
                                            {errors.subject?.message}
                                        </p>
                                        )}
                                        <input
                                        {...register("subject")}
                                        type="text"
                                        className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="flex items-center">
                                        <i className="fa-solid fa-arrow-up-9-1 mr-1"></i>
                                        Message
                                    </label>
                                    <ReactQuill 
                                    modules = {modules}
                                    className="p-0 border-none outline-none form-control bg-white mt-3" 
                                    value={messageContent} 
                                    theme="snow" 
                                    onChange={(content) => setMessageContent(content)} />
                                </div>
                            </>
                        }
                    </form>
                </div>
            </div>
        </>
    )
}


export default TestSmtpSettingsForm;