import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import SpinnerLoading from "../../components/spinner-loading/SpinnerLoading";
import PageTitle from "../../layouts/PageTitle";
import { ContextApi } from "../../../store/ContextApi";
import { toast } from "react-toastify";
import { getSocialSettingsFunc } from "../../../store/custom-actions/settings-actions/social-settings/GetSocialSettingsSlicer";
import { postSocialSettingsFunc } from "../../../store/custom-actions/settings-actions/social-settings/PostSocialSettingsSlicer";
function SocialSettingsPage() {
    const contextApi = useContext(ContextApi);
    const settings_URL_APi = `${contextApi.dashboard_URL_API}/general-settings`;
    const {loading:getSettingsLoading , data} = useSelector(
        state => state.getSocialSettingsSlicer
    );
    const {loading:postSettingsLoading} = useSelector(
        state => state.postSocialSettingsSlicer
    );
    const dispatch = useDispatch();
    const {register , setValue , formState : {errors} , handleSubmit} = useForm();
    const [avilableShowing , setAvilableShowing] = useState()
    const getSettings = async () => {
        dispatch(getSocialSettingsFunc(settings_URL_APi))
    }
    useEffect(() => {
        getSettings()
    },[])
    useEffect(() => {
        if(data) {
            setValue("facebook_link" , data.facebook_link);
            setValue("youtube_link" , data.youtube_link);
            setValue("twitter_link" , data.twitter_link);
            setValue("instagram_link" , data.instagram_link);
            setValue("linkedin_link" , data.linkedin_link);
            data.show_social_links === "1" ? setAvilableShowing(true) : setAvilableShowing(false);
        }
    },[data])
    const handleSubmitSettings = async (data) => {
        if(data) {
            const formData = new FormData();
            formData.append("facebook_link" , data.facebook_link)
            formData.append("youtube_link" , data.youtube_link)
            formData.append("twitter_link" , data.twitter_link)
            formData.append("instagram_link" , data.instagram_link)
            formData.append("linkedin_link" , data.linkedin_link)
            formData.append("show_social_links" , avilableShowing)
            dispatch(postSocialSettingsFunc({
                api : settings_URL_APi , 
                data : formData
            }))
            .then(result => {
                if(result.payload.success === true) {
                    getSettings();
                    toast.success(`${result.payload.message} !`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    })
                }
            })
        }
    }
    return (
        <>
            <PageTitle motherMenu={"Settings"} activeMenu={"Social Settings"} customClasses={"mb-0"}/>
            <div className="relative flex items-center justify-between my-3">
                <div className="relative flex items-center">
                    <button 
                        type="submit"
                        form="new-settings-form"
                        className={`flex items-center bg-primary text-white gap-1 px-4 py-2 cursor-pointer  font-semibold tracking-widest rounded-md hover:bg-blue-400 duration-300 ml-1 hover:gap-2 hover:translate-x-3
                        ${postSettingsLoading ? "pointer-events-none opacity-80" : ""}
                        `}
                        >
                            {
                                postSettingsLoading
                                ?
                                "Please Wait.."
                                :
                                <>
                                    Submit
                                    <i className="fa-solid fa-gear text-white ml-1"></i>
                                </>
                            }
                    </button>
                </div>
            </div>
            
            {
                getSettingsLoading
                ?
                <SpinnerLoading/>
                :
                <div className="row">
                    <div className="col-xl-12 col-xxl-12">
                        <div className="card p-4">
                            <div className="relative settings-form-content">
                                <div className="new-settings-form-div relative w-full ">
                                    <form 
                                        className="new-settings-form"
                                        id="new-settings-form"
                                        onSubmit={handleSubmit((data) => {
                                            handleSubmitSettings(data)
                                        })}
                                    >
                                        {
                                            <>
                                                <div className={'row'}>
                                                    <div className="form-group mb-3 col-sm-12 col-md-6">
                                                        <label className="flex items-center">
                                                            <i className="fa-brands fa-facebook mr-1"></i>
                                                            Facebook URL
                                                        </label>
                                                        {errors.facebook_link?.message && (
                                                        <p className="text-red-600">
                                                            {errors.facebook_link?.message}
                                                        </p>
                                                        )}
                                                        <input
                                                        {...register("facebook_link")}
                                                        type="text"
                                                        className="form-control"
                                                        />
                                                    </div>
                                                    <div className="form-group mb-3 col-sm-12 col-md-6">
                                                        <label className="flex items-center">
                                                            <i className="fa-brands fa-youtube mr-1"></i>
                                                            Youtube URL
                                                        </label>
                                                        {errors.youtube_link?.message && (
                                                        <p className="text-red-600">
                                                            {errors.youtube_link?.message}
                                                        </p>
                                                        )}
                                                        <input
                                                        {...register("youtube_link")}
                                                        type="text"
                                                        className="form-control"
                                                        />
                                                    </div>
                                                </div>
                                                <div className={'row'}>
                                                    <div className="form-group mb-3 col-sm-12 col-md-6">
                                                        <label className="flex items-center">
                                                            <i className="fa-brands fa-twitter mr-1"></i>
                                                            Twitter URL
                                                        </label>
                                                        {errors.twitter_link?.message && (
                                                        <p className="text-red-600">
                                                            {errors.twitter_link?.message}
                                                        </p>
                                                        )}
                                                        <input
                                                        {...register("twitter_link")}
                                                        type="text"
                                                        className="form-control"
                                                        />
                                                    </div>
                                                    <div className="form-group mb-3 col-sm-12 col-md-6">
                                                        <label className="flex items-center">
                                                            <i className="fa-brands fa-instagram mr-1"></i>
                                                            Instagram URL
                                                        </label>
                                                        {errors.instagram_link?.message && (
                                                        <p className="text-red-600">
                                                            {errors.instagram_link?.message}
                                                        </p>
                                                        )}
                                                        <input
                                                        {...register("instagram_link")}
                                                        type="text"
                                                        className="form-control"
                                                        />
                                                    </div>
                                                </div>
                                                <div className={'row'}>
                                                    <div className="form-group mb-3 col-sm-12 col-md-6">
                                                        <label className="flex items-center">
                                                            <i className="fa-brands fa-linkedin mr-1"></i>
                                                            Linkedin URL
                                                        </label>
                                                        {errors.linkedin_link?.message && (
                                                        <p className="text-red-600">
                                                            {errors.linkedin_link?.message}
                                                        </p>
                                                        )}
                                                        <input
                                                        {...register("linkedin_link")}
                                                        type="text"
                                                        className="form-control"
                                                        />
                                                    </div>
                                                    <div className="form-group  col-sm-12 col-md-6 flex items-center">
                                                        <input
                                                        type="checkbox"
                                                        id="customCheckBox5"
                                                        className="form-check-input scale-110 cursor-pointer ml-2"
                                                        checked = {avilableShowing}
                                                        onChange={(e) => {
                                                            e.target.checked ? setAvilableShowing(true) : setAvilableShowing(false)
                                                        }}/>
                                                        <label htmlFor="customCheckBox5" className="flex items-center translate-y-1 cursor-pointer">
                                                            <i className="fa-brands fa-istagram mr-1"></i>
                                                            Avilable Showing
                                                        </label>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}


export default SocialSettingsPage;