import "./loading-screen.css";
function LoadingScreen({loading}) {
    return (
        <>
            <div className={`fixed loading-parent top-0 left-0 w-full h-[100vh] flex flex-col justify-center items-center transition-all bg-[#00000002] z-[999999999999]  duration-500 ${loading ? " active" : ""}`}>
                <div className="loader">
                    <div className="circle bg-primary"></div>
                    <div className="circle bg-primary"></div>
                    <div className="circle bg-primary"></div>
                    <div className="circle bg-primary"></div>
                </div>
                <p className="m-0 mt-3 text-[15px] flex relative items-center text-white">
                    Loading..
                </p>
            </div>
        </>
    )
}
export default LoadingScreen;