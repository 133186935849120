import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ContextApi } from "../../../store/ContextApi";
import PageTitle from "../../layouts/PageTitle";
import SpinnerLoading from "../../components/spinner-loading/SpinnerLoading";
import FaildLoadData from "../../global-components/FaildLoadData/FaildLoadData";
import { Link } from "react-router-dom";
import{getCountriesFunc} from "../../../store/custom-actions/countries-actions/GetContriesSlicer"
import swal from "sweetalert";
import { deleteCountryFunc } from "../../../store/custom-actions/countries-actions/DeleteCountrySlicer";
import LoadingScreen from "../../components/loading-screen/LoadingScreen";
import NewCountryForm from "../../custom-forms/countries/NewCountryForm";
import { toast } from "react-toastify";
import UpdateCountryForm from "../../custom-forms/countries/UpdateCountryForm";
import checkPermissions from "../../global-functions/checkPermissionsFunc";

export default function CountriesPage() {
    const {loading:getCountriesLoading , data:countriesData} = useSelector(state => state.getCountriesSlicer)
    const {loading:deleteCountryLoading} = useSelector(state => state.deleteCountrySlicer)
    const contextApi = useContext(ContextApi);
    const countries_API_URL = `${contextApi.dashboard_URL_API}/countries`;
    const [countriesList , setCountriesList] = useState([]);
    const [showNewCountryForm , setShowNewCountryForm] = useState();
    const [showUpdateForm , setShowUpdateForm] = useState();
    const [countryWichUpdate , setCountryWhichUpdate] = useState({});
    const [searchValue , setSearchValue] = useState("");
    const dispatch = useDispatch();

    useEffect(() => {
        const getCitites = async () => {
          try {
            dispatch(getCountriesFunc(countries_API_URL))
          } catch (error) {
            console.log(error);
          }
        };
        getCitites();
      }, []);
      useEffect(() => {
        countriesData && setCountriesList(countriesData);
        countriesData && (jobData.current = countriesData.slice(
          activePag.current * sort,
          (activePag.current + 1) * sort
        ));
      }, [countriesData]);
      const sort = 7;
      let paggination = Array(Math.ceil(countriesList.length / sort))
        .fill()
        .map((_, i) => i + 1);
    
      const activePag = useRef(0);
      const jobData = useRef(
        countriesList.slice(
          activePag.current * sort,
          (activePag.current + 1) * sort
        )
      );
      const onClick = (i) => {
        activePag.current = i;
        jobData.current = countriesList.slice(
          activePag.current * sort,
          (activePag.current + 1) * sort
        );
      };
      const search = () => {
        if(searchValue !== "") {
          const searchResult = countriesData &&
          countriesData.length >= 1 && countriesData.filter((country) =>  country.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()));
          console.log(searchResult)
          jobData.current = searchResult
        }else {
          dispatch(getCountriesFunc(countries_API_URL))
        }
      }
      useEffect(() => {
        searchValue === "" && dispatch(getCountriesFunc(countries_API_URL));
      },[searchValue])
      const deleteCountryFunction = async (id) => {
        swal({
          title: "Confirm Country Deletion",
          text: `Once deleted, This Country will not be avilable!`,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            dispatch(deleteCountryFunc(`${countries_API_URL}/${id}`))
            .then((result) => {
              if(result.payload.success === true){
                dispatch(getCountriesFunc(countries_API_URL));
                toast.success(`${result.payload.message} !`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
              })
              }
            })
          } else {
            swal("Country is safe!");
          }
        });
      };
    return (
        <>
        <PageTitle motherMenu={"Dashboard"} activeMenu={"Countries"} />
        {
            !getCountriesLoading && countriesList.length < 1
            &&
            checkPermissions(
              "create addresses",
              <div className="relative mt-2 flex justify-end items-center">
                <Link 
                to={"#"} 
                className="btn btn-primary btn-sm"
                onClick={() => [
                  setShowNewCountryForm(true)
                ]}
                >New Country</Link>
              </div>
            )
        }
        {/* {

            (
                countriesData
                ?
                ( */}
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header flex justify-between items-center">
                        <h4 className="card-title">Countries</h4>
                        <div className="relative add-and-search flex items-center">
                          <div className="input-group search-area w-auto mr-2">
                              <span className="input-group-text"
                                onClick={() => search()}
                              >
                                  <Link to={"#"}>
                                  <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 32 32"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                  >
                                      <path
                                      d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                                      fill="var(--primary)"
                                      ></path>
                                  </svg>
                                  </Link>
                              </span>
                              <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search here..."
                                  onChange={(e) => {
                                  setSearchValue(e.target.value);
                                  }}
                              />
                          </div>
                          {
                            checkPermissions(
                              "create addresses",
                              <Link 
                              to={"#"} 
                              className="btn btn-primary btn-sm"
                              onClick={() => [
                                setShowNewCountryForm(true)
                              ]}
                              >New Country</Link>
                            )
                          }
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="w-100 table-responsive">
                          <div id="example_wrapper" className="dataTables_wrapper">
                            <table id="example" className="display w-100 dataTable">
                              <thead>
                                <tr role="row">
                                  <th>#ID</th>
                                  <th>Name</th>
                                  <th>Code</th>
                                  <th>Status</th>
                                  <th>Cities</th>
                                  <th>States</th>
                                  {
                                    checkPermissions
                                    (
                                      ["delete addresses" , "update addresses"],
                                      <th>Actions</th>
                                    )
                                  }
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  getCountriesLoading
                                  ?
                                  (
                                    <tr>
                                      <td colSpan={2}>
                                        <SpinnerLoading/>
                                      </td>
                                    </tr>
                                  )
                                  :
                                  countriesList && countriesList.length >= 1
                                  ?
                                  jobData.current.map((country , index) => {
                                      return (
                                          <tr key={index}>
                                              <td>{country.id}</td>
                                              <td>{country.name}</td>
                                              <td>{country.code}</td>
                                              <td>
                                                  <span className={`relative text-sm rounded-lg text-white ${country.active === 1 ? "bg-green-500" : "bg-red-500"} p-1`}>
                                                      {
                                                          country.active === 1
                                                          ?
                                                          "Active"
                                                          :
                                                          "Not Active"
                                                      }
                                                  </span>
                                              </td>
                                              <td>{country.cities_count}</td>
                                              <td>{country.states_count}</td>
                                              <td>
                                                {
                                                  checkPermissions(
                                                    "update addresses",
                                                    <Link
                                                        to="#"
                                                        className="btn btn-primary shadow btn-xs sharp me-1"
                                                        onClick={() => {
                                                          setShowUpdateForm(true);
                                                          setCountryWhichUpdate(country);
                                                        }}
                                                    >
                                                        <i className="fas fa-pencil-alt"></i>
                                                    </Link>
                                                  )
                                                }
                                                {
                                                  checkPermissions(
                                                    "delete addresses",
                                                    <Link
                                                        to="#"
                                                        className="btn btn-danger shadow btn-xs sharp"
                                                        onClick={() => {
                                                            deleteCountryFunction(country.id)
                                                        }}
                                                    >
                                                        <i className="fa fa-trash"></i>
                                                    </Link>
                                                  )
                                                }
                                              </td>
                                          </tr>
                                      )
                                  }) 
                                  :
                                  <tr>
                                    <td colSpan={4}>No data avilable</td>
                                  </tr>
                                }
                              </tbody>
                            </table>
              
                            <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-2">
                              <div className="dataTables_info">
                                Showing {activePag.current * sort + 1} to{" "}
                                {countriesList.length > (activePag.current + 1) * sort
                                  ? (activePag.current + 1) * sort
                                  : countriesList.length}{" "}
                                of {countriesList.length} entries
                              </div>
                              <div
                                className="dataTables_paginate paging_simple_numbers mb-0"
                                id="example5_paginate"
                              >
                                <Link
                                  className="paginate_button previous disabled"
                                //   to="/table-datatable-basic"
                                  to="#"
                                  onClick={() =>
                                    activePag.current > 0 && onClick(activePag.current - 1)
                                  }
                                >
                                  <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                                </Link>
                                <span>
                                  {paggination.map((number, i) => (
                                    <Link
                                      key={i}
                                    //   to="/table-datatable-basic"
                                      to="#"
                                      className={`paginate_button  ${
                                        activePag.current === i ? "current" : ""
                                      } `}
                                      onClick={() => onClick(i)}
                                    >
                                      {number}
                                    </Link>
                                  ))}
                                </span>
                                <Link
                                  className="paginate_button next"
                                //   to="/table-datatable-basic"
                                  to="#"
                                  onClick={() =>
                                    activePag.current + 1 < paggination.length &&
                                    onClick(activePag.current + 1)
                                  }
                                >
                                  <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                {/* )
                :
                <FaildLoadData
                    refetchDataFunc={() => dispatch(getCountriesFunc(countries_API_URL))}
                />
            )
        } */}
        <LoadingScreen loading={deleteCountryLoading} />
        <NewCountryForm
          show={showNewCountryForm}
          onHide={() => setShowNewCountryForm(false)}
        />
        <UpdateCountryForm
          show={showUpdateForm}
          onHide={() => setShowUpdateForm(false)}
          countryId = {countryWichUpdate.id}
          oldName = {countryWichUpdate.name}
          oldCode = {countryWichUpdate.code}
          oldStatus = {countryWichUpdate.active}
        />
        </>
    )
}