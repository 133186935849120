import React, { useContext , useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//
import loginbg from "../../../images/bg-1.jpg";
import logo from "../../../images/log.png";
// import { loginFunc } from "../../../store/custom-actions/login-action/LoginSlicer";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { ContextApi } from "../../../store/ContextApi";
import { loginFunc } from "../../../store/custom-actions/auth/login-actions/LoginSlicer";
import Swal from "sweetalert2";
import { getHomeDataFunc } from "../../../store/custom-actions/settings-actions/dahsboard/GetHomeDataSlicer";
import { getGeneralSettingsFunc } from "../../../store/custom-actions/settings-actions/general-settings/GetGeneralSettingsSlicer";
// import ContextApi from "../../../store/ContextApi";
function LoginPage(props) {
  const {loading } = useSelector(state => state.loginSlicer);
  const dispatch = useDispatch();
  const {register , watch , formState : {errors} , handleSubmit} = useForm();
  const contextApi = useContext(ContextApi);
  const login_url = `${contextApi.dashboard_URL_API}/login`;
  const homeData_url = `${contextApi.dashboard_URL_API}/global-settings`;
  const generalSettingsApiApi = `${contextApi.dashboard_URL_API}/general-settings`;
  const [showPass , setShowPass] = useState(false);
  const pass = watch("password");
  const navigate = useNavigate();
  const loginFunction = async (formData) => {
    try {
      await dispatch(loginFunc(
        {
          api : login_url,
          userData : formData
        }
      )).then(result => {
        if(result.payload && result.payload.success === true) {
          // swal("Good job!", "submission is successfully!", "success");
          navigate("/")
          // dispatch(getHomeDataFunc(homeData_url));
          dispatch(getGeneralSettingsFunc(generalSettingsApiApi));
        }
      })
    }
    catch (error) {
      Swal.fire({
        title: "Oops!",
        text: error,
        icon: "error"
      });
    }
  }

  return (
    <div
      className="login-main-page w-full flex justify-center items-center"
      style={{
        backgroundImage: "url(" + loginbg + ")",
        backgroundSize: "cover",
      }}
    >
      <div className="relative row login-container flex justify-center items-cenetr bg-white rounded-md py-2">
        <div className="col-xl-6 col-md-6 text-center">
          <div>
            <img src={logo} className="education-img" alt="ll"></img>
          </div>
        </div>
        <div className="col-xl-6 col-md-6">
          <div className="sign-in-your">
            <h4 className="fs-20 font-w800 text-black">
              Sign in your account
            </h4>
            {/* <span>
              Welcome back! Login with your data that you entered
              <br /> during registration
            </span> */}
            <form 
            id="login-form"
            onSubmit={handleSubmit((data) => {
              loginFunction(data);
            })}>
              <div className="mb-3">
                <label className="mb-1">
                  <strong>Email</strong>
                </label>
                <input
                  {...register("email" , {required : "Email is required"})}
                  type="email"
                  className="form-control"
                />
                {errors.email?.message && 
                  <p className="text-danger fs-12">
                    {errors.email?.message}
                  </p>
                }
              </div>
              <div className="mb-3 relative overflow-hidden">
                <label className="mb-1">
                  <strong>Password</strong>
                </label>
                <input
                  {...register("password" , {required : "Password is required"})}
                  type={`${showPass ? "text" : "password"}`}
                  className="form-control"
                  form="login-form"
                />
                {errors.password?.message && 
                  <p className="text-danger fs-12">
                    {errors.password?.message}
                  </p>
                }
                <i 
                  className={`fa-regular fa-eye absolute transition-all duration-500 text-[20px] cursor-pointer top-[50%] right-0 ${pass ? "right-[10px] opacity-100" : "right-[-30px] opacity-0"} ${showPass ? "text-blue-600" : ""}`}
                  onClick={()=>setShowPass(!showPass)}
                ></i>
              </div>
              <button
                  type="submit"
                  className={`btn btn-primary btn-block mt-1 ${loading ? "opacity-80 pointer-events-none" : ""}`}
                >
                {loading
                  ?
                  <>
                  <div className="relative flex justify-center items-center">
                    loading..
                  </div>
                  </>
                  :
                  "Sign In Me" 
                }
              </button>
            </form>
          </div>
        </div>
        </div>
    </div>
  );
}


export default LoginPage;
