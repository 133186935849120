import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import SpinnerLoading from "../../components/spinner-loading/SpinnerLoading";
import PageTitle from "../../layouts/PageTitle";
import { ContextApi } from "../../../store/ContextApi";
import { toast } from "react-toastify";
import { getLogoSettingsFunc } from "../../../store/custom-actions/settings-actions/logo-settings/GetLogoSettingsSlicer";
import { postLogoSettingsFunc } from "../../../store/custom-actions/settings-actions/logo-settings/PostLogoSettingsSlicer";
import { getHomeDataFunc } from "../../../store/custom-actions/settings-actions/dahsboard/GetHomeDataSlicer";
import UploadImagesModale from "../../components/upload-images/UploadImagesModale";
function MediaSettings() {
    const contextApi = useContext(ContextApi);
    const settings_URL_APi = `${contextApi.dashboard_URL_API}/general-settings`;
    const homeData_url = `${contextApi.dashboard_URL_API}/global-settings`;
    const {loading:getSettingsLoading , data} = useSelector(
        state => state.getLogoSettingsSlicer
    );
    const {loading:postSettingsLoading} = useSelector(
        state => state.postLogoSettingsSlicer
    );
    const dispatch = useDispatch();
    const {handleSubmit} = useForm();
    const getSettings = async () => {
        dispatch(getLogoSettingsFunc(settings_URL_APi))
    }
    useEffect(() => {
        getSettings()
    },[])
    const [logoImage, setLogoImage] = useState("");
    const [imageViwer, setImageViwer] = useState("");
    const [showUploadLogo, setShowUploadLogo] = useState();

    const [footerImg, setFooterImg] = useState();
    const [showUploadFooterImg, setShowUploadFooterImg] = useState();
    const [footerImageViewr, setFooterImageViewr] = useState();
    
    const [invoiceLogo, setInvoiceLogo] = useState();
    const [showUploadInvoiceLogo, setShowUploadInvoiceLogo] = useState();
    const [invoiceLogoViewr, setInvoiceLogoViewr] = useState();

    const [favicon, setFavicon] = useState();
    const [showUploadFavicon, setShowUploadFavicon] = useState();
    const [faviconViewr, setFaviconViewr] = useState();
    useEffect(() => {
        if(data) {
            setImageViwer(data.site_logo)
            setFooterImageViewr(data.footer_logo)
            setInvoiceLogoViewr(data.invoice_logo)
            setFaviconViewr(data.site_favicon)
        }
      }, [data]);
    const handleSetNewLogo = (image) => {
        setImageViwer(image[0].src)
        setLogoImage(image[0].value)
    } 
    const handleSetNewFooterImg = (image) => {
        setFooterImageViewr(image[0].src)
        setFooterImg(image[0].value)
    } 
    const handleSetNewInvoiceLogo = (image) => {
        setInvoiceLogoViewr(image[0].src)
        setInvoiceLogo(image[0].value)
    } 
    const handleSetNewFavicon = (image) => {
        setFaviconViewr(image[0].src)
        setFavicon(image[0].value)
    } 
    const handleSubmitData = async (data) => {
        const formData = new FormData();
        logoImage && formData.append("site_logo" , logoImage);
        footerImg && formData.append("footer_logo" , footerImg);
        invoiceLogo && formData.append("invoice_logo" , invoiceLogo);
        favicon && formData.append("site_favicon" , favicon);
        dispatch(postLogoSettingsFunc(
            {
                api : settings_URL_APi , 
                data : formData
            }
        ))
        .then (result => {
            if(result.payload.success === true) {
                console.log(result.payload)
                getSettings();
                changeFavicon(faviconViewr?.includes(contextApi.domainUrl) ? faviconViewr : `${contextApi.domainUrl}/${faviconViewr}`);
                dispatch(getHomeDataFunc(homeData_url))
                toast.success(`${result.payload.message} !`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                })
            }
        })
        // if(logoImage !== "") {
        // }else {
        //     toast.error(`Logo Must Not be empty!`, {
        //         position: "top-right",
        //         autoClose: 5000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //     })
        // }
    }
    const changeFavicon = (iconURL) => {
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
            link = document.createElement('link');
            link.rel = 'icon';
            document.getElementsByTagName('head')[0].appendChild(link);
        }
        link.href = iconURL;
    };
    useEffect(() => {
        if(faviconViewr) {
            changeFavicon(faviconViewr);
            window.localStorage.setItem("siteFavicon" , faviconViewr?.includes(contextApi.domainUrl) ? faviconViewr : `${contextApi.domainUrl}/${faviconViewr}`)
        }
    },[faviconViewr , data])
    useEffect(() => {
        if(data) {
            changeFavicon(`${contextApi.domainUrl}/${data?.site_favicon}`);
            window.localStorage.setItem("siteFavicon" , `${contextApi.domainUrl}/${data?.site_favicon}`)
        }
        return () => {
            changeFavicon(`${contextApi.domainUrl}/${data?.site_favicon}`)
            window.localStorage.setItem("siteFavicon" , `${contextApi.domainUrl}/${data?.site_favicon}`)
        }
    },[data])
    return (
        <>
            <PageTitle motherMenu={"Settings"} activeMenu={"Media Settings"} customClasses={"mb-0"}/>
            <div className="toolbar toolbar-bottom p-2 mb-3 mt-0">
                <button 
                type="submit"
                form="change-admin-image-form"
                className={`btn btn-primary sw-btn-next ms-1 ${postSettingsLoading  ? "pointer-events-none opacity-50" : ""}`}
                >
                    {
                        postSettingsLoading
                        ?
                        "loading.."
                        :
                        "Submit"
                    }
                </button>
            </div>	
            {
                getSettingsLoading
                ?
                <SpinnerLoading/>
                :
                <div className="row">
                    <div className="col-xl-12 col-xxl-12">
                        <div className="form-wizard ">
                            <form
                                className="change-admin-image-form"
                                id="change-admin-image-form"
                                onSubmit={handleSubmit((data) => {
                                    handleSubmitData(data);
                                })}
                            >
                                {/* logo input */}
                                <div className="card">
                                    <div className="card-body">
                                        <h3>Site Logo</h3>
                                        <div className="form-group mb-2">
                                            {
                                                imageViwer !== ""
                                                ?
                                                <div className="relative flex justify-center items-center mb-2">
                                                    <div className="relative w-[240px] h-[200px] ">
                                                        <img src={imageViwer?.includes(contextApi.domainUrl) ? imageViwer : `${contextApi.domainUrl}/${imageViwer}`} alt="logo" className="absolute top-0 left-0 w-full h-full" />
                                                    </div>
                                                </div>
                                                :
                                                ""
                                            }
                                            <label>Logo best size (200px * 180px)</label>
                                            <input 
                                                id="file" 
                                                type="file" 
                                                className="form-control"
                                                onClick={(event) => {event.preventDefault(); setShowUploadLogo(true);}}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* footer image */}
                                <div className="card">
                                    <div className="card-body">
                                        <h3>Footer logo</h3>
                                        <div className="form-group mb-2">
                                            {
                                                footerImageViewr !== ""
                                                ?
                                                <div className="relative flex justify-center items-center mb-2">
                                                    <div className="relative w-[240px] h-[200px] ">
                                                        <img src={footerImageViewr?.includes(contextApi.domainUrl) ? footerImageViewr : `${contextApi.domainUrl}/${footerImageViewr}`} alt="footer logo" className="absolute top-0 left-0 w-full h-full" />
                                                    </div>
                                                </div>
                                                :
                                                ""
                                            }
                                            <label>Footer logo best size (200px * 200px)</label>
                                            <input 
                                                id="file" 
                                                type="file" 
                                                className="form-control"
                                                onClick={(event) => {event.preventDefault(); setShowUploadFooterImg(true);}}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* invoce image */}
                                <div className="card">
                                    <div className="card-body">
                                        <h3>Invoice logo</h3>
                                        <div className="form-group mb-2">
                                            {
                                                invoiceLogoViewr !== ""
                                                ?
                                                <div className="relative flex justify-center items-center mb-2">
                                                    <div className="relative w-[240px] h-[200px] ">
                                                        <img src={invoiceLogoViewr?.includes(contextApi.domainUrl) ? invoiceLogoViewr : `${contextApi.domainUrl}/${invoiceLogoViewr}`} alt="footer logo" className="absolute top-0 left-0 w-full h-full" />
                                                    </div>
                                                </div>
                                                :
                                                ""
                                            }
                                            <label>Invoice logo best size (200px * 200px)</label>
                                            <input 
                                                id="file" 
                                                type="file" 
                                                className="form-control"
                                                onClick={(event) => {event.preventDefault(); setShowUploadInvoiceLogo(true);}}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* favicon image */}
                                <div className="card">
                                    <div className="card-body">
                                        <h3>Favicon logo</h3>
                                        <div className="form-group mb-2">
                                            {
                                                faviconViewr !== ""
                                                ?
                                                <div className="relative flex justify-center items-center mb-2">
                                                    <div className="relative w-[240px] h-[200px] ">
                                                        <img src={faviconViewr?.includes(contextApi.domainUrl) ? faviconViewr : `${contextApi.domainUrl}/${faviconViewr}`} alt="footer logo" className="absolute top-0 left-0 w-full h-full" />
                                                    </div>
                                                </div>
                                                :
                                                ""
                                            }
                                            <label>Favicon best size (150px * 150px)</label>
                                            <input 
                                                id="file" 
                                                type="file" 
                                                className="form-control"
                                                onClick={(event) => {event.preventDefault(); setShowUploadFavicon(true);}}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            }
            <UploadImagesModale show={showUploadLogo} onHide={() => setShowUploadLogo(false)}  setImagesFunc={handleSetNewLogo} multipleOption={false}
            // checkedList={[
            // {src : `${contextApi.domainUrl}/${imageViwer}` , value : old_slider_data?.mobile_image}
            // ]}
            />
            <UploadImagesModale show={showUploadFooterImg} onHide={() => setShowUploadFooterImg(false)}  setImagesFunc={handleSetNewFooterImg} multipleOption={false}
            // checkedList={[
            // {src : `${contextApi.domainUrl}/${sliderMobileImageViewr}` , value : old_slider_data?.mobile_image}
            // ]}
            />
            <UploadImagesModale show={showUploadInvoiceLogo} onHide={() => setShowUploadInvoiceLogo(false)}  setImagesFunc={handleSetNewInvoiceLogo} multipleOption={false}
            // checkedList={[
            // {src : `${contextApi.domainUrl}/${sliderMobileImageViewr}` , value : old_slider_data?.mobile_image}
            // ]}
            />
            <UploadImagesModale show={showUploadFavicon} onHide={() => setShowUploadFavicon(false)}  setImagesFunc={handleSetNewFavicon} multipleOption={false}
            // checkedList={[
            // {src : `${contextApi.domainUrl}/${sliderMobileImageViewr}` , value : old_slider_data?.mobile_image}
            // ]}
            />
        </>
    )
}


export default MediaSettings;