import ReactQuill from "react-quill";
// import PageTitle from "../../layouts/PageTitle";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
// import "./ui-settings-page.css"
import { useDispatch, useSelector } from "react-redux";
// import ContextApi from "../../../store/ContextApi";
import { useNavigate } from "react-router-dom";
import { addNewSliderFunc } from "../../../../store/custom-actions/sliders/AddSliderSlicer";
import { ContextApi } from "../../../../store/ContextApi";
import PageTitle from "../../../layouts/PageTitle";
import { toast } from "react-toastify";
import UploadImagesModale from "../../../components/upload-images/UploadImagesModale";

function NewSliderForm() {
    const contextApi = useContext(ContextApi);
    const ui_settings_URL_APi = `${contextApi.dashboard_URL_API}/general-settings/sliders`;
    const dispatch = useDispatch();
    const [sliderImg , setSliderImg] = useState();
    const [sliderMobileImg , setSliderMobileImg] = useState();
    const [sliderDescription , setSliderDescription] = useState();
    const {register , setValue , formState : {errors} , handleSubmit , reset} = useForm();
    const {loading:postSliderDataLoading} = useSelector(
        state => state.addNewSliderSlicer
    );
    const [showUploadImage , setShowUploadImage] = useState();
    const [showUploadMobileImage , setShowUploadMobileImage] = useState();
    const [sliderImageViewr , setSliderImageViewr] = useState("");
    const [sliderMobileImageViewr , setSliderMobileImageViewr] = useState("");
    const colors = [
        '#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff',
        '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff',
        '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff',
        '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2',
        '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466',
        '#000000', '#990000', '#b26b00', '#b2b200', '#005c00', '#003399', '#592966'
    ];
    const modules = {
        toolbar: {
        container: [
        [{"color" : colors}],
        [{ 'background': colors }]
        [{ 'header': [1, 2, 3, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        ['link' , "video"],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'align': [] }],
        ['clean']
        ],
    }
    };

    const handleSubmitSlider = async (data) => {
        if(data && sliderImg) {
            const sliderFormData = new FormData();
            sliderFormData.append("image" , sliderImg);
            sliderFormData.append("mobile_image" , sliderMobileImg);
            sliderFormData.append("title" , data.title);
            sliderFormData.append("description" , sliderDescription);
            sliderFormData.append("link" , data.link);
            dispatch(addNewSliderFunc({api : ui_settings_URL_APi , data : sliderFormData}))
            .then(result => {
                console.log(result)
                if(result.payload.success === true) {
                    setTimeout(() => window.history.back() , 2000);
                    reset();
                    setSliderDescription("");
                    setSliderImg("");
                    // NewSliderForm("/update-ui-settings")
                    toast.success(`${result.payload.message} !`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    })
                }
            })
        }
    }
    const handleSetSliderImage = (image) => {
        setSliderImageViewr(image.src)
        setSliderImg(image.value)
    }
    const handleSetMobileSliderImage = (image) => {
        setSliderMobileImageViewr(image.src)
        setSliderMobileImg(image.value)
    }
    return (
        <>
            <PageTitle motherMenu={"Home"} activeMenu={"New Slider"} customClasses={"my-0"} />
            <div className="relative flex items-center justify-end my-3">
                <div className="relative flex items-center">
                    <button class="rounded-lg relative w-36 h-10 cursor-pointer flex items-center border border-blue-500 bg-blue-500 group hover:bg-blue-500 active:bg-blue-500 active:border-blue-500 overflow-hidden"
                        onClick={() => window.history.back()}
                    >
                        <span class="text-gray-200 font-semibold ml-8 transform group-hover:translate-x-20 transition-all duration-300">Back</span>
                        <span class="absolute right-0 h-full w-10 rounded-lg bg-blue-500 flex items-center justify-center transform group-hover:translate-x-0 group-hover:w-full transition-all duration-300">
                            <i className="fa-solid fa-arrow-left text-white"></i>
                        </span>
                    </button>
                    <button 
                        type="submit"
                        form="new-slider-form"
                        class={`flex items-center bg-primary text-white gap-1 px-4 py-2 cursor-pointer  font-semibold tracking-widest rounded-md hover:bg-blue-400 duration-300 ml-1 hover:gap-2 hover:translate-x-3
                        ${postSliderDataLoading ? "pointer-events-none opacity-80" : ""}
                        `}
                        >
                            {
                                postSliderDataLoading
                                ?
                                "Please Wait.."
                                :
                                <>
                                    Submit
                                    <i className="fa-solid fa-gear text-white ml-1"></i>
                                </>
                            }
                    </button>
                </div>
            </div>
            <div className="col">
                <div className="card p-4">
                    <div className="relative settings-form-content">
                        <div className="new-lesson-form-div relative w-full ">
                            <form 
                                className="new-slider-form"
                                id="new-slider-form"
                                onSubmit={handleSubmit((data) => {
                                    handleSubmitSlider(data)
                                })}
                            >
                                {
                                    <>
                                        <div className={'row'}>
                                            <div className="form-group logo-input mb-3 col-sm-12 col-md-6">
                                                <label className="flex items-center">
                                                    <i className="fa-brands fa-cuttlefish mr-1"></i>
                                                    Slider Title
                                                </label>
                                                {errors.title?.message && (
                                                <p className="text-red-600">
                                                    {errors.title?.message}
                                                </p>
                                                )}
                                                <input
                                                    {...register("title")}
                                                    type="text"
                                                    className="form-control"
                                                />
                                            </div>
                                            <div className="form-group mb-3 col-sm-12 col-md-6">
                                                <label className="flex items-center">
                                                    <i className="fa-solid fa-link mr-1"></i>
                                                    Path Link
                                                </label>
                                                {errors.link?.message && (
                                                <p className="text-red-600">
                                                    {errors.link?.message}
                                                </p>
                                                )}
                                                <input
                                                {...register("link")}
                                                type="text"
                                                className="form-control"
                                                />
                                            </div>
                                        </div>
                                        <div className={`row`}>
                                            <div className="form-group mb-3 col-sm-12 col-md-6">
                                                {
                                                    sliderImageViewr !== ""
                                                    ?
                                                    <div className="relative flex justify-center items-center mb-2">
                                                        <div className="relative w-[240px] h-[200px] ">
                                                            <img src={sliderImageViewr?.includes(contextApi.domainUrl) ? sliderImageViewr : `${contextApi.domainUrl}/${sliderImageViewr}`} alt="footer logo" className="absolute top-0 left-0 w-full h-full" />
                                                        </div>
                                                    </div>
                                                    :
                                                    ""
                                                }
                                                <label className="flex items-center">
                                                    <i className="fa-solid fa-image mr-1"></i>
                                                    Slider Image
                                                </label>
                                                <input
                                                type="file"
                                                className="form-control"
                                                // onChange={(e) => setSliderImg(e.target.files[0])}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setShowUploadImage(true)
                                                }}
                                                accept="image/*"
                                                />
                                            </div>
                                            <div className="form-group mb-3 col-sm-12 col-md-6">
                                                {
                                                    sliderMobileImageViewr !== ""
                                                    ?
                                                    <div className="relative flex justify-center items-center mb-2">
                                                        <div className="relative w-[240px] h-[200px] ">
                                                            <img src={sliderMobileImageViewr?.includes(contextApi.domainUrl) ? sliderMobileImageViewr : `${contextApi.domainUrl}/${sliderMobileImageViewr}`} alt="footer logo" className="absolute top-0 left-0 w-full h-full" />
                                                        </div>
                                                    </div>
                                                    :
                                                    ""
                                                }
                                                <label className="flex items-center">
                                                    <i className="fa-solid fa-mobile-screen-button mr-1"></i>
                                                    Slider Mobile Image
                                                </label>
                                                <input
                                                type="file"
                                                className="form-control"
                                                // onChange={(e) => setSliderMobileImg(e.target.files[0])}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setShowUploadMobileImage(true)
                                                }}
                                                accept="image/*"
                                                />
                                            </div>
                                        </div>
                                        {/* <div className="slider-description-line mb-3 relative my-3 flex justify-center items-center after:absolute after:left-[50%] after:translate-x-[-50%] after:top-[50%] after:px-2 after:bg-[#eaebef]  after:translate-y-[-50%] bg-slate-300 w-full h-[1px]"></div> */}
                                        <div className="row m-0">
                                            <ReactQuill 
                                            modules = {modules}
                                            className="p-0 border-none outline-none form-control bg-white mt-3" 
                                            value={sliderDescription} theme="snow" 
                                            onChange={(content) => setSliderDescription(content)} />
                                        </div>
                                    </>
                                }
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <UploadImagesModale show={showUploadImage} onHide={() => setShowUploadImage(false)} multipleOption={false} setImagesFunc={handleSetSliderImage}/>
            <UploadImagesModale show={showUploadMobileImage} onHide={() => setShowUploadMobileImage(false)} multipleOption={false} setImagesFunc={handleSetMobileSliderImage}/>
        </>
    )
}


export default NewSliderForm;