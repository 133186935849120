import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { checkErrors } from "../../../../jsx/global-functions/checkErrors";

export const logoutFunc = createAsyncThunk(
    "login",
    async () => {
        const token = window.localStorage.getItem("token");
        const config = {
            headers: {
                Authorization: `Bearer ${token}` // Include the token in the Authorization header
            }
        }
        axios.defaults.headers.common["Content-Type"] = "application/json";
        axios.defaults.headers.common["Accept"] = "application/json";
        try {
            const response = await axios.post(`https://apis.hamzastore-eg.com/api/dashboard/logout` , {} , config);
                console.log(response)
                if(response && response.data) {
                    if(response.data.success === true) {
                        window.location.href = "/login-page" 
                        // window.localStorage.clear();
                        window.localStorage.removeItem("user")
                        window.localStorage.removeItem("token")
                        window.localStorage.removeItem("expire_date")
                    }
                }
        }
        catch (error) {
            checkErrors(error.response)
        }
    }
);

const logoutSlicer = createSlice({
    name : "user logout",
    initialState : {
        loading : false,
        status : null,
        error : false
    },
    reducers : {
        loginAction : (state , action) => {
            state.loading = false
            state.status = "loggedIn"
            state.error = false
        }
    },
    extraReducers : (builder) => {
        builder.addCase(logoutFunc.pending , (state) =>{
            state.loading = true
            state.status = null
            state.error = false
        })
        builder.addCase(logoutFunc.rejected , (state) =>{
            state.loading = false
            state.status = null
            state.error = true
        })
        builder.addCase(logoutFunc.fulfilled , (state , action) =>{
            state.loading = false
            state.status = action.payload
            state.error = false
        })
    }
})
export default logoutSlicer.reducer;
export let {loginAction} = logoutSlicer.actions;