// import React, { useState  , useEffect, useContext} from 'react';
// import { ContextApi } from '../../../store/ContextApi';

// const CheckboxBtn = ({ index ,  image , arrayWillSend , removing , additionDone}) => {
//   const contextApi = useContext(ContextApi);
//   const [checked, setChecked] = useState();

//   // useEffect(() => {
//   //   console.log(arrayWillSend)
//   //   console.log(image)
//   //   if(arrayWillSend) {
//   //     const find = arrayWillSend?.flatMap(item => item.value).find(id => id == image.id);
//   //     console.log(find);
//   //     find ? setChecked(true) : setChecked(false)
//   //   }
//   // },[arrayWillSend])
//   useEffect(() => {
//     if (arrayWillSend && image?.id !== undefined) {
//       const exists = arrayWillSend
//         .flatMap(item => item.value)
//         .some(id => id == image.id);
//       console.log(exists); // Will log true or false
//       exists ? setChecked(true) : setChecked(false);
//     }
//   }, [arrayWillSend, image]);
//   const toggelChecked = (e) => {
//     // setChecked(!checked)
//     if(e.target.checked) {
//       setChecked(true)
//       additionDone({src: `${contextApi.domainUrl}/${image?.path}`, value:image.id})
//     }else {
//       setChecked(false)
//       const arrAfterRemoving = arrayWillSend && arrayWillSend.length >=1 && arrayWillSend.filter(img => img.id !== image.id) 
//       removing(arrAfterRemoving)
//     }
//   }
//   useEffect(() => {
//     console.log(checked)
//   },[checked])
//   return (
//     <>
//       <input type="checkbox" name="img" id={index}
//       checked = {checked}
//       onChange={(e)=>{
//         console.log(e.target.checked)
//         toggelChecked(e);
//       }}
//       // className="hidden"
//       />
//       <label
//       className={`relative w-[120px] h-[120px] bg-center bg-no-repeat bg-cover cursor-pointer rounded-md p-1 image-picker-label ${checked ? "border-2 border-primary" : "border-none"}`} htmlFor={index}
//       >
//         <img src={`${contextApi.domainUrl}/${image?.path}`} className="w-full h-full object-cover" />
//       </label>
//     </>
//   );
// };

// export default CheckboxBtn;


import React, { useState, useEffect, useContext } from 'react';
import { ContextApi } from '../../../store/ContextApi';

const CheckboxBtn = ({ index, image, arrayWillSend, removing, additionDone }) => {
  const contextApi = useContext(ContextApi);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    console.log(arrayWillSend)
    if (arrayWillSend && image?.id !== undefined) {
      const exists = arrayWillSend
        .flatMap(item => item.value)
        .some(id => id === image.id);
        exists ? setChecked(true) : setChecked(false)
    }
  }, [arrayWillSend, image]);

  const toggelChecked = (e) => {
    if (e.target.checked) {
      additionDone({ src: `${contextApi.domainUrl}/${image?.path}`, value: image.id });
    } else {
      const arrAfterRemoving = arrayWillSend.filter(img => img.value !== image.id);
      console.log(arrAfterRemoving)
      removing(arrAfterRemoving);
    }
  };

  useEffect(() => {
    console.log(checked);  // Debugging: Check if checked state changes
  }, [checked]);

  return (
    <>
      <input
        type="checkbox"
        name="img"
        id={index}
        checked={checked}  // Use the `checked` state for the checkbox
        onChange={(e) => {
          console.log(e.target.checked);
          toggelChecked(e);
        }}
      />
      <label
        className={`relative w-[120px] h-[120px] bg-center bg-no-repeat bg-cover cursor-pointer rounded-md p-1 image-picker-label ${checked ? "border-2 border-primary" : "border-none"}`}
        htmlFor={index}
      >
        <img src={`${contextApi.domainUrl}/${image?.path}`} className="w-full h-full object-cover" />
      </label>
    </>
  );
};

export default CheckboxBtn;
