import { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import SpinnerLoading from "../../components/spinner-loading/SpinnerLoading";
import PageTitle from "../../layouts/PageTitle";
import { ContextApi } from "../../../store/ContextApi";
import { toast } from "react-toastify";
import { getTaxFeeSettingsFunc } from "../../../store/custom-actions/settings-actions/tax-fee-settings/GetTaxFeeSettingsSlicer";
import { postTaxFeeSettingsFunc } from "../../../store/custom-actions/settings-actions/tax-fee-settings/PostTaxFeeSettingsSlicer";
function TaxFeeForm() {
    const contextApi = useContext(ContextApi);
    const settings_URL_APi = `${contextApi.dashboard_URL_API}/general-settings`;
    const {loading:getSettingsLoading , data} = useSelector(
        state => state.getTaxFeeSettingsSlicer
    );
    const {loading:postSettingsLoading} = useSelector(
        state => state.postTaxFeeSettingsSlicer
    );
    const dispatch = useDispatch();
    const {register , setValue , formState : {errors} , handleSubmit} = useForm();
    const getSettings = async () => {
        dispatch(getTaxFeeSettingsFunc(settings_URL_APi))
    }
    useEffect(() => {
        getSettings()
    },[])
    useEffect(() => {
        if(data) {
            console.log(data)
            setValue("tax_fee_amount" , data.tax_fee_amount);
            setValue("tax_fee_type" , data.tax_fee_type);
        }
    },[data])
    const handleSubmitSettings = async (data) => {
        if(data) {
            const formData = new FormData();
            formData.append("tax_fee_amount" , data.tax_fee_amount)
            formData.append("tax_fee_type" , data.tax_fee_type)
            dispatch(postTaxFeeSettingsFunc({
                api : settings_URL_APi , 
                data : formData
            }))
            .then(result => {
                if(result.payload.success === true) {
                    getSettings();
                    toast.success(`${result.payload.message} !`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    })
                }
            })
        }
    }
    return (
        <>
            <PageTitle motherMenu={"Settings"} activeMenu={"TAX Settings"} customClasses={"mb-0"}/>
            <div className="relative flex items-center justify-between my-3">
                <div className="relative flex items-center">
                    <button 
                        type="submit"
                        form="new-settings-form"
                        className={`flex items-center bg-primary text-white gap-1 px-4 py-2 cursor-pointer  font-semibold tracking-widest rounded-md hover:bg-blue-400 duration-300 ml-1 hover:gap-2 hover:translate-x-3
                        ${postSettingsLoading ? "pointer-events-none opacity-80" : ""}
                        `}
                        >
                            {
                                postSettingsLoading
                                ?
                                "Please Wait.."
                                :
                                <>
                                    Submit
                                    <i className="fa-solid fa-gear text-white ml-1"></i>
                                </>
                            }
                    </button>
                </div>
            </div>
            
            {
                getSettingsLoading
                ?
                <SpinnerLoading/>
                :
                <div className="row">
                    <div className="col-xl-12 col-xxl-12">
                        <div className="card p-3">
                            <div className="relative settings-form-content">
                                <div className="new-settings-form-div relative w-full ">
                                    <form 
                                        className="new-settings-form"
                                        id="new-settings-form"
                                        onSubmit={handleSubmit((data) => {
                                            handleSubmitSettings(data)
                                        })}
                                    >
                                        {
                                            <>
                                                <div className={'row'}>
                                                    <div className="form-group mb-3 col-sm-12 col-md-6">
                                                        <label className="flex items-center">
                                                            <i className="fa-solid fa-arrow-up-9-1 mr-1"></i>
                                                            Tax Amount
                                                        </label>
                                                        {errors.tax_fee_amount?.message && (
                                                        <p className="text-red-600">
                                                            {errors.tax_fee_amount?.message}
                                                        </p>
                                                        )}
                                                        <input
                                                        {...register("tax_fee_amount")}
                                                        type="number"
                                                        className="form-control"
                                                        />
                                                    </div>
                                                    <div className="form-group mb-3 col-sm-12 col-md-6">
                                                        <label className="flex items-center">
                                                            <i className="fa-solid fa-arrow-up-9-1 mr-1"></i>
                                                            Amount Type
                                                        </label>
                                                        {errors.tax_fee_type?.message && (
                                                        <p className="text-red-600">
                                                            {errors.tax_fee_type?.message}
                                                        </p>
                                                        )}
                                                        <select
                                                            className="form-control"
                                                            {...register("tax_fee_type")}
                                                        >
                                                            <option value={"fixed"}>Fixed</option>
                                                            <option value={"percentage"}>Percentage</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}


export default TaxFeeForm;