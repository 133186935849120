import { lazy , useEffect, useState } from 'react';

/// Components
import Index from "./jsx";
import { useDispatch, useSelector } from 'react-redux';
import { checkAutoLogin } from './services/AuthService';
import { isAuthenticated } from './store/selectors/AuthSelectors';
/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import { ContextApi } from './store/ContextApi';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import { getHomeDataFunc } from './store/custom-actions/settings-actions/dahsboard/GetHomeDataSlicer';
import { getLanguageSettingsFunc } from './store/custom-actions/language/GetLanguageSettingsSlicer';
import LoadingScreen from './jsx/components/loading-screen/LoadingScreen';
import i18n from "i18next";
import 'react-toastify/dist/ReactToastify.css';
import { getGeneralSettingsFunc } from './store/custom-actions/settings-actions/general-settings/GetGeneralSettingsSlicer';
function App (props) {
    const token = window.localStorage.getItem("token")
    const dispatch = useDispatch();
    const [settings , setSettings] = useState({})
    const {loading:getGeneralSettingsLoading , data:generalSettingsData} = useSelector(
      state => state.getGeneralSettingsSlicer
    )
    const global_data = {
        // dashboard_URL_API : "https://apis.hamzastore-eg.com/api/dashboard",
        dashboard_URL_API : "https://apis-books.topgrowth-eg.com/api/dashboard",
        domainUrl : "https://apis-books.topgrowth-eg.com",
        main_headers : (
          axios.defaults.headers.common["Content-Type"] = "application/json",
          axios.defaults.headers.common["Accept"] = "application/json"
        ),
        homeSettings : settings
    }
    const changeFavicon = (iconURL) => {
      let link = document.querySelector("link[rel~='icon']");
      if (!link) {
          link = document.createElement('link');
          link.rel = 'icon';
          document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href = iconURL;
    };
    const changeTitle = (title) => {
      document.title = title;
    };
    useEffect(() => {
      !generalSettingsData && changeFavicon(window.localStorage.getItem("siteFavicon"))
      !generalSettingsData && changeTitle(window.localStorage.getItem("siteTitle"))
      !generalSettingsData && document.documentElement.style.setProperty("--primary" , window.localStorage.getItem("primaryColor"))
      !generalSettingsData && document.documentElement.style.setProperty("--secondary" , window.localStorage.getItem("secondaryColor"))
      generalSettingsData && changeFavicon(generalSettingsData?.site_favicon)
      generalSettingsData && changeTitle(generalSettingsData?.site_title)
      if(generalSettingsData) {
        document.documentElement.style.setProperty("--primary" , generalSettingsData?.back_primary_color)
        document.documentElement.style.setProperty("--secondary" , generalSettingsData?.back_secondary_color)
      }
    },[generalSettingsData])
    const generalSettingsApi = `${global_data.dashboard_URL_API}/general-settings`
    const handleGetGeneralSettings = () => {
      dispatch(getGeneralSettingsFunc(generalSettingsApi))
      .then((result) => {
        console.log(result)
        if(result?.payload) {
          setSettings(result?.payload)
          window.localStorage.setItem("siteFavicon" , `${global_data.domainUrl}/${result?.payload?.site_favicon}`)
          window.localStorage.setItem("siteTitle" , result?.payload?.site_title)
        }
      })
    }
    useEffect(() => {
      handleGetGeneralSettings()
    },[])
    useEffect(() => {
        checkAutoLogin(dispatch, props.history);
    }, [dispatch, props.history]);

    // getGeneralSettingsFunc
      const homeData_url = `${global_data.dashboard_URL_API}/global-settings`;
      const {data:mainData} = useSelector(
        state => state.getHomeDataSlicer
      )
      useEffect(() => {
        token && dispatch(getHomeDataFunc(homeData_url))
      },[token])
      useEffect(() => {
        if(mainData) {
          setSettings(mainData)
        }
      },[mainData])
      const languageAPi = `https://apis-books.topgrowth-eg.com/api/language-settings`
      useEffect(() => {
        const getSettings = () => {
            if(window.localStorage.getItem("langSlug")) {
              dispatch(getLanguageSettingsFunc(`${languageAPi}?lang=${window.localStorage.getItem("langSlug")}`))
            }else {
              dispatch(getLanguageSettingsFunc(languageAPi))
            }
        }
        getSettings()
    },[])
    const {loading:switchLangLoading , data:languageData} = useSelector(
      state => state.getLanguageSettingsSlicer
    )
    useEffect(() => {
      const fetchTranslations = async () => {
        if (languageData) {
          // document.documentElement.style.setProperty("--primary" , "#374557")
          const { defaultLang, all_word } = languageData;
          if (defaultLang && all_word) {
            const { direction, slug } = defaultLang;
            document.documentElement.dir = direction; // Set the direction dynamically
            window.localStorage.setItem("langSlug" , defaultLang.slug);
            i18n.addResources(slug, 'translation', all_word);
            i18n.changeLanguage(slug);
          } else {
            console.error('Invalid language data structure', languageData);
          }
        }
      };
      fetchTranslations();
    }, [languageData]);
      return (
        <>
          <ContextApi.Provider value={global_data}>
            <Index/>
          </ContextApi.Provider>
          <ToastContainer className="custom-toast-container"/>
          {
            <LoadingScreen loading={switchLangLoading}/>
          }
        </>
      )


};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: isAuthenticated(state),
    };
};

// export default withRouter(connect(mapStateToProps)(App)); 
export default App; 

