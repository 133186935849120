import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ContextApi } from "../../../store/ContextApi";
import PageTitle from "../../layouts/PageTitle";
import SpinnerLoading from "../../components/spinner-loading/SpinnerLoading";
import FaildLoadData from "../../global-components/FaildLoadData/FaildLoadData";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import LoadingScreen from "../../components/loading-screen/LoadingScreen";
import { toast } from "react-toastify";
import { getRolesFunc } from "../../../store/custom-actions/roles-actions/GetRolesSlicer";
import { deleteRoleFunc } from "../../../store/custom-actions/roles-actions/DeleteRoleSlicer";
import checkPermissions from "../../global-functions/checkPermissionsFunc";
import { checkErrors } from "../../global-functions/checkErrors";

export default function RolesPage() {
    const {loading:getRolesLoading , data:rolesData} = useSelector(state => state.getRolesSlicer)
    const {loading:deleteRoleLoading} = useSelector(state => state.deleteRoleSlicer)
    const contextApi = useContext(ContextApi);
    const roles_API_URL = `${contextApi.dashboard_URL_API}/roles`;
    const [rolesList , setRolesList] = useState([]);
    const [searchValue , setSearchValue] = useState("");
    const dispatch = useDispatch();
    useEffect(() => {
        const getRoles = async () => {
          try {
            dispatch(getRolesFunc(roles_API_URL))
          } catch (error) {
            console.log(error);
          }
        };
        getRoles();
      }, []);
      useEffect(() => {
        rolesData && setRolesList(rolesData);
        rolesData && (jobData.current = rolesData.slice(
            activePag.current * sort,
            (activePag.current + 1) * sort
          ));
      }, [rolesData]);
      const sort = 20;
      let paggination = Array(Math.ceil(rolesList.length / sort))
        .fill()
        .map((_, i) => i + 1);
    
      const activePag = useRef(0);
      const jobData = useRef(
        rolesList.slice(
          activePag.current * sort,
          (activePag.current + 1) * sort
        )
      );
      const onClick = (i) => {
        activePag.current = i;
        jobData.current = rolesList.slice(
          activePag.current * sort,
          (activePag.current + 1) * sort
        );
      };
      const search = () => {
        if(searchValue !== "") {
          const searchResult = rolesData &&
          rolesData.length >= 1 && rolesData.filter((role) =>  role.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()));
          jobData.current = searchResult
        }else {
          dispatch(getRolesFunc(roles_API_URL))
        }
      }
      useEffect(() => {
        searchValue === "" && dispatch(getRolesFunc(roles_API_URL));
      },[searchValue])
      const deleteRoleFunction = async (id) => {
        swal({
          title: "Confirm Role Deletion",
          text: `Once deleted, This Role will not be avilable!`,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            dispatch(deleteRoleFunc(`${roles_API_URL}/${id}`))
            .then((result) => {
              if(result.payload.success === true){
                dispatch(getRolesFunc(roles_API_URL));
                toast.success(`${result.payload.message} !`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
              })
              }
            })
          } else {
            swal("Role is safe!");
          }
        });
      };
    return (
        <>
        <PageTitle motherMenu={"Dashboard"} activeMenu={"Roles"} />
        {/* {
            !getRolesLoading && rolesList.length < 1
            &&
            checkPermissions(
                "create roles",
                <div className="relative mt-2 flex justify-end items-center">
                    <Link 
                    to={"/new-role"} 
                    className="btn btn-primary btn-sm"
                    >New Role</Link>
                </div>
            )
        } */}
        {
            getRolesLoading
            ?
            (
                <SpinnerLoading/>
            )
            :
            (
                rolesData
                ?
                (
                    // rolesList && rolesList.length >= 1
                    // &&
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header flex justify-between items-center">
                            <h4 className="card-title">Roles</h4>
                            <div className="relative add-and-search flex items-center">
                                <div className="input-group search-area w-auto mr-2">
                                    <span className="input-group-text"
                                    onClick={() => search()}
                                    >
                                        <Link to={"#"}>
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 32 32"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                            d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                                            fill="var(--primary)"
                                            ></path>
                                        </svg>
                                        </Link>
                                    </span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search here..."
                                        onChange={(e) => {
                                        setSearchValue(e.target.value);
                                        }}
                                    />
                                </div>
                                {
                                    checkPermissions(
                                        "create roles",
                                        <Link 
                                        to={"/new-role"} 
                                        className="btn btn-primary btn-sm"
                                        >New Role</Link>
                                    )
                                }
                            </div>
                            </div>
                            <div className="card-body">
                            <div className="w-100 table-responsive">
                                <div id="example_wrapper" className="dataTables_wrapper">
                                <table id="example" className="display w-100 dataTable">
                                    <thead>
                                    <tr role="row">
                                        <th>#ID</th>
                                        <th>Name</th>
                                        <th>Guard Name</th>
                                        <th>Creaion Date</th>
                                        <th>Last Update</th>
                                        {
                                            checkPermissions(
                                                ["update roles" , "delete roles"],
                                                <th>Actions</th>
                                            )
                                        }
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        rolesList && rolesList.length >= 1
                                        ?
                                        jobData.current.map((role , index) => {
                                        const date = new Date(role.created_at)
                                        const year = date.getFullYear();
                                        const month = date.getMonth()+1;
                                        const day = date.getDate();
                                        const lastUpdateDate = new Date(role.updated_at)
                                        const lastUpdateYear = lastUpdateDate.getFullYear();
                                        const lastUpdatemonth = lastUpdateDate.getMonth()+1;
                                        const lastUpdateDay = lastUpdateDate.getDate();
                                        return (
                                            <tr key={index}>
                                                <td>{role.id}</td>
                                                <td>{role.name}</td>
                                                <td>{role.guard_name}</td>
                                                <td>{year} / {month} / {day}</td>
                                                <td>{lastUpdateYear} / {lastUpdatemonth} / {lastUpdateDay}</td>
                                                {
                                                    checkPermissions(
                                                        ["update roles" , "delete roles"],
                                                        <td>
                                                            {
                                                                checkPermissions(
                                                                    "read roles",
                                                                    <Link
                                                                        to={`/show-role/${role.id}`}
                                                                        className="btn btn-primary shadow btn-xs sharp me-1"
                                                                    >
                                                                        <i className="fa-solid fa-eye"></i>
                                                                    </Link>
                                                                )
                                                            }
                                                            {
                                                                checkPermissions(
                                                                    "update roles",
                                                                    <Link
                                                                        to={`/update-role/${role.id}`}
                                                                        className="btn btn-primary shadow btn-xs sharp me-1"
                                                                    >
                                                                        <i className="fas fa-pencil-alt"></i>
                                                                    </Link>
                                                                )
                                                            }
                                                            {
                                                                checkPermissions(
                                                                    "delete roles",
                                                                    <Link
                                                                        to="#"
                                                                        className="btn btn-danger shadow btn-xs sharp"
                                                                        onClick={() => {
                                                                            deleteRoleFunction(role.id)
                                                                        }}
                                                                    >
                                                                        <i className="fa fa-trash"></i>
                                                                    </Link>
                                                                )
                                                            }
                                                        </td>
                                                    )
                                                }
                                            </tr>
                                        )
                                        }) 
                                        :
                                        <tr>
                                            <td colSpan={4}>No data avilable</td>
                                        </tr>
                                    }
                                    </tbody>
                                </table>
                    
                                <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-2">
                                    <div className="dataTables_info">
                                    Showing {activePag.current * sort + 1} to{" "}
                                    {rolesList.length > (activePag.current + 1) * sort
                                        ? (activePag.current + 1) * sort
                                        : rolesList.length}{" "}
                                    of {rolesList.length} entries
                                    </div>
                                    <div
                                    className="dataTables_paginate paging_simple_numbers mb-0"
                                    id="example5_paginate"
                                    >
                                    <Link
                                        className="paginate_button previous disabled"
                                    //   to="/table-datatable-basic"
                                        to="#"
                                        onClick={() =>
                                        activePag.current > 0 && onClick(activePag.current - 1)
                                        }
                                    >
                                        <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                                    </Link>
                                    <span>
                                        {paggination.map((number, i) => (
                                        <Link
                                            key={i}
                                        //   to="/table-datatable-basic"
                                            to="#"
                                            className={`paginate_button  ${
                                            activePag.current === i ? "current" : ""
                                            } `}
                                            onClick={() => onClick(i)}
                                        >
                                            {number}
                                        </Link>
                                        ))}
                                    </span>
                                    <Link
                                        className="paginate_button next"
                                    //   to="/table-datatable-basic"
                                        to="#"
                                        onClick={() =>
                                        activePag.current + 1 < paggination.length &&
                                        onClick(activePag.current + 1)
                                        }
                                    >
                                        <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                                    </Link>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                )
                :
                <FaildLoadData
                    refetchDataFunc={() => dispatch(getRolesFunc(roles_API_URL))}
                />
            )
        }
        <LoadingScreen loading={deleteRoleLoading} />
        </>
    )
}