import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getBasicSettingsFunc } from "../../../store/custom-actions/settings-actions/basic-settings/GetBasicSettingsSlicer";
import SpinnerLoading from "../../components/spinner-loading/SpinnerLoading";
import { postBasicSettingsFunc } from "../../../store/custom-actions/settings-actions/basic-settings/PostBasicSettingsSlicer";
import PageTitle from "../../layouts/PageTitle";
import { ContextApi } from "../../../store/ContextApi";
import ReactQuill from "react-quill";
import { toast } from "react-toastify";
import { getHomeDataFunc } from "../../../store/custom-actions/settings-actions/dahsboard/GetHomeDataSlicer";

function BasicSettingsPage() {
    const contextApi = useContext(ContextApi);
    const basic_settings_URL_APi = `${contextApi.dashboard_URL_API}/general-settings`;
    const homeData_url = `${contextApi.dashboard_URL_API}/global-settings`;
    const {loading:getBasicSettingsLoading , data} = useSelector(
        state => state.getBasicSettingsSlicer
    );
    const {loading:postSettingsLoading} = useSelector(
        state => state.postBasicSettingsSlicer
    );
    const dispatch = useDispatch();
    const [footerCopyRight , setFooterCopyRight] = useState("")
    const {register , setValue , formState : {errors} , handleSubmit} = useForm();
    
    const getBasicSettings = async () => {
        dispatch(getBasicSettingsFunc(basic_settings_URL_APi))
    }
    useEffect(() => {
        getBasicSettings()
    },[])
    useEffect(() => {
        if(data) {
            setValue("site_title" , data.site_title);
            setValue("site_global_email" , data.site_global_email);
            setFooterCopyRight(data.site_footer_copyright);
        }
    },[data])
    const handleSubmitSettings = async (data) => {
        if(data) {
            const basicSettingsFormData = new FormData();
            basicSettingsFormData.append("site_title" , data.site_title)
            basicSettingsFormData.append("site_global_email" , data.site_global_email)
            basicSettingsFormData.append("site_footer_copyright" , footerCopyRight)
            dispatch(postBasicSettingsFunc({
                api : basic_settings_URL_APi , 
                data : basicSettingsFormData
            }))
            .then(result => {
                if(result.payload.success === true) {
                    getBasicSettings();
                    dispatch(getHomeDataFunc(homeData_url))
                    toast.success(`${result.payload.message} !`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    })
                }
            })
        }
    }
    const colors = [
        '#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff',
        '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff',
        '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff',
        '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2',
        '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466',
        '#000000', '#990000', '#b26b00', '#b2b200', '#005c00', '#003399', '#592966'
    ];
    const modules = {
        toolbar: {
        container: [
        [{"color" : colors}],
        [{ 'background': colors }]
        [{ 'header': [1, 2, 3, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        ['link' , "video"],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'align': [] }],
        ['clean']
        ],
    }
    };
    return (
        <>
            <PageTitle motherMenu={"Settings"} activeMenu={"Basic Settings"} customClasses={"mb-0"}/>
            <div className="relative flex items-center justify-between my-3">
                <div className="relative flex items-center">
                    <button 
                        type="submit"
                        form="new-settings-form"
                        class={`flex items-center bg-primary text-white gap-1 px-4 py-2 cursor-pointer  font-semibold tracking-widest rounded-md hover:bg-blue-400 duration-300 ml-1 hover:gap-2 hover:translate-x-3
                        ${postSettingsLoading ? "pointer-events-none opacity-80" : ""}
                        `}
                        >
                            {
                                postSettingsLoading
                                ?
                                "Please Wait.."
                                :
                                <>
                                    Submit
                                    <i className="fa-solid fa-gear text-white ml-1"></i>
                                </>
                            }
                    </button>
                </div>
            </div>
            
            {
                getBasicSettingsLoading
                ?
                <SpinnerLoading/>
                :
                <div className="row">
                    <div className="col-xl-12 col-xxl-12">
                        <div className="card p-4">
                            <div className="relative settings-form-content">
                            <div className="new-settings-form-div relative w-full ">
                                <form 
                                    className="new-settings-form"
                                    id="new-settings-form"
                                    onSubmit={handleSubmit((data) => {
                                        handleSubmitSettings(data)
                                    })}
                                >
                                    {
                                        <>
                                            <div className={'row'}>
                                                <div className="form-group mb-3 col-sm-12 col-md-6">
                                                    <label className="flex items-center">
                                                        <i className="fa-solid fa-arrow-up-9-1 mr-1"></i>
                                                        Site Title
                                                    </label>
                                                    {errors.site_title?.message && (
                                                    <p className="text-red-600">
                                                        {errors.site_title?.message}
                                                    </p>
                                                    )}
                                                    <input
                                                    {...register("site_title")}
                                                    type="text"
                                                    className="form-control"
                                                    />
                                                </div>
                                                <div className="form-group mb-3 col-sm-12 col-md-6">
                                                    <label className="flex items-center">
                                                        <i className="fa-solid fa-arrow-up-9-1 mr-1"></i>
                                                        Site Global Email
                                                    </label>
                                                    {errors.site_global_email?.message && (
                                                    <p className="text-red-600">
                                                        {errors.site_global_email?.message}
                                                    </p>
                                                    )}
                                                    <input
                                                    {...register("site_global_email")}
                                                    type="email"
                                                    className="form-control"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row m-0 p-0">
                                                <label className="flex items-center">
                                                    <i className="fa-solid fa-arrow-up-9-1 mr-1"></i>
                                                    Site Copyright Content
                                                </label>
                                                <ReactQuill 
                                                modules = {modules}
                                                className="p-0 border-none outline-none form-control bg-white " 
                                                value={footerCopyRight} 
                                                theme="snow" 
                                                onChange={(content) => setFooterCopyRight(content)} />
                                            </div>
                                        </>
                                    }
                                </form>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}


export default BasicSettingsPage;