import { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import SpinnerLoading from "../../components/spinner-loading/SpinnerLoading";
import PageTitle from "../../layouts/PageTitle";
import { ContextApi } from "../../../store/ContextApi";
import { toast } from "react-toastify";
import { postOrdersOutSideSettingsFunc } from "../../../store/custom-actions/settings-actions/orders-outside/PostOrdersOutSideSettingsSlicer";
import { getOrdersOutSideSettingsFunc } from "../../../store/custom-actions/settings-actions/orders-outside/GetOrdersOutSideSettingsSlicer";
function OrdersOutSideSettings() {
    const contextApi = useContext(ContextApi);
    const settings_URL_APi = `${contextApi.dashboard_URL_API}/general-settings`;
    const {loading:getSettingsLoading , data} = useSelector(
        state => state.getOrdersOutSideSettingsSlicer
    );
    const {loading:postSettingsLoading} = useSelector(
        state => state.postOrdersOutSideSettingsSlicer
    );
    const dispatch = useDispatch();
    const {register , setValue , formState : {errors} , handleSubmit} = useForm();
    const getSettings = async () => {
        dispatch(getOrdersOutSideSettingsFunc(settings_URL_APi))
    }
    useEffect(() => {
        getSettings()
    },[])
    useEffect(() => {
        if(data) {
            setValue("max_order_amount" , data.max_order_amount);
            setValue("min_order_amount" , data.min_order_amount);
        }
    },[data , setValue])
    const handleSubmitSettings = async (data) => {
        if(data) {
            const formData = new FormData();
            formData.append("max_order_amount" , data.max_order_amount)
            formData.append("min_order_amount" , data.min_order_amount)
            dispatch(postOrdersOutSideSettingsFunc({
                api : settings_URL_APi , 
                data : formData
            }))
            .then(result => {
                if(result.payload.success === true) {
                    getSettings();
                    toast.success(`${result.payload.message} !`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    })
                }
            })
        }
    }
    return (
        <>
            <PageTitle motherMenu={"Settings"} activeMenu={"Orders Settings"} customClasses={"mb-0"}/>
            <div className="relative flex items-center justify-between my-3">
                <div className="relative flex items-center">
                    <button 
                        type="submit"
                        form="new-settings-form"
                        className={`flex items-center bg-primary text-white gap-1 px-4 py-2 cursor-pointer  font-semibold tracking-widest rounded-md hover:bg-blue-400 duration-300 ml-1 hover:gap-2 hover:translate-x-3
                        ${postSettingsLoading ? "pointer-events-none opacity-80" : ""}
                        `}
                        >
                            {
                                postSettingsLoading
                                ?
                                "Please Wait.."
                                :
                                <>
                                    Submit
                                    <i className="fa-solid fa-gear text-white ml-1"></i>
                                </>
                            }
                    </button>
                </div>
            </div>
            
            {
                getSettingsLoading
                ?
                <SpinnerLoading/>
                :
                <div className="row">
                    <div className="col-xl-12 col-xxl-12">
                        <div className="card p-3">
                            <div className="relative settings-form-content">
                                <div className="new-settings-form-div relative w-full ">
                                    <form 
                                        className="new-settings-form"
                                        id="new-settings-form"
                                        onSubmit={handleSubmit((data) => {
                                            handleSubmitSettings(data)
                                        })}
                                    >
                                        {
                                            <>
                                                <div className={'row'}>
                                                    <div className="form-group mb-3 col-sm-12 col-md-6">
                                                        <label className="flex items-center">
                                                            <i className="fa-solid fa-arrow-up-9-1 mr-1"></i>
                                                            Max Orders
                                                        </label>
                                                        {errors.max_order_amount?.message && (
                                                        <p className="text-red-600">
                                                            {errors.max_order_amount?.message}
                                                        </p>
                                                        )}
                                                        <input
                                                        {...register("max_order_amount")}
                                                        type="number"
                                                        className="form-control"
                                                        min={0}
                                                        />
                                                    </div>
                                                    <div className="form-group mb-3 col-sm-12 col-md-6">
                                                        <label className="flex items-center">
                                                            <i className="fa-solid fa-arrow-up-9-1 mr-1"></i>
                                                            Min Orders
                                                        </label>
                                                        {errors.min_order_amount?.message && (
                                                        <p className="text-red-600">
                                                            {errors.min_order_amount?.message}
                                                        </p>
                                                        )}
                                                        <input
                                                        {...register("min_order_amount")}
                                                        type="number"
                                                        className="form-control"
                                                        min={0}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}


export default OrdersOutSideSettings;