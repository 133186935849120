import React, { useContext, useEffect, useState } from "react";

/// React router dom
import {  Switch, Route, Routes, useNavigate } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import ScrollToTop from "./layouts/ScrollToTop";
/// Dashboard
import Home from "./components/Dashboard/Home";
import DashboardDark from "./components/Dashboard/DashboardDark";
import Instructors from "./components/Dashboard/Instructors";
import Activity from "./components/Dashboard/Activity";
import Message from "./components/Dashboard/Message";
import Schedule from "./components/Dashboard/Schedule";
import Profile from "./components/Dashboard/Profile";


//Courses----
import CoursesMain from './components/Courses/CoursesMain';
import CourseDetail1 from './components/Courses/CourseDetail1';
import CourseDetail2 from './components/Courses/CourseDetail2';

//Instructors
import InstructorDashboard from './components/Instructor/InstructorDashboard';
import InstructorCourses from './components/Instructor/InstructorCourses';
import InstructorSchedule from './components/Instructor/InstructorSchedule';
import InstructorStudents from './components/Instructor/InstructorStudents';
import InstructorResources from './components/Instructor/InstructorResources';
import InstructorTransactions from './components/Instructor/InstructorTransactions';
import LiveClass from './components/Instructor/LiveClass';

/////Demo
import Theme1 from "./components/Dashboard/Demo/Theme1";
import Theme2 from "./components/Dashboard/Demo/Theme2";
import Theme3 from "./components/Dashboard/Demo/Theme3";
import Theme4 from "./components/Dashboard/Demo/Theme4";
import Theme5 from "./components/Dashboard/Demo/Theme5";
import Theme6 from "./components/Dashboard/Demo/Theme6";
import Theme7 from "./components/Dashboard/Demo/Theme7";
import Theme8 from "./components/Dashboard/Demo/Theme8";


/// App
import AppProfile from "./components/AppsMenu/AppProfile/AppProfile";
import Compose from "./components/AppsMenu/Email/Compose/Compose";
import Inbox from "./components/AppsMenu/Email/Inbox/Inbox";
import Read from "./components/AppsMenu/Email/Read/Read";
import Calendar from "./components/AppsMenu/Calendar/Calendar";
import PostDetails from "./components/AppsMenu/AppProfile/PostDetails";

/// Product List
import ProductGrid from "./components/AppsMenu/Shop/ProductGrid/ProductGrid";
import ProductList from "./components/AppsMenu/Shop/ProductList/ProductList";
import ProductDetail from "./components/AppsMenu/Shop/ProductGrid/ProductDetail";
import Checkout from "./components/AppsMenu/Shop/Checkout/Checkout";
import Invoice from "./components/AppsMenu/Shop/Invoice/Invoice";
import ProductOrder from "./components/AppsMenu/Shop/ProductOrder";
import Customers from "./components/AppsMenu/Shop/Customers/Customers";

/// Charts
import SparklineChart from "./components/charts/Sparkline";
import ChartJs from "./components/charts/Chartjs";
import Chartist from "./components/charts/chartist";
import RechartJs from "./components/charts/rechart";
import ApexChart from "./components/charts/apexcharts";

/// Bootstrap
import UiAlert from "./components/bootstrap/Alert";
import UiAccordion from "./components/bootstrap/Accordion";
import UiBadge from "./components/bootstrap/Badge";
import UiButton from "./components/bootstrap/Button";
import UiModal from "./components/bootstrap/Modal";
import UiButtonGroup from "./components/bootstrap/ButtonGroup";
import UiListGroup from "./components/bootstrap/ListGroup";
import UiCards from "./components/bootstrap/Cards";
import UiCarousel from "./components/bootstrap/Carousel";
import UiDropDown from "./components/bootstrap/DropDown";
import UiPopOver from "./components/bootstrap/PopOver";
import UiProgressBar from "./components/bootstrap/ProgressBar";
import UiTab from "./components/bootstrap/Tab";
import UiPagination from "./components/bootstrap/Pagination";
import UiGrid from "./components/bootstrap/Grid";
import UiTypography from "./components/bootstrap/Typography";

/// Plugins
import Select2 from "./components/PluginsMenu/Select2/Select2";
import MainNouiSlider from "./components/PluginsMenu/NouiSlider/MainNouiSlider";
import MainSweetAlert from "./components/PluginsMenu/SweetAlert/SweetAlert";
import Toastr from "./components/PluginsMenu/Toastr/Toastr";
import JqvMap from "./components/PluginsMenu/JqvMap/JqvMap";
import Lightgallery from "./components/PluginsMenu/Lightgallery/Lightgallery";

//Redux
import Todo from "./pages/Todo";

/// Widget
import Widget from "./pages/Widget";

/// Table
import SortingTable from "./components/table/SortingTable/SortingTable";
import FilteringTable from "./components/table/FilteringTable/FilteringTable";
import DataTable from "./components/table/DataTable";
import BootstrapTable from "./components/table/BootstrapTable";

/// Form
import Element from "./components/Forms/Element/Element";
import Wizard from "./components/Forms/Wizard/Wizard";
import SummerNote from "./components/Forms/Summernote/SummerNote";
import Pickers from "./components/Forms/Pickers/Pickers";
import jQueryValidation from "./components/Forms/jQueryValidation/jQueryValidation";

/// Pages
import Registration from "./pages/Registration";
// import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
// import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";
import { ThemeContext } from "../context/ThemeContext";
import Protect from "../store/custom-actions/ProtectRoutes";
import LogOut from "../store/custom-actions/LogoutComponent";
import NotFoundPage from "./custom-pages/not-found-page/NotFoundPage";
import LoginPage from "./custom-pages/login-page/LoginPage";
import ProductPage from "./custom-pages/products-pages/ProductsPage";
import ProductDetails from "./custom-pages/products-pages/ProductDetails";
import NewProductForm from "./custom-forms/product-forms/NewProductForm";
import CategoriesPage from "./custom-pages/categories-page/CategoriesPage";
import SubCategoriesPage from "./custom-pages/sub-categories-page/SubCategoriesage";
import CitiesPage from "./custom-pages/cities-page/CitiesPage";
import CountriesPage from "./custom-pages/countries-page/CountriesPage";
import StatesPage from "./custom-pages/states-page/StatesPage";
import CitiesOfStatePage from "./custom-pages/cities-page/CitiesOfStatePage";
import NewCouponForm from "./custom-forms/coupons/NewCouponForm";
import CouponsPage from "./custom-pages/coupons-page/CouponsPage";
import UpdateCouponFrom from "./custom-forms/coupons/UpdateCouponForm";
import CouponUsersPage from "./custom-pages/coupons-page/CouponsUersPage";
import UsersPage from "./custom-pages/users-pages/UsersPage";
import UpdateProductForm from "./custom-forms/product-forms/UpdateProductForm";
import RolesPage from "./custom-pages/roles-page/RolesPage";
import NewRoleForm from "./custom-forms/roles/NewRoleForm";
import UpdateRoleForm from "./custom-forms/roles/UpdateRoleForm";
import SingleRolePage from "./custom-pages/roles-page/RolePermissions";
import AdminsPage from "./custom-pages/admins-page/AdminsPage";
import NewAdminForm from "./custom-forms/admins/NewAdminForm";
import UpdateAdminMainData from "./custom-forms/admins/UpdateAdminMainData";
import ChangeAdminPassword from "./custom-forms/admins/ChangeAdminPassword";
import UpdateAdminImage from "./custom-forms/admins/UpdateAdminImage";
import AdminProfile from "./custom-pages/admins-page/AdminProfile";
import OrdersPage from "./custom-pages/orders-page/OrdersPage";
import NewsLettersPage from "./custom-pages/news-letters-page/NewsLettersPage";
import InvoicePage from "./custom-pages/orders-page/invoice-page/InvoicePage";
import BasicSettingsPage from "./custom-forms/settings-pages/BasicSettingsPage";
import SmtpSettingsForm from "./custom-forms/settings-pages/SmtpSettingsForm";
import TestSmtpSettingsForm from "./custom-forms/settings-pages/TestSmtpSettingsForm";
import TaxFeeForm from "./custom-forms/settings-pages/TaxFeeForm";
import OrdersOutSideSettings from "./custom-forms/settings-pages/OrdersOutSideSettings";
import PaymentsGatewaySettings from "./custom-forms/settings-pages/PaymentsGateway";
import MessagesPage from "./custom-pages/contact-page/MessagesPage";
import SendNotficationPage from "./custom-forms/notfications/SendNotficationPage";
import UpdateOrderStatus from "./custom-forms/order/UpdateStatus";
import UpdatePaymnetStatus from "./custom-forms/order/UpdatePaymnetStatus";
import UpdateRefundOrderPage from "./custom-pages/orders-page/UpdateRefund";
import NewMessage from "./custom-forms/news-letters/NewMessage";
import WhatsappForm from "./custom-forms/whatsapp/WhatsappForm";
import HavNotPermissionPage from "./custom-pages/have-not-permission-page/HaveNotPermissionPage";
import checkPermissions from "./global-functions/checkPermissionsFunc";
import SocialSettingsPage from "./custom-forms/settings-pages/SocialSettingsPage";
import ContactSettingsPage from "./custom-forms/settings-pages/ContactSettingsPage";
import LanguagesPage from "./custom-pages/languages-page/LanguagesPage";
import UpdateLaguageWordsPage from "./custom-pages/languages-page/UpdateLaguageWordsPage";
import UserProfile from "./custom-pages/users-pages/UserProfile";
import SlidersPage from "./custom-pages/sliders-page/SlidersPage";
import NewSliderForm from "./custom-forms/settings-pages/sliders-settings/NewSliderForm";
import { useSelector } from "react-redux";
import MediaSettings from "./custom-forms/settings-pages/MediaSettings";
import Setting from "./layouts/Setting";

const Markup = () => {
  const { menuToggle } = useContext(ThemeContext);
  const expire_date = window.localStorage.getItem("expire_date");
  const user = JSON.parse(window.localStorage.getItem("user"))
  const userRoleName = user && user.roleName && user.roleName.toLowerCase();
  const navigate = useNavigate();
  const {data:languageData} = useSelector(
    state => state.getLanguageSettingsSlicer
  )
  useEffect(()=>{
    if(!expire_date){
      navigate("/login-page")
    }
  },[navigate , expire_date])

  const routes = [
    { url: "/", component: <Home/> },
    { url: "/dashboard", component: <Home/> },
    // products///////
    { url: "/products", component: checkPermissions("read products" , <ProductPage/> , <HavNotPermissionPage/>) },
    { url: "/product-details/:productId", component: checkPermissions("read products" , <ProductDetails/> , <HavNotPermissionPage/>)  },
    { url: "/new-item", component: checkPermissions("create products" , <NewProductForm/> , <HavNotPermissionPage/>)  },
    { url: "/update-item/:itemId", component: checkPermissions("update products" , <UpdateProductForm/> , <HavNotPermissionPage/>) },
    
    // categories///////
    { url: "/categories", 
      component: checkPermissions("read categories" , <CategoriesPage/> , <HavNotPermissionPage/>) },
    { url: "/sub-categories", 
      component: checkPermissions("read sub categories" , <SubCategoriesPage/> , <HavNotPermissionPage/>) },

    // adresses///////
    { url: "/cities", 
      component: checkPermissions("read addresses" , <CitiesPage/> , <HavNotPermissionPage/>) },
    { url: "/cities/:stateId", 
      component: checkPermissions("read addresses" , <CitiesOfStatePage/> , <HavNotPermissionPage/>) },
    { url: "/countries", 
      component: checkPermissions("read addresses" , <CountriesPage/> , <HavNotPermissionPage/>) },
    { url: "/states", component: checkPermissions("read addresses" , <StatesPage/> , <HavNotPermissionPage/>) },
    // { url: "/sub-categories", component: <SubCategoriesPage/> },
    
    //coupons///////
    { url: "/coupons", component: checkPermissions("read coupons" , <CouponsPage/> , <HavNotPermissionPage/>)  },
    { url: "/new-coupon", component: <NewCouponForm/> },
    { url: "/update-coupon/:couponId", component: checkPermissions("update coupons" , <UpdateCouponFrom/> , <HavNotPermissionPage/>)  },
    { url: "/coupon-users/:couponId", component: checkPermissions("read coupons" , <CouponUsersPage/> , <HavNotPermissionPage/>) },
    
    //users//////
    { url: "/users", 
      component: checkPermissions("read users" , <UsersPage/> , <HavNotPermissionPage/>) },
    { url: "/user-profile/:userId", 
      component: checkPermissions("read users" , <UserProfile/> , <HavNotPermissionPage/>) },

    //roles
    { url: "/roles", 
      component: checkPermissions("read roles" , <RolesPage/> , <HavNotPermissionPage/>) },
    { url: "/show-role/:roleId", 
      component: checkPermissions("read roles" , <SingleRolePage/> , <HavNotPermissionPage/>) },
    { url: "/new-role", component: checkPermissions("create roles" , <NewRoleForm/> , <HavNotPermissionPage/>) },
    { url: "/update-role/:roleId", component: checkPermissions("update roles" , <UpdateRoleForm/> , <HavNotPermissionPage/>)  },
    
    //admins
    { url: "/admins", 
      component: checkPermissions("read admins" , <AdminsPage/> , <HavNotPermissionPage/>) },
    { url: "/admin-profile", component: checkPermissions("read admins" , <AdminProfile/> , <HavNotPermissionPage/>) },
    { url: "/new-admin", component: checkPermissions("create admins" , <NewAdminForm/> , <HavNotPermissionPage/>) },
    { url: "/update-admin-main-data/:adminId", component: checkPermissions("update admins" , <UpdateAdminMainData/> , <HavNotPermissionPage/>) },
    { url: "/update-admin-pass/:adminId", component: checkPermissions("update admins" , <ChangeAdminPassword/> , <HavNotPermissionPage/>) },
    { url: "/update-admin-image/:adminId", component: checkPermissions("update admins" , <UpdateAdminImage/> , <HavNotPermissionPage/>) },
    
    //orders
    { url: "/orders", component: checkPermissions("read orders" , <OrdersPage/> , <HavNotPermissionPage/>) },
    { url: "/invoice-page/:orderId", component: checkPermissions("read orders" , <InvoicePage/> , <HavNotPermissionPage/>) },
    { url: "/update-order-status/:orderId", component: checkPermissions("update orders" , <UpdateOrderStatus/> , <HavNotPermissionPage/>)  },
    { url: "/update-order-payment-status/:orderId", component: checkPermissions("update orders" , <UpdatePaymnetStatus/> , <HavNotPermissionPage/>) },
    { url: "/update-order-redfund/:orderId", component: checkPermissions("update orders" ,<UpdateRefundOrderPage/> , <HavNotPermissionPage/>) },
    
    //news
    { url: "/news-letters", component: checkPermissions("read newsletters" , <NewsLettersPage/> , <HavNotPermissionPage/>)  },
    { url: "/post-news", component: <NewMessage/> },

    //contact
    { url: "/users-messages", component: checkPermissions("read contacts" , <MessagesPage/> , <HavNotPermissionPage/>)  },
    { url: "/send-notfication", component: checkPermissions("create notifications" , <SendNotficationPage/> , <HavNotPermissionPage/>)  },

    //settings
    { url: "/basic-settings", component:  (userRoleName === "super admin" ? <BasicSettingsPage/> : <HavNotPermissionPage/>)},
    { url: "/smtp-settings", component: (userRoleName === "super admin" ? <SmtpSettingsForm/> : <HavNotPermissionPage/>)},
    { url: "/test-smtp", component: (userRoleName === "super admin" ? <TestSmtpSettingsForm/> : <HavNotPermissionPage/>) },
    { url: "/media-settings", component: (userRoleName === "super admin" ? <MediaSettings/> : <HavNotPermissionPage/>) },
    { url: "/tax-settings", component: (userRoleName === "super admin" ? <TaxFeeForm/> : <HavNotPermissionPage/>) },
    { url: "/orders-outside-settings", component: (userRoleName === "super admin" ? <OrdersOutSideSettings/> : <HavNotPermissionPage/>) },
    { url: "/payment-gateway-settings", component: (userRoleName === "super admin" ? <PaymentsGatewaySettings/> : <HavNotPermissionPage/>) },
    { url: "/social-settings", component: (userRoleName === "super admin" ? <SocialSettingsPage/> : <HavNotPermissionPage/>) },
    { url: "/contact-settings", component: (userRoleName === "super admin" ? <ContactSettingsPage/> : <HavNotPermissionPage/>) },
    { url: "/languages-settings", component: (userRoleName === "super admin" ? <LanguagesPage/> : <HavNotPermissionPage/>) },
    { url: "/single-lang/:langId", component: (userRoleName === "super admin" ? <UpdateLaguageWordsPage/> : <HavNotPermissionPage/>) },
    { url: "/sliders-settings", component: (userRoleName === "super admin" ? <SlidersPage/> : <HavNotPermissionPage/>) },
    { url: "/new-slider", component: (userRoleName === "super admin" ? <NewSliderForm/> : <HavNotPermissionPage/>) },
    
    //whatsapp
    { url: "/new-whatsapp-message", component: checkPermissions("create notifications" , <WhatsappForm/> , <HavNotPermissionPage/>)  },
  ];
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  // return (
  //   <>
  //     <div
  //       id={`${!pagePath ? "main-wrapper" : ""}`}
  //       className={`${!pagePath ? "show" : "mh100vh"}  ${
  //         menuToggle ? "menu-toggle" : ""
  //       }`}
  //     >
  //       {!pagePath && <Nav />}

  //       <div className={`${!pagePath ? "content-body" : ""}`}
	// 		style={{ minHeight: window.screen.height - 45 }}
	// 	>
  //         <div
  //           className={`${!pagePath ? "container-fluid" : ""}`}
  //         >
  //           <Switch>
  //             {routes.map((data, i) => (
  //               <Route
  //                 key={i}
  //                 exact
  //                 path={`/${data.url}`}
  //                 component={data.component}
  //               />
  //             ))}
  //           </Switch>
  //         </div>
  //       </div>
  //       {!pagePath && <Footer />}
  //     </div>
	//   <ScrollToTop />
  //   </>
  // );

  return (
    <>
      <div
        id={`${expire_date ? "main-wrapper" : ""}`}
        className={`${expire_date ? "show" : "mh100vh"}  ${
          menuToggle ? "menu-toggle" : ""
        }`}
      >
        {
          expire_date
          &&
          <Nav />
        }
        <div
          // className={`${expire_date ? "content-body" : ""}`}
          className={`${expire_date ? "content-body" : ""} ${languageData?.defaultLang?.slug === "ar" ? "ar-mode" : "en-mode"}`}
          style={expire_date && { minHeight: window.screen.height - 45 }}
        >
          <div className={`${expire_date ? "container-fluid" : ""}`}>
            <Routes>
              <Route path="*" element={<NotFoundPage/>}/>
              <Route element={<Protect/>}>
                <Route path="/login-page" element={<LoginPage/>}/>
                {/* <Route path="/register-page" element={<RegisterPage/>} /> */}
                {/* <Route path="/page-forgot-password" element={<ForgotPassword/>} /> */}
              </Route>
              {/* {routes} */}
              {
                routes.map((route , index) => <Route key={index} path={route.url} element={
                  route.component
                }/>)
              }
            </Routes>
            {
              expire_date
              &&
              <LogOut/>
            }
          </div>
        </div>
        {
          expire_date
          &&
          <>
          <Footer />
          <Setting/>
          </>
        }
      </div>
      <ScrollToTop />
    </>
  )
  


  
};

export default Markup;
